import { AccountStatus, Editions } from './enums';
import { OrderTheme } from './order-theme';
import { base32guid } from './types';

export interface Settings {
  readonly accountId: string;
  readonly accountIdToken: base32guid;
  readonly userIdToken: base32guid;
  readonly edition: Editions;
  readonly expiryDate: Date;
  readonly accountStatus: AccountStatus;
  readonly localeId: string;
  readonly countryCode: string;
  readonly showCountry: boolean;
  readonly financialYearStart: Date;
  readonly currencyCode: string;
  readonly taxRate: number;
  readonly includeTax: boolean;
  readonly orderTheme: OrderTheme;
  readonly schemaVersion: number;
}

export const EMPTY_SETTINGS: Settings = {
  accountId: '',
  accountIdToken: '',
  userIdToken: '',
  edition: Editions.NotSpecified,
  expiryDate: new Date(),
  accountStatus: AccountStatus.None,
  localeId: 'en-US',
  countryCode: 'US',
  showCountry: false,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- The year doesn't actually matter
  financialYearStart: new Date(2020, 0, 1),
  currencyCode: 'USD',
  taxRate: 0,
  includeTax: false,
  orderTheme: new OrderTheme('', '', ''),
  schemaVersion: 0
};
