import { Component, ViewEncapsulation } from '@angular/core';
import { ChartType } from 'chart.js';

import { ChartComponent } from './chart.component';

@Component({
  selector: 'wky-bar-chart',
  templateUrl: './chart.component.html',
  styleUrls: [ './chart.component.scss' ],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class BarChartComponent extends ChartComponent  {
  public readonly chartType: ChartType = 'bar';
}
