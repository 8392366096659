import { Injectable } from '@angular/core';
import { addMonths } from 'date-fns';
import { concatMap, map, Observable, of } from 'rxjs';

import { QueryParams } from '../core';
import { LogService } from '../core/logging';
import { IdPrefix, OrderDetails, OrderNumber, OrderState, OrderType, Settings } from '../core/model';
import { DateRange } from '../utility';
import { OrderNumberService } from './order-number.service';
import { PouchDbEntityDataService } from './pouchdb-entity-data.service';
import { PouchDbService } from './pouchdb.service';
import { SettingsService } from './settings.service';

export const enum OrderValuesByMonthIndex {
  Month,
  Year,
  TotalQuotes,
  UnpaidInvoices,
  PaidInvoices
};

export const ORDERS_BY_ORDERDETAILSID_START_INDEX: number = -1;

@Injectable()
export class OrderDetailsService extends PouchDbEntityDataService<OrderDetails> {
  constructor(private _orderNumberService: OrderNumberService,
              private _settingsService: SettingsService,
              pouchDbService: PouchDbService,
              logService: LogService) {
    super(IdPrefix.OrderDetails, pouchDbService, logService);
  }

  public getByQuery(queryParams: QueryParams): Observable<OrderDetails[]> {
    return this.getWithQuery(queryParams);
  }

  public getNewInstance(orderType: OrderType): Observable<OrderDetails> {
    const settings: Settings = this._settingsService.currentSettings;

    return this._orderNumberService.getNext(orderType)
                                   .pipe(concatMap((nextNumber: OrderNumber) => {
                                      return of({ ...new OrderDetails(orderType),
                                                  accountId: settings.accountIdToken,
                                                  countryCode: settings.countryCode,
                                                  orderNumber: nextNumber.value,
                                                  orderState: OrderState.NotSpecified,
                                                  dateRaised: new Date(),
                                                  currencyCode: settings.currencyCode,
                                                  includeTax: settings.includeTax
                                                });
                                    }));
  }

  public isNumberInUse(orderType: OrderType, orderId: string, orderNumber: any): Observable<boolean> {
    const queryParams: QueryParams = new QueryParams('orderDetails/byOrderNumber',
                                                     [orderType, orderNumber],
                                                     [orderType, orderNumber]);
    queryParams.pageSize = 1;

    return this.getWithQuery(queryParams)
               .pipe(concatMap((matchOrders: OrderDetails[]) => {
                  let inUse: boolean = false;
                  for (const matchOrder of matchOrders) {
                    if (matchOrder._id !== orderId) {
                      inUse = true;
                      break;
                    }
                  }

                  return of(inUse);
               }));
  }

  public delete(orderDetailsId: string): Observable<string | number> {
    /* The easiest way to delete both the order details and items is to do a bulk update with each doc
      flagged as deleted.
    */
    const queryParams: QueryParams = new QueryParams('orders/byOrderDetailsId',
                                                     [ orderDetailsId, ORDERS_BY_ORDERDETAILSID_START_INDEX ],
                                                     [ orderDetailsId, Number.MAX_SAFE_INTEGER ]);

    return this.getMultiWithQuery(queryParams)
               .pipe(concatMap((docs: any[]) => {
                      const deletedDocs: any[] = [];

                      for (const doc of docs) {
                        deletedDocs.push({ ...doc,
                                          _deleted: true
                                        });
                      }

                      return this.pouchDbService.Database.bulkDocs(deletedDocs);
                     }),
                     concatMap((responses: (PouchDB.Core.Response | PouchDB.Core.Error)[]) => {
                      /* As long as the OrderDetail is deleted, its items can't be accessed (and can
                        be cleaned up relatively easily), so we can assume success.  If not, it's a
                        problem as we may have deleted some/all of the items.
                      */
                      for (const response of responses) {
                        const isOrderDetails: boolean = response.id === orderDetailsId;

                        if (this.isResponse(response)) {
                          if (!response.ok) {
                            this.log.error(`Unable to delete order id:'${response.id}'`, response);
                            if (isOrderDetails) {
                              throw new Error(`Unable to delete order id '${orderDetailsId}'`);
                            }
                          }
                        } else {
                          this.log.error(`Error deleting order id:'${orderDetailsId}'`, response);
                          if (isOrderDetails) {
                            throw new Error(`Error deleting order id '${orderDetailsId}'`);
                          }
                        }
                      }

                      return of(orderDetailsId);
                     }));
  }

  /** The returned observable will automatically complete after the first value is emitted. */
  public getOrderValuesByMonth(dateRange: DateRange): Observable<number[][]> {
    let from: Date = dateRange.from;
    const to: Date = dateRange.to;
    const queryParams: QueryParams = new QueryParams('orderDetails/byDateRaised', from.valueOf(), to.valueOf());
    queryParams.includeDocs = false;

    return this.getRowsWithQuery(queryParams)
               .pipe(map((rows: any[]) => {
                  let currentMonth: number = from.getMonth();
                  let currentYear: number = from.getFullYear();
                  let totalQuotes: number = 0;
                  let unpaidInvoices: number = 0;
                  let paidInvoices: number = 0;
                  let month: number = 0;
                  let year: number = 0;
                  const orderValues: number[][] = [];

                  for (const row of rows) {
                    const dateRaised: Date = new Date(row.key);
                    month = dateRaised.getMonth();
                    year = dateRaised.getFullYear();
                    const [orderType, grossTotal, orderState] = row.value;

                    while (currentMonth < month || currentYear < year) {
                      orderValues.push([
                        currentMonth,
                        currentYear,
                        totalQuotes,
                        unpaidInvoices,
                        paidInvoices
                      ]);

                      from = addMonths(from, 1);
                      currentMonth = from.getMonth();
                      currentYear = from.getFullYear();
                      totalQuotes = 0;
                      unpaidInvoices = 0;
                      paidInvoices = 0;
                    }

                    switch (orderType) {
                      case OrderType.Quote:
                        totalQuotes += grossTotal;
                        break;

                      case OrderType.Invoice:
                        if (orderState === OrderState.Paid) {
                          paidInvoices += grossTotal;
                        } else if (orderState === OrderState.Raised) {
                          unpaidInvoices += grossTotal;
                        }
                        break;

                      default:
                        if (orderType !== OrderType.NotSpecified) {
                          this.log.warn(`Unrecognized OrderType enum value - ${orderType}`);
                        }
                        break;
                    }
                  }

                  orderValues.push([
                    currentMonth,
                    currentYear,
                    totalQuotes,
                    unpaidInvoices,
                    paidInvoices
                  ]);

                  return orderValues;
                }));
  }

  public getTotalOutstandingValue(): Observable<number> {
    const queryParams: QueryParams = new QueryParams('orderDetails/totalOutstandingValue');
    queryParams.includeDocs = false;
    queryParams.reduce = true;

    return this.getRowsWithQuery(queryParams)
               .pipe(map(rows => {
                      return rows && rows.length > 0 ? rows[0].value : 0;
                    }));
  }

  protected getInstanceFromDoc(doc: any): OrderDetails {
    const isOrderDetails: boolean = (doc instanceof OrderDetails);
    const orderDetails: OrderDetails = isOrderDetails ? doc as OrderDetails
                                                      : new OrderDetails(doc);

    return orderDetails;
  }
}
