import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import { Color } from '@ionic/core';
import { ChartDataset, ChartOptions, ChartTypeRegistry, Tick, TooltipItem } from 'chart.js';
import { add, formatDistance } from 'date-fns';
import { takeUntil } from 'rxjs';
import { SwiperOptions } from 'swiper';
import { PaginationOptions } from 'swiper/types';

import { DUMMY_ROUTE_SEGMENT_FOR_NEW_ORDER } from '../../constants';
import { AppConfig, appConfigToken } from '../../core';
import { Logger, LogService } from '../../core/logging';
import { Editions, Settings } from '../../core/model';
import { CssBreakpoint } from '../../enums';
import { DateRangeService, OrderDetailsService, OrderValuesByMonthIndex, SettingsService } from '../../services';
import { PageComponent } from '../../shared';
import { BarChartComponent } from '../../shared/charts/charts.module';
import { DateRange, DateRangePeriod } from '../../utility';

const CHART_DATE_RANGE: DateRangePeriod = DateRangePeriod.Last3MonthsPlus;
const MAX_TICKS_LIMIT: number = 5;
const TRAIL_EDITION_EXPIRY_WARNING_DAYS: number = 7;

const enum ChartDataSet {
  Quotes,
  PaidInvoices,
  UnpaidInvoices
}

// TODO: TASK - finalize dataset colours (use constants too)
// TODO: TASK - consider that invoice value may be negative (for credit notes) so tick handling may need to change
// TODO: TASK - display notification if mobile apps not yet downloaded
// TODO: TASK - sort out stepsize and autoskip options for new version of chart library
// TODO: TASK - consider way to call chart.update() to refresh chart (and change changedetectionstrategy too)
// TODO: FEATURE - implement swiper's 'creative' effect to animate the slides (doesn't currently work if >1 slide visible):
  // public readonly swiperCreativeEffect: SwiperOptions['creativeEffect'] = {
  //   prev: {
  //     translate: ['-120%', 0, -500]
  //   },
  //   next: {
  //     translate: ['120%', 0, -500]
  //   }
  // };
// TODO: BUG - getting expiry warning for brand new account

@Component({
  selector: 'wky-dashboard-page',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default  /* Not using OnPush so graph auto-updates */
})
export class DashboardPage extends PageComponent implements OnInit {
  public showExpiryWarning: boolean = false;
  public expiryWarning: string = '';
  public expiryWarningColour: Color = '';
  public internalBillingLink: string[] | undefined;
  public externalBillingLink: string | undefined;
  public billingLinkTarget: string | undefined;
  public outstandingToDate: string = '£0';
  public invoicedThisMonth: string = '£0';
  public quotedThisMonth: string = '£0';
  public readonly swiperBreakpoints: SwiperOptions['breakpoints'] = {
    [CssBreakpoint.LG]: {
      slidesPerView: 2
    },
    [CssBreakpoint.XL]: {
      slidesPerView: 3
    }
  };
  public readonly swiperPagination: PaginationOptions = {
    clickable: true
  };
  public labels: string[] = [];
  public datasets: ChartDataset[] = [];
  public chartOptions: ChartOptions = {};
  public showEmptyState: boolean = false;
  public readonly DUMMY_ROUTE_SEGMENT_FOR_NEW_ORDER: string = DUMMY_ROUTE_SEGMENT_FOR_NEW_ORDER;
  @ViewChild(BarChartComponent) private _chart!: BarChartComponent;
  private _dateRange: DateRange;
  private _datasetColours: string[] = [
    '#8dff8d',
    '#ff8d8d',
    '#b5e4f9',
    '#e4c6e7'
  ];
  private readonly _log: Logger;

  constructor(private _orderDetailsService: OrderDetailsService,
              private _dateRangeService: DateRangeService,
              private _settingsService: SettingsService,
              @Inject(appConfigToken) private _appConfig: AppConfig,
              logService: LogService,
              titleService: Title) {
    super(titleService);

    this._log = logService.getLogger('DashboardPage');
    this._dateRange = this._dateRangeService.getDateRange(CHART_DATE_RANGE);
  }

  public ngOnInit(): void {
    this.pageTitle = 'Dashboard';

    this.datasets.push({ label: 'Quotes',          data: [], borderWidth: 1, hidden: true },
                       { label: 'Paid Invoices',   data: [], borderWidth: 1, stack: 'invoices' },
                       { label: 'Unpaid Invoices', data: [], borderWidth: 1, stack: 'invoices' });
    this.chartOptions = {
      ...BarChartComponent.getDefaultOptions(),
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: this.formatTooltipLabel
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          type: 'linear',
          grid: {
            borderColor: 'transparent'
          },
          ticks: {
            callback: this.formatTicksLabel,
            // max: 1000,
            maxTicksLimit: MAX_TICKS_LIMIT
          }
        }
      }
    };
  }

  public ionViewWillEnter = (): void => {
    super.ionViewWillEnter();

    this._settingsService.currentSettings$.pipe(takeUntil(this.isLeavingView$))
                                          .subscribe(settings => {
                                            this.checkForAccountExpiry(settings);
                                          });

    /* Get the range again, just in case the app has been open so long that it's changed */
    this._dateRange = this._dateRangeService.getDateRange(CHART_DATE_RANGE);
    this.labels.length = 0;

    const localeId: string = this._settingsService.currentSettings.localeId;
    const dateTimeFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat(localeId, { month: 'short' });

    for (let date: Date = this._dateRange.from; date < this._dateRange.to; date = add(date, { months: 1 })) {
      this.labels.push(dateTimeFormat.format(date));
    }

    this.clearChart();

    this._orderDetailsService.getTotalOutstandingValue()
                             .subscribe(totalValue => {
                                this.outstandingToDate = this._settingsService.formatCurrency(totalValue, true);
                              });
    this._orderDetailsService.getOrderValuesByMonth(this._dateRange)
                             .subscribe((orderValues: number[][]) => {
                                const quoteValues: number[] = [];
                                const unpaidInvoiceValues: number[] = [];
                                const paidInvoiceValues: number[] = [];
                                let hideQuotes: boolean = true;
                                let quoteValue: number = 0;
                                let unpaidInvoiceValue: number = 0;
                                let paidInvoiceValue: number = 0;

                                for (const orderValue of orderValues) {
                                  /* We'll keep the last values retrieved since we can use them for the
                                    headline figures.
                                  */
                                  quoteValue = orderValue[OrderValuesByMonthIndex.TotalQuotes];
                                  unpaidInvoiceValue = orderValue[OrderValuesByMonthIndex.UnpaidInvoices];
                                  paidInvoiceValue = orderValue[OrderValuesByMonthIndex.PaidInvoices];

                                  if (quoteValue !== 0) {
                                    hideQuotes = false;
                                  }

                                  quoteValues.push(quoteValue);
                                  unpaidInvoiceValues.push(unpaidInvoiceValue);
                                  paidInvoiceValues.push(paidInvoiceValue);
                                }

                                this.invoicedThisMonth = this._settingsService.formatCurrency(unpaidInvoiceValue + paidInvoiceValue, true);
                                this.quotedThisMonth = this._settingsService.formatCurrency(quoteValue, true);

                                this.datasets[ChartDataSet.Quotes].hidden = hideQuotes;
                                this.showEmptyState = hideQuotes
                                                   && unpaidInvoiceValues.every((value: number) => {
                                                                            return value === 0;
                                                                          })
                                                   && paidInvoiceValues.every((value: number) => {
                                                                          return value === 0;
                                                                        });

                                if (!this.showEmptyState) {
                                  this.loadChartData(hideQuotes ? undefined : quoteValues, unpaidInvoiceValues, paidInvoiceValues);
                                }
                              });
  };

  private checkForAccountExpiry(settings: Settings): void {
    const daysToAccountExpiry: number = this._settingsService.daysToAccountExpiry();
    const edition: Editions = settings.edition;

    this._log.debug(`Checking account expiry - edition: ${edition}, expiry: ${settings.expiryDate}`);

    switch (edition) {
      case Editions.Trial:
        if (daysToAccountExpiry < 0) {
          this.expiryWarning = `Your trial has expired - upgrade your account`;
          this.expiryWarningColour = 'danger';
          this.showExpiryWarning = true;
        } else if (daysToAccountExpiry < TRAIL_EDITION_EXPIRY_WARNING_DAYS) {
          const period: string = formatDistance(settings.expiryDate, new Date());
          this.expiryWarning = `Your trial expires in ${period} - upgrade your account`;
          this.expiryWarningColour = 'warning';
          this.showExpiryWarning = true;
        } else {
          this.showExpiryWarning = false;
        }
        break;

      default:
        this._log.assert(edition === Editions.Standard,
                        `Unsupported Editions enum value - ${edition}`);
        if (daysToAccountExpiry < 0) {
          this.expiryWarning = `Your subscription has expired - what happens now?`;
          this.expiryWarningColour = 'danger';
          this.showExpiryWarning = true;
        } else {
          this.showExpiryWarning = false;
        }
        break;
    }

    if (this.showExpiryWarning) {
      if (Capacitor.isNativePlatform()) {
        this.internalBillingLink = undefined;
        this.externalBillingLink = new URL('/account/billing', this._appConfig.webUri).toString();
        this.billingLinkTarget = '_system';
      } else {
        this.internalBillingLink = ['/account/billing'];
        this.externalBillingLink = undefined;
        this.billingLinkTarget = undefined;
      }
    }
  }

  private loadChartData(quoteValues: number[] | undefined, unpaidInvoiceValues: number[], paidInvoiceValues: number[]): void {
    if (quoteValues) {
      this._chart.setDatasetProperties(ChartDataSet.Quotes, quoteValues,
                                       this._datasetColours[ChartDataSet.Quotes]);
    }
    this._chart.setDatasetProperties(ChartDataSet.UnpaidInvoices, unpaidInvoiceValues,
                                     this._datasetColours[ChartDataSet.UnpaidInvoices]);
    this._chart.setDatasetProperties(ChartDataSet.PaidInvoices, paidInvoiceValues,
                                     this._datasetColours[ChartDataSet.PaidInvoices]);

    // eslint-disable-next-line @typescript-eslint/typedef -- it's the ugliest union type you're likely to see
    const yAxis = this.chartOptions?.scales?.y;
    if (yAxis) {
      yAxis.min = undefined;
      yAxis.max = undefined;
      // ticks.autoSkip = true;
      // ticks.stepSize = undefined;
    }

    this.chartOptions = { ...this.chartOptions };
  }

  private clearChart(): void {
    // eslint-disable-next-line @typescript-eslint/typedef -- it's the ugliest union type you're likely to see
    const yAxis = this.chartOptions?.scales?.y;
    if (yAxis) {
      yAxis.min = 0;
      yAxis.max = 1250;
      // ticks.autoSkip = false;
      // ticks.stepSize = 250;
    }

    this.chartOptions = { ...this.chartOptions };
    this.datasets[ChartDataSet.Quotes].hidden = true;
    this.datasets[ChartDataSet.UnpaidInvoices].hidden = true;
    this.datasets[ChartDataSet.PaidInvoices].hidden = true;
  }

  private formatTooltipLabel = (tooltip: TooltipItem<keyof ChartTypeRegistry>): string | string[] => {
    const datasetLabel: string = tooltip.dataset.label ?? '';
    const value: number = tooltip.raw as number ?? 0;
    const formattedValue: string = this._settingsService.formatCurrency(value);
    const label: string = `${datasetLabel} - ${formattedValue}`;

    return label;
  };

  private formatTicksLabel = (value: number | string, _index: number, ticks: Tick[]): string | number | null | undefined => {
    let label: string | undefined;

    if (typeof(value) === 'number') {
      /* We want to remove the zero and top-most ticks */
      const max: number = Math.max(ticks[0].value, ticks[ticks.length - 1].value);

      if (value < max) {
        /* HACK ALERT!  Ideally, we'd just use:

          label = new Intl.NumberFormat('en-GB', { notation: 'compact', compactDisplay: 'short'})
                          .format(value);

          but the `notation` option isn't supported in es2015 or Safari (see
            https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_options_notation_parameter),
          so for now, we'll just have to hack our own version.
        */
        /* eslint-disable @typescript-eslint/no-magic-numbers */
        if (value >= 1000000) {
          label = Math.round(value / 1000000).toString() + 'M';
        } else if (value >= 1000) {
          label = Math.round(value / 1000).toString() + 'K';
        } else if (value > 0) {
          label = value.toString();
        }
        /* eslint-enable @typescript-eslint/no-magic-numbers */
      }
    } else {
      label = value;
    }

    return label;
  };
}
