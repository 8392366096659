export class Name {
  constructor();
  constructor(title: string,
              firstName: string,
              lastName: string);
  constructor(public title: string = '',
              public firstName: string = '',
              public lastName: string = '') {}

  public get fullName(): string {
    let fullName: string = this.title;

    if (this.firstName.length > 0) {
      if (fullName.length > 0) {
        fullName += ' ';
      }

      fullName += this.firstName;
    }

    if (this.lastName.length > 0) {
      if (fullName.length > 0) {
        fullName += ' ';
      }

      fullName += this.lastName;
    }

    return fullName;
  }

  public get displayName(): string {
    let displayName: string = this.firstNameOrTitle;

    if (this.lastName.length > 0) {
      if (displayName.length > 0) {
        displayName += ' ';
      }

      displayName += this.lastName;
    }

    return displayName;
  }

  public get greetingsName(): string {
    let name: string;

    if (this.firstName.length > 0) {
      name = this.firstName;
    } else if (this.title.length > 0) {
      name = this.title + ' ' + this.lastName;
    } else {
      name = this.lastName;
    }

    return name;
  }

  public get firstNameOrTitle(): string {
    return this.firstName.length > 0 ? this.firstName : this.title;
  }
}
