import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import Showdown from 'showdown';

import { Logger, LogService } from '../core/logging';
import { stringify } from '../utility/misc';

@Injectable({
  providedIn: 'root'
})
export class ContentSanitizerService {
  private readonly _log: Logger;
  private readonly _converter: Showdown.Converter;

  constructor(private _sanitizer: DomSanitizer,
              logService: LogService) {
    this._log = logService.getLogger('ContentSanitizerService');

    this._converter = new Showdown.Converter({
      backslashEscapesHTMLTags: true,
      noHeaderId: true,
      simpleLineBreaks: true,
      smoothLivePreview: true,
      strikethrough: true,
      tables: true
    });

    this.setDefaultConfig();
  }

  private setDefaultConfig(): void {
    const config: DOMPurify.Config = this.getDefaultConfig();
    DOMPurify.setConfig(config);
  }

  private getDefaultConfig(): DOMPurify.Config {
    return {
      // ADD_ATTR?: string[];
      // ADD_DATA_URI_TAGS?: string[];
      // ADD_TAGS?: string[];
      ALLOW_DATA_ATTR: true,  /* MarkdownEditor appears to use data-* attributes to track content */
      ALLOWED_ATTR: [ /* None */],
      ALLOWED_TAGS: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'p',
        'br',
        'strong',
        'em',
        'del',
        'ul',
        'ol',
        'li',
        '#text'
      ],
      // FORBID_ATTR?: string[];
      // FORBID_TAGS?: string[];
      // FORCE_BODY?: boolean;
      // KEEP_CONTENT?: boolean;
      RETURN_DOM: false,
      RETURN_DOM_FRAGMENT: false,
      // RETURN_DOM_IMPORT?: boolean;
      // RETURN_TRUSTED_TYPE?: boolean;
      // SAFE_FOR_JQUERY?: boolean;
      // SANITIZE_DOM?: boolean;
      // WHOLE_DOCUMENT?: boolean;
      // ALLOWED_URI_REGEXP?: RegExp;
      // SAFE_FOR_TEMPLATES?: boolean;
      // ALLOW_UNKNOWN_PROTOCOLS?: boolean;
      USE_PROFILES: {
        html: true
      }
      // IN_PLACE?: boolean;
    };
  }

  public plaintextToSafeHtml(plaintext: string): SafeHtml {
    const escaped: string = this.encodePlaintextForHtml(plaintext)
                                .replace(/\r\n?|\n/g, '<br>');
    const cleanHtml: string = this.sanitizeHtml(escaped);

    return this._sanitizer.bypassSecurityTrustHtml(cleanHtml);
  }

  public encodePlaintextForHtml(plaintext: string): string {
    /* Escaping according to OWASP recommendations:
        https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#rule-1---html-escape-before-inserting-untrusted-data-into-html-element-content

      As noted in that cheatsheet, '&apos;' not recommended because it's not in the HTML spec (See section
      24.4.1) - it's in the XML and XHTML specs.  Also, forward slash is included as it helps end an HTML entity.
    */
    return plaintext.replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&#39;')
                    .replace(/\//g, '&#x2F;');
  }

  public toMarkdown(html: string): string {
    const cleanHtml: string = this.sanitizeHtml(html);
    return this._converter.makeMarkdown(cleanHtml);
  }

  public toHtml(markdown: string): string {
    const html: string = this._converter.makeHtml(markdown);
    return this.sanitizeHtml(html);
  }

  public toSafeHtml(markdown: string): SafeHtml {
    const html: string = this._converter.makeHtml(markdown);
    const cleanHtml: string = this.sanitizeHtml(html);

    return this._sanitizer.bypassSecurityTrustHtml(cleanHtml);
  }

  public escapeForMarkdown(plaintext: string): string {
    /* See https://daringfireball.net/projects/markdown/syntax#backslash, plus <, > and |, for
      good measure, as per https://www.markdownguide.org/basic-syntax
    */
    return plaintext.replace(/[!#()*+.<>[\\\]_`{|}\-]/g, '\\$&');
  }

  public sanitizeMarkdown(markdown: string): string {
    const html: string = this._converter.makeHtml(markdown);
    const cleanHtml: string = this.sanitizeHtml(html);
    return this._converter.makeMarkdown(cleanHtml);
  }

  public sanitizeHtml(html: string): string {
    const cleanHtml: string = DOMPurify.sanitize(html);

    if (DOMPurify.removed && DOMPurify.removed.length > 0) {
      let removedContent: string = '';

      DOMPurify.removed.forEach((item: any) => {
        if (item.element) {
          removedContent += '\r\n\tElement: ' + item.element.outerHTML;
        } else if (item.attribute) {
          removedContent += `\r\n\t${item.from.tagName}.${item.attribute.name}="${item.attribute.value}"`;
        } else {
          removedContent += '\r\n\t' + stringify(item);
        }
      });

      this._log.warn(`Insecure HTML content found in Markdown has been removed:${removedContent}`);
    }

    return cleanHtml;
  }
}
