<ion-tabs>
	<ion-tab-bar *ngIf="(this.showTabs$ | async) === true" slot="bottom">
		<ion-tab-button tab="dashboard">
			<fa-icon icon="home" [fixedWidth]="true"></fa-icon>
			<ion-label>Home</ion-label>
		</ion-tab-button>
		<ion-tab-button tab="quotes">
			<fa-icon icon="comment-dollar" [fixedWidth]="true"></fa-icon>
			<ion-label>Quotes</ion-label>
		</ion-tab-button>
		<ion-tab-button tab="invoices">
			<fa-icon icon="file-invoice" [fixedWidth]="true"></fa-icon>
			<ion-label>Invoices</ion-label>
		</ion-tab-button>
		<ion-tab-button tab="settings">
			<fa-icon icon="cog" [fixedWidth]="true"></fa-icon>
			<ion-label>Settings</ion-label>
		</ion-tab-button>
	</ion-tab-bar>
</ion-tabs>
