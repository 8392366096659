export const INPUT_TYPE_MODES: Map<string, string> = new Map([
  ['date', ''],
  ['email', 'email'],
  ['number', 'decimal'],
  ['password', ''],
  ['search', 'search'],
  ['tel', 'tel'],
  ['text', ''],
  ['url', 'url'],
  ['time', ''],
  ['week', ''],
  ['month', ''],
  ['datetime-local', ''],
  ['checkbox', ''],
  ['radio', ''],
  ['textarea', '']
]);
