import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { HIGHEST_CHARACTER } from '../constants';
import { QueryParams } from '../core';
import { LogService } from '../core/logging';
import { OrderItemSearchResult } from '../core/model';
import { PouchDbDataService } from './pouchdb-data.service';
import { PouchDbService } from './pouchdb.service';

@Injectable()
export class OrderItemSearchService extends PouchDbDataService<OrderItemSearchResult> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(pouchDbService: PouchDbService,
              logService: LogService) {
    /* Technically not needed, but if omitted, there is no constructor for the base class to
      get the class name from (which, bizarrely, then leads to 'circular dependency' errors
      in the service's DI).
    */
    super(pouchDbService, logService);
  }

  public search(term: string): Observable<OrderItemSearchResult[]> {
    let results: Observable<OrderItemSearchResult[]>;

    if (term.length > 0) {
      term = term.trim().toUpperCase();

      const queryParams: QueryParams = new QueryParams('orderItems/search',
                                                       term,
                                                       term + HIGHEST_CHARACTER);
      queryParams.includeDocs = false;
      results = this.getWithQuery(queryParams);
    } else {
      results = of([]);
    }

    return results;
  }

  protected getInstanceFromRow(row: any): OrderItemSearchResult {
    const orderItemSearchResult: OrderItemSearchResult = {
      id: row.id,
      description: row.value[0],
      unitAmount: row.value[1]
    };

    return orderItemSearchResult;
  }
}
