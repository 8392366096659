import { LocaleService } from '../services';

export class DynamicLocaleId extends String {
  constructor(private _localeService: LocaleService) {
    super('');
  }

  public toString(): string {
    return this._localeService.getLocaleId();
  }
}
