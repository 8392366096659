import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MenuDisplayService } from './services';

// TODO: TASK - these tabs were set up with a click handler because if you use the tab name as
// the route, it remembers the last route per tab and returns there when you click the tab
// again (which is really odd if you're in a deep child page).  But this doesn't work - the
// tab routing overrides the click handler.  So how can we get around this???
//     <ion-tab-button tab="" (click)="navigateTo($event, ['/'])">

@Component({
  templateUrl: 'tabs.component.html',
  styleUrls: ['tabs.component.scss']
})
export class TabsComponent {
  public showTabs$: BehaviorSubject<boolean>;

  constructor(menuDisplayService: MenuDisplayService) {
    this.showTabs$ = menuDisplayService.showTabs$;
  }
}
