import { ChangeDetectionStrategy, Component, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { of, switchMap, takeUntil } from 'rxjs';

import { Logger, LogService } from '../../core/logging';
import { QuerystringKey } from '../../enums';
import { AuthService, SchemaCheckService, SettingsService, ToastService } from '../../services';
import { PageComponent } from '../../shared';
import { stringEqualsIgnoreCase } from '../../utility';

// TODO: BUG: - check if user is authenticated; if not, redirect to login (as settings will never be initialized)
// TODO: TASK - consider better 'loading' graphic
// TODO: TASK - consider displaying some sort of image/UI in the event of an error (when spinner is no longer displayed)

@Component({
  selector: 'wky-loading-page',
  templateUrl: './loading.page.html',
  styleUrls: ['./loading.page.scss'],
  changeDetection: ChangeDetectionStrategy.Default  /* So we can hide the spinner dynamically */
})
export class LoadingPage extends PageComponent {
  public isLoading: boolean = true;
  private readonly _log: Logger;

  constructor(private _settingsService: SettingsService,
              private _schemaCheckService: SchemaCheckService,
              private _authService: AuthService,
              private _router: Router,
              private _route: ActivatedRoute,
              private _ngZone: NgZone,
              private _toastService: ToastService,
              titleService: Title,
              logService: LogService) {
    super(titleService);
    this._log = logService.getLogger('LoadingPage');
  }

  public ionViewWillEnter(): void {
    super.ionViewWillEnter();

    /* When the app is first loaded or refreshed, it needs to load in the settings but can
      only do this asynchronously.  If doing this from within a 'normal' page, there's no way
      to ensure that the settings are obtained before the page loads its other data, leading
      to the display of incorrect formatting, currencies, etc, forcing refreshing of the UI.

      So instead, since this is only a problem on first load, we check for the settings from
      within the AuthGuard and if not found, redirect to this page, whose only purpose is to
      display a loading animation while the settings are loaded.  As soon as that is complete,
      the user is redirected back to the original page.
    */
    let redirectUrl: string = this._route.snapshot.queryParams[QuerystringKey.RedirectUrl];
    if (typeof(redirectUrl) === 'undefined' || redirectUrl.length === 0) {
      redirectUrl = '/';  /* Don't leave the user on this loading page */
    }

    this._authService.isAuthenticated$
                     .pipe(takeUntil(this.isLeavingView$))
                     .subscribe((loggedIn) => {
                        /* Errors below or in validation of the user's id token can result in
                          the user being logged out, so don't leave them here.
                        */
                        if (!loggedIn) {
                          this._router.navigate(['/login']);
                        }
                      });
    this._settingsService.currentSettings$
                         .pipe(takeUntil(this.isLeavingView$),
                               switchMap((settings) => {
                                 return this._settingsService.settingsInitialized
                                          ? this._schemaCheckService.ensureLatestVersion(settings.accountIdToken,
                                                                                         settings.schemaVersion)
                                          : of(false);
                               }))
                         .subscribe({
                            next: (_upgraded) => {
                              if (this._settingsService.settingsInitialized) {
                                this._log.debug(`Settings obtained - redirecting to ${redirectUrl}...`);
                                const url: UrlTree = this._router.parseUrl(redirectUrl);

                                this._ngZone.run(() => {
                                  this._router.navigateByUrl(url, { replaceUrl: true })
                                              .then((succeeded) => {
                                                if (!succeeded) {
                                                  this._log.warn(`Failed to redirect to ${redirectUrl}`);

                                                  /* Most likely reason for failure is account expiry, so just
                                                    redirect to the dashboard (notification will still be displayed)
                                                  */
                                                  if (!stringEqualsIgnoreCase(redirectUrl, '/dashboard')) {
                                                    this._router.navigate(['/dashboard']);
                                                  }
                                                }
                                              });
                                });
                              } else {
                                this._log.debug('Settings issued but not flagged as initialized');
                              }
                            },
                            error: (error) => {
                              this._log.debug('Error obtaining current settings', error);
                              this.isLoading = false;
                              this._toastService.showError(`A serious problem has occurred during the initialization of Workery, which is preventing it from loading properly.

Please try logging in again, otherwise contact the support team for assistance.`);
                              this._authService.logout();
                            }
                          });
  }
}
