import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, combineLatest, takeUntil } from 'rxjs';

import { LogService } from '../core/logging';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class MenuDisplayService extends BaseService {
  public showMenu$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showTabs$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isPhoneDevice: boolean;

  constructor(platform: Platform,
              authService: AuthService,
              settingsService: SettingsService,
              logService: LogService) {
    super(settingsService, logService);

    /* FYI, this is more to do with device size than screen resolution */
    this.isPhoneDevice = platform.is('iphone') || platform.is('android');

    combineLatest([authService.isAuthenticated$,
                   settingsService.currentSettings$
                  ])
                  .pipe(takeUntil(this.isBeingDestroyed$))
                  .subscribe(([isLoggedIn, settings]) => {
                    const settingsInitialized: boolean = settings.accountId.length > 0;
                    const showOptions: boolean = isLoggedIn && settingsInitialized; // && !isOnboarding;

                    this.showMenu$.next(showOptions && !this.isPhoneDevice);
                    this.showTabs$.next(showOptions &&  this.isPhoneDevice);
                  });
    this.isBeingDestroyed$.subscribe(() => {
                            this.showMenu$.complete();
                            this.showTabs$.complete();
                          });
  }
}
