import { Component, OnInit } from '@angular/core';

import { OrderType } from '../../core/model';
import { OrderListPage } from './order-list.page';

@Component({
  selector: 'wky-invoice-list-page',
  templateUrl: './order-list.page.html',
  styleUrls: ['./order-list.page.scss']
})
export class InvoiceListPage extends OrderListPage implements OnInit {
  public ngOnInit(): void {
    this.pageTitle = 'Invoices';
    this.itemRoute = '/invoices';
    this.showingInvoices = true;
  }

  protected get orderType(): OrderType {
    return OrderType.Invoice;
  }
}
