import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { catchError, concatMap, from, map, Observable, of, takeWhile } from 'rxjs';

import { Logger, LogService } from '../core/logging';
import { CountryInfo, LocaleInfo } from '../core/model';
import { stringFormat } from '../utility';
import { COUNTRIES_BY_LANGUAGE, SUPPORTED_COUNTRIES, TAX_RATES_BY_COUNTRY_STATE } from './locale-data';

const LOCALEID_REGEX: RegExp = /^(?<lang>[a-z]{2,3}).*?(-(?<country>[A-Z]{2,3}))?$/;

// TODO: TASK - load locales for Toast UI Editor (see `locale (loading editor locale).service.unused.ts`)

/* IMPORTANT!  This allows the locale (and LOCALE_ID) to be changed dynamically when Account.locale changes.
  However, Angular's pipes only read LOCALE_ID on creation, so will not pick up any later changes unless the
  app is reloaded.  If we provide a formal way for users to change their locale, we'll either need to force
  a reload or create our own pipes to read LOCALE_ID dynamically.
*/
@Injectable({
  providedIn: 'root'
})
export class LocaleService {
  private _currentLocale: LocaleInfo;
  private readonly _countries: Map<string, string> = new Map<string, string>();
  private readonly _months: Map<number, string> = new Map<number, string>();
  private readonly _currencies: Map<string, string> = new Map<string, string>();
  private readonly _log: Logger;

  constructor(logService: LogService) {
    this._log = logService.getLogger('LocaleService');

    this._currentLocale = {
      localeId: 'en-US',
      countryCode: 'US',
      currencyCode: 'USD'
    };
  }

  public initialize(): void {
    if (window.navigator.languages.length > 0) {
      this._log.debug('Initializing');

      /* We want to work through the user's preferred languages, checking whether there's
        a language module to support them, with `loadLocale()` returning an empty string
        if not.  So we need to keep taking languages until a non-empty one is returned.
      */
      const localeLoading$: Observable<string> = from(window.navigator.languages);
      localeLoading$.pipe(concatMap(localeId => {
                            return this.loadLocale(localeId);
                          }),
                          takeWhile(localeId => localeId.length === 0))
                    .subscribe({
                      next: (_localeId => {
                        /* `takeWhile()` invokes this while its condition is met, i.e. the
                          localeId is an empty string, so we can just ignore it.
                        */
                      }),
                      error: (err => {
                        this._log.error(`Error occurred initializing locale - current locale left as ${this._currentLocale.localeId}`,
                                        err);
                      }),
                      complete: (() => {
                        this._log.debug(`Locale now set to '${this._currentLocale.localeId}'`);
                      })
                    });
    } else {
      this._log.debug(`No locales set - defaulting to ${this._currentLocale.localeId}`);
    }
  }

  public getCurrentLocaleInfo(): LocaleInfo {
    return this._currentLocale;
  }

  public getLocaleId(): string {
    return this._currentLocale.localeId;
  }

  public getCurrentCountryCode(): string {
    return this._currentLocale.countryCode;
  }

  private loadLocale(localeId: string): Observable<string> {
    /* Currently, this will cause chunks to be built for every locale supported by Angular.  If we need to
      restrict them, we can do so with a 'magic comment' such as "webpackInclude: /(nb|sv)\.js$/" to include
      only specific locales (or use "webpackExclude" to exclude specific ones).

      FYI, this is not really a supported scenario - see https://github.com/angular/angular-cli/issues/22154
      so may stop working at any point!
    */
    return from(import(`/node_modules/@angular/common/locales/${localeId}.mjs`))
              .pipe(map((module: any) => {
                      registerLocaleData(module.default);
                      this.setCurrentLocale(localeId);
                      return localeId;
                    }),
                    catchError(err => {
                      this._log.warn(`Locale ${localeId} could not be loaded`, err);
                      return of('');
                    }));
  }

  private setCurrentLocale(localeId: string): void {
    const countryCode: string = this.getCountryCodeFromLocaleId(localeId);

    this._currentLocale = {
      localeId,
      countryCode,
      currencyCode: this.getCurrencyForCountryCode(countryCode)
    };
  }

  private getCurrencyForCountryCode(countryCode: string): string {
    return SUPPORTED_COUNTRIES.get(countryCode)?.currencyCode ?? 'USD';
  }

  private getCountryCodeFromLocaleId(localeId: string): string {
    const [_lang, countryCode] = this.getCodesFromLocaleId(localeId);

    return countryCode ?? 'US';
  }

  private getCodesFromLocaleId(localeId: string): [string | undefined, string | undefined] {
    let lang: string | undefined;
    let countryCode: string | undefined;
    const result: RegExpExecArray | null = LOCALEID_REGEX.exec(localeId);

    if (result && result.groups) {
      lang = result.groups['lang'];
      countryCode = result.groups['country'];
    }

    if (lang && typeof(countryCode) === 'undefined') {
      countryCode = COUNTRIES_BY_LANGUAGE.get(lang);
    }

    return [lang, countryCode];
  }

  public getCountries(): Map<string, string> {
    if (this._countries.size === 0) {
      const regionNames: Intl.DisplayNames = new Intl.DisplayNames(this._currentLocale.localeId,
                                                                   { type: 'region' });

      for (const countryCode of SUPPORTED_COUNTRIES.keys()) {
        const name: string | undefined = regionNames.of(countryCode);
        if (name) {
          this._countries.set(countryCode, name);
        } else {
          this._log.warn(`Country code ${countryCode} is not supported by Intl.DisplayNames`);
        }
      }
    }

    return this._countries;
  }

  public getCountryName(countryCode: string): string {
    const countries: Map<string, string> = this.getCountries();
    return countries.get(countryCode) ?? '';
  }

  public getMonths(): Map<number, string> {
    if (this._months.size === 0) {
      const dateTimeFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat(this._currentLocale.localeId,
                                                                          { month: 'long' });

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- 12 months in a year, duh
      for (let i: number = 0; i < 12 ; i++) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- year is irrelevant
        const month: string = dateTimeFormat.format(new Date(2000, i, 1));
        this._months.set(i + 1, month);
      }
    }

    return this._months;
  }

  public getCurrencies(countryCode: string): Map<string, string> {
    if (this._currencies.size === 0) {
      const currencies: Map<string, string> = new Map<string, string>();
      const regionNames: Intl.DisplayNames = new Intl.DisplayNames(this._currentLocale.localeId,
                                                                   { type: 'currency' });

      for (const countryInfo of SUPPORTED_COUNTRIES.values()) {
        const currencyCode: string = countryInfo.currencyCode;

        if (!currencies.has(currencyCode)) {
          const currency: string = stringFormat('{1} ({0})',
                                                currencyCode,
                                                regionNames.of(currencyCode));
          currencies.set(currencyCode, currency);
        }
      }

      /* Because we're nice, we'll put the currency for the user's country at the top,
        followed by EUR, GBP and USD, then the rest in alphabetical order.
      */
      const countryInfo: CountryInfo | undefined = SUPPORTED_COUNTRIES.get(countryCode);
      const primaryCodes: string[] = (countryInfo ? [countryInfo.currencyCode, 'EUR', 'GBP', 'USD']
                                                  : ['EUR', 'GBP', 'USD']);
      let sortedKeys: string[] = [...currencies.entries()].sort((a, b) => a[1].localeCompare(b[1]))
                                                          .map(entry => entry[0]);

      /* This will add duplicate keys to the array, so we'll have to check before adding them */
      sortedKeys = primaryCodes.concat(sortedKeys);
      sortedKeys.forEach(currencyCode => {
        if (!this._currencies.has(currencyCode)) {
          this._currencies.set(currencyCode, currencies.get(currencyCode) ?? '');
        }
      });
    }

    return this._currencies;
  }

  public getTaxRateForCountryCode(countryCode: string, state: string): number {
    let taxRate: number | undefined;

    /* Even if the state is available, there may not be a state-specific tax rate.  So check for
      it, but if we don't find one, just use the country code on its own.
    */
    if (state.length > 0) {
      taxRate = TAX_RATES_BY_COUNTRY_STATE.get(countryCode + '-' + state);
    }

    if (typeof(taxRate) === 'undefined') {
      taxRate = TAX_RATES_BY_COUNTRY_STATE.get(countryCode);
    }

    return taxRate ?? 0;
  }
}
