import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressIndicatorService {
  constructor(private _loadingController: LoadingController) {}

  public loading(message: string): Promise<HTMLIonLoadingElement> {
    return this.showLoading({
                  message
                });
  }

  private async showLoading(options: LoadingOptions): Promise<HTMLIonLoadingElement> {
    const loading: HTMLIonLoadingElement = await this._loadingController.create(options);
    await loading.present();
    return loading;
  }
}
