import { DEFAULT_LIST_PAGE_SIZE } from '../constants';

export class QueryParams {
  public ascending: boolean = true;
  public pageSize: number = DEFAULT_LIST_PAGE_SIZE;
  public includeDocs: boolean = true;
  public reduce: boolean = false;

  constructor(public query: string = '',
              public startkey?: any,
              public endkey?: any) {}
}
