/** Gets the current value of an element via its `InputEvent` (wrapped in a `CustomEvent`) */
export function getCustomInputEventValue($event: Event): any {
// TODO: TASK - this should really be a CustomEvent<InputEvent>
  const customEvent: CustomEvent = $event as CustomEvent;
  return customEvent.detail.currentTarget.value;
}

/** Gets the current value of an element via its `FocusEvent` (wrapped in a `CustomEvent`) */
export function getCustomFocusEventValue($event: Event): any {
  const customEvent: CustomEvent = $event as CustomEvent;
  return customEvent.detail.currentTarget.value;
}

export function getCustomEventValue($event: Event): any {
  const customEvent: CustomEvent = $event as CustomEvent;
  return customEvent.detail.value;
}

export function getCustomEventChecked($event: Event): boolean {
  const customEvent: CustomEvent = $event as CustomEvent;
  return customEvent.detail.checked as boolean;
}
