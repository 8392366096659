import { Injectable } from '@angular/core';

import { LogService } from '../core/logging';
import { Customer, IdPrefix } from '../core/model';
import { PouchDbEntityDataService } from './pouchdb-entity-data.service';
import { PouchDbService } from './pouchdb.service';

@Injectable()
export class CustomerService extends PouchDbEntityDataService<Customer> {
  constructor(pouchDbService: PouchDbService,
              logService: LogService) {
    super(IdPrefix.Customer, pouchDbService, logService);
  }

  protected getInstanceFromDoc(doc: any): Customer {
    const isCustomer: boolean = (doc instanceof Customer);
    const customer: Customer = isCustomer ? doc as Customer
                                          : new Customer(doc);

    return customer;
  }
}
