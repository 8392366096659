import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../../services';

@Component({
  selector: 'wky-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @Input() public heading: string = '';
  public isLoggedIn$: Observable<boolean>;

  constructor(private _authService: AuthService) {
    this.isLoggedIn$ = this._authService.isAuthenticated$;
  }
}
