import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, ViewContainerRef } from '@angular/core';
import { FaConfig } from '@fortawesome/angular-fontawesome';
import { Platform } from '@ionic/angular';

import { environment } from '../environments/environment';
import { AppConfig, appConfigToken } from './core';
import { Logger, LogService } from './core/logging';
import { LocaleService } from './services';

@Component({
  selector: 'wky-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default   /* Needed otherwise the dashboard chart displays incorrectly */
})
export class AppComponent {
  private readonly _log: Logger;

  constructor(public vcRef: ViewContainerRef,  /* Needed for colour picker */
              @Inject(DOCUMENT) private _document: Document,
              private _platform: Platform,
              private _localeService: LocaleService,
              @Inject(appConfigToken) appConfig: AppConfig,
              logService: LogService,
              faConfig: FaConfig) {
    this._log = logService.getLogger('AppComponent');

    this.initializeApp(appConfig);
    faConfig.defaultPrefix = 'far';
  }

  private initializeApp(appConfig: AppConfig): void {
    this.initializeDocumentHead(appConfig);
    this._localeService.initialize();
    this._platform.ready()
                  .then((readySource) => {
                    this._log.debug(`Platform ready - ${this._platform.platforms()} (${readySource})`);
// TODO: TASK - check this is correct when running on mobile (properly)
                    // if (readySource === 'cordova') {
                      // this._statusBar.styleDefault();
                      // this._splashScreen.hide();
                    // }
                  });
  }

  private initializeDocumentHead(appConfig: AppConfig): void {
    let connectUris: string = appConfig.dataUris.join(' ');
    const meta: HTMLMetaElement = this._document.createElement('meta');

    if (!environment.production) {
      connectUris += ' http://127.0.0.1:9099/';    /* Add the emulator host */
    }

// TODO: TASK - investigate limitations of setting via meta vs setting via server header
    meta.setAttribute('http-equiv', 'Content-Security-Policy');
// TODO: TASK - work out how to get rid of 'unsafe-inline' and 'unsafe-eval' - see https://github.com/ionic-team/ionic/issues/18269 (and https://github.com/firebase/firebaseui-web/issues/753 ????)
    meta.setAttribute('content', `
      default-src 'none';
      img-src 'self' data: https://www.gstatic.com;
      connect-src 'self' data: ${appConfig.workeryApiServiceBaseUri} ${connectUris} https://identitytoolkit.googleapis.com https://securetoken.googleapis.com https://www.googleapis.com https://api.pwnedpasswords.com;
      font-src data: https://fonts.gstatic.com;
      frame-src https://${appConfig.auth.authDomain};
      script-src-elem 'self' https://apis.google.com;
      script-src 'unsafe-eval' 'self';
      style-src 'unsafe-inline' 'self' https://fonts.googleapis.com;
      worker-src blob:;
      `.replace(/(\n\s*)/g, '')
    );
    this._document.head.append(meta);
  }
}
