import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { IonicSafeString, OverlayEventDetail, ToastOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private _lastToast: HTMLIonToastElement | undefined;

  constructor(private _toastController: ToastController) {}

  public showSuccess(message: string | IonicSafeString): void {
    this.showToast({
      message,
      color: 'success',
      duration: 5000,
      buttons: [
        {
          icon: 'close-outline',
          role: 'cancel'
        }
      ]
    });
  }

  public showWarning(message: string | IonicSafeString): void {
    this.showToast({
      message,
      color: 'warning',
      buttons: [
        {
          text: 'Close',
          role: 'cancel'
        }
      ]
    });
  }

  public showError(message: string | IonicSafeString): void {
    this.showToast({
      message,
      color: 'danger',
      buttons: [
        {
          text: 'Close',
          role: 'cancel'
        }
      ]
    });
  }

  public showValidationWarning(message: string | IonicSafeString): void {
    this.showToast({
      message,
      color: 'danger',
      duration: 5000,
      buttons: [
        {
          icon: 'close-outline',
          role: 'cancel'
        }
      ]
    });
  }

  private showToast(options: ToastOptions): void {
    this._toastController.create(options)
                         .then(toast => {
                            /* Ensure that there's only one toast displayed at any time */
                            if (this._lastToast) {
                              this._lastToast.dismiss();
                            }

                            toast.onDidDismiss()
                                 .then((_detail: OverlayEventDetail<any>) => {
                                    if (toast === this._lastToast) {
                                      this._lastToast = undefined;
                                    }
                                  });
                            toast.present();
                            this._lastToast = toast;
                          });
  }
}
