import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { SettingsService } from '../services';
import { getResolvedUrl } from '../utility';
import { redirectUnauthorizedToLogin } from './auth-pipe';
import { AuthGuard } from './auth.guard';
import { AccountStatus } from './model';

// eslint-disable-next-line @typescript-eslint/typedef, @typescript-eslint/explicit-function-return-type
export const canActivateForOnboarding = () => ({
  canActivate: [ OnboardingGuard ], data: { authGuardPipe: redirectUnauthorizedToLogin }
});

/** Handles navigation to the root (/) or onboarding pages, redirecting to the appropriate
 * page, depending on whether the user has completed onboarding or not (as indicated by the
 * `Account.accountStatus` property).
 *
 * Note that this should not handle explicit navigation to the dashboard page.
 */
@Injectable({
  providedIn: 'any'
})
export class OnboardingGuard implements CanActivate {
  constructor(private _authGuard: AuthGuard,
              private _settingsService: SettingsService,
              private _router: Router) {}

  public canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
    return this._authGuard.canActivate(next, state)
                          .pipe(map((can: boolean | UrlTree) => {
                                  /* So the call to the AuthGuard will have taken care of ensuring that
                                    the request is authenticated and whether the settings have been
                                    initialized. If not, it will return a UrlTree for redirecting to the
                                    loading page which we can ignore.  If it returns `true`, the settings
                                    are initialized and we just needs to determine whether to redirect to
                                    the dashboard or the onboarding page.
                                  */
                                  if (can === true) {
                                    const requestedUrl: string = getResolvedUrl(next).toUpperCase();
                                    const navigatingToOnboarding: boolean = (requestedUrl === '/ONBOARDING');
                                    const accountStatus: AccountStatus = this._settingsService.currentSettings.accountStatus;
                                    // eslint-disable-next-line no-bitwise -- we know what we're doing
                                    const isOnboarding: boolean = (accountStatus & AccountStatus.StartOnboarding) !== 0;

                                    if (navigatingToOnboarding) {
                                      if (isOnboarding) {
                                        /* Already heading to the right place... */
                                        can = true;
                                      } else {
                                        /* The user's completed onboarding, so it makes more sense for them
                                          to go the settings to change anything now.
                                        */
                                        can = this._router.createUrlTree(['/settings']);
                                      }
                                    } else {
                                      can = this._router.createUrlTree([isOnboarding ? '/onboarding'
                                                                                     : '/dashboard']);
                                    }
                                  }

                                  return can;
                                }));
  };
}
