import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { Auth, connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
         faAnalytics,
         faCheckCircle,
         faChevronCircleRight,
         faCircle
       } from '@fortawesome/pro-duotone-svg-icons';
import {
         faArrowRight,
         faArrowsV,
         faCog,
         faCommentDollar,
         faEnvelope,
         faFileInvoice,
         faGlobe,
         faHome,
         faLightbulbOn,
         faMobileAlt,
         faPalette,
         faPhone,
         faSignOut,
         faThumbsDown,
         faThumbsUp,
         faTriangleExclamation
       } from '@fortawesome/pro-regular-svg-icons';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import SwiperCore, { Pagination } from 'swiper';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appConfigToken, ResponseInterceptor } from './core';
import { AuthHttpInterceptor } from './core/auth-http-interceptor';
import { LogService } from './core/logging';
import { AccountService,
         AddressService,
         AlertService,
         AuthService,
         CompanyDataService,
         ContentSanitizerService,
         CustomerSearchService,
         CustomerService,
         DateRangeService,
         DummyDataService,
         EnumService,
         FileService,
         LocaleService,
         MenuDisplayService,
         NameService,
         NetworkService,
         OrderDetailsService,
         OrderItemSearchService,
         OrderItemService,
         OrderNumberService,
         OrderService,
         ProgressIndicatorService,
         ToastService,
         WorkeryApiService
       } from './services';
import { SidebarComponent } from './sidebar.component';
import { TabsComponent } from './tabs.component';
import { DynamicLocaleId } from './utility';

SwiperCore.use([Pagination]);

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    TabsComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    IonicModule.forRoot(),
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.config.auth)),
    provideAuth(() => {
      const auth: Auth = getAuth();
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://127.0.0.1:9099');
      }
      return auth;
    }),
    AppRoutingModule
  ],
  providers: [
    AccountService,
    AddressService,
    AlertService,
    AuthService,
    CompanyDataService,
    ContentSanitizerService,
    CustomerSearchService,
    CustomerService,
    DateRangeService,
    DummyDataService,
    EnumService,
    FileService,
    LocaleService,
    LogService,
    MenuDisplayService,
    NameService,
    NetworkService,
    OrderDetailsService,
    OrderItemSearchService,
    OrderItemService,
    OrderNumberService,
    OrderService,
    ProgressIndicatorService,
    ToastService,
    WorkeryApiService,
    { provide: appConfigToken, useValue: environment.config },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: LOCALE_ID, deps: [LocaleService], useClass: DynamicLocaleId },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
                     faAnalytics,
                     faArrowRight,
                     faArrowsV,
                     faCheckCircle,
                     faChevronCircleRight,
                     faCircle,
                     faCog,
                     faCommentDollar,
                     faEnvelope,
                     faGlobe,
                     faHome,
                     faFileInvoice,
                     faLightbulbOn,
                     faMobileAlt,
                     faPalette,
                     faPhone,
                     faSignOut,
                     faThumbsDown,
                     faThumbsUp,
                     faTriangleExclamation
                    );
  }
}
