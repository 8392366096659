<ion-header>
	<ion-toolbar color="primary">
		<ng-container *ngIf="this.isLoggedIn$ | async as isLoggedIn; then loggedIn; else loggedOut"></ng-container>
		<ng-template #loggedIn>
			<ion-buttons slot="start">
				<ion-back-button></ion-back-button>
			</ion-buttons>
			<ion-title>{{ this.heading }}</ion-title>
			<ng-content></ng-content>
		</ng-template>
		<ng-template #loggedOut>
			<ion-buttons slot="end">
				<ion-button [routerLink]="['/login']">Log In</ion-button>
			</ion-buttons>
		</ng-template>
	</ion-toolbar>
</ion-header>
