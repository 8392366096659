import { Inject, Injectable, OnDestroy } from '@angular/core';
import { FirebaseError } from '@angular/fire/app';
import { ActionCodeSettings, applyActionCode, Auth, confirmPasswordReset, sendPasswordResetEmail, signInWithEmailAndPassword, Unsubscribe, updatePassword, User, UserCredential } from '@angular/fire/auth';
import jwt_decode from 'jwt-decode';
import { Observable, ReplaySubject } from 'rxjs';

import { AppError, AuthErrorCode } from '../core';
import { AppConfig, appConfigToken } from '../core/app-config';
import { AuthConfig } from '../core/auth-config';
import { Logger, LogService } from '../core/logging';
import { base32guid, Editions, NewAccount } from '../core/model';
import { ErrorCode } from '../enums';
import { PouchDbService } from './pouchdb.service';
import { SettingsService } from './settings.service';
import { UserSettingsService } from './user-settings.service';
import { WorkeryApiService } from './workery-api.service';

// TODO: TASK - consider token storage (web vs app) - firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
// TODO: TASK - sort out handling password reset when compiled to apps
// TODO: TASK - replace call to sendPasswordResetEmail() with call to web API (so email matches other templates)

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  public readonly isAuthenticated$: Observable<boolean>;
  private readonly _isAuthenticated$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private readonly _authStateUnsubscribe: Unsubscribe;
  private _currentEmail: string = '';
  private _serverUri: string = '';
  private _authConfig: AuthConfig;
  private _accessToken: string = '';
  private readonly _log: Logger;

  constructor(@Inject(appConfigToken) appConfig: AppConfig,
              private _pouchDbService: PouchDbService,
              private _settingsService: SettingsService,
              private _userSettingsService: UserSettingsService,
              private _workeryApiService: WorkeryApiService,
              private _fireAuthService: Auth,
              logService: LogService) {
    this._log = logService.getLogger('AuthService');
    this._authConfig = appConfig.auth;
    this._authStateUnsubscribe = _fireAuthService.onAuthStateChanged(this.onAuthStateChanged,
                                                                     (error) => {
                                                                      this._log.fatal('Error from Auth.onAuthStateChanged', error);
                                                                    });
    this.isAuthenticated$ = this._isAuthenticated$.asObservable();
  }

  public ngOnDestroy(): void {
    this._isAuthenticated$.complete();
    this._authStateUnsubscribe();
  }

  public get currentEmail(): string {
    return this._currentEmail;
  }

  public get serverUri(): string {
    return this._serverUri;
  }

  public async getAccessToken(forceRefresh?: boolean): Promise<string> {
    let accessToken: string = this._accessToken;

    if (accessToken.length > 0) {
      accessToken = await this._fireAuthService.currentUser?.getIdToken(forceRefresh) ?? '';
    }

    return accessToken;
  }

  public getAuthToken(): Observable<string> {
    return this._workeryApiService.get<string>('auth/token');
  }

  /** The returned observable will automatically complete after the first value is emitted. */
  public login(email: string, password: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      signInWithEmailAndPassword(this._fireAuthService, email, password)
                           .then((userCredential: UserCredential) => {
                              const isAuthenticated: boolean = (userCredential.user !== null);
                              this._currentEmail = userCredential.user?.email ?? '';
                              this.setAuthenticationState(isAuthenticated);

                              observer.next(isAuthenticated);
                              observer.complete();
                            }, (error: any) => {
                              if (   error.code === AuthErrorCode.UserNotFound
                                  || error.code === AuthErrorCode.WrongPassword) {
                                this.setAuthenticationState(false);
                                observer.next(false);
                              } else {
                                this._log.fatal(error);
                                observer.error(error);
                              }

                              observer.complete();
                            });
    });
  }

  public logout(): Promise<boolean> {
    return this._fireAuthService.signOut()
                                .then(() => {
                                  this._currentEmail = '';
                                  return true;
                                }, (error: any) => {
                                  this._log.fatal('Error signing out of application', error);
                                  return false;
                                });
  }

  /** The returned observable will automatically complete after the first 'value' is emitted. */
  public sendPasswordResetEmail(email: string, urlOrigin: string): Observable<void> {
    /*<li><p>url: Sets the link continue/state URL, which has different meanings
    *     in different contexts:</p>
    *     <ul>
    *     <li>When the link is handled in the web action widgets, this is the deep
    *         link in the continueUrl query parameter.</li>
    *     <li>When the link is handled in the app directly, this is the continueUrl
    *         query parameter in the deep link of the Dynamic Link.</li>
    *     </ul>
    *     </li>
    * <li>iOS: Sets the iOS bundle ID. This will try to open the link in an iOS app
    *     if it is installed.</li>
    * <li>android: Sets the Android package name. This will try to open the link in
    *     an android app if it is installed. If installApp is passed, it specifies
    *     whether to install the Android app if the device supports it and the app
    *     is not already installed. If this field is provided without a
    *     packageName, an error is thrown explaining that the packageName must be
    *     provided in conjunction with this field.
    *     If minimumVersion is specified, and an older version of the app is
    *     installed, the user is taken to the Play Store to upgrade the app.</li>
    * <li>handleCodeInApp: The default is false. When set to true, the action code
    *     link will be be sent as a Universal Link or Android App Link and will be
    *     opened by the app if installed. In the false case, the code will be sent
    *     to the web widget first and then on continue will redirect to the app if
    *     installed.</li>
    */
    const continueUrl: URL = new URL('/reset-password', urlOrigin);
    const settings: ActionCodeSettings = {
// TODO: store these settings in environment
      // android?: {
      //   installApp?: boolean;
      //   minimumVersion?: string;
      //   packageName: string;
      // };
      // handleCodeInApp?: boolean;
      // iOS?: { bundleId: string };
      url: continueUrl.href
      // dynamicLinkDomain?: string;
    };

    return new Observable<void>(observer => {
      sendPasswordResetEmail(this._fireAuthService, email, settings)
                       .then(() => {
                          observer.next();
                          observer.complete();
                        }, (error: any) => {
                          this._log.error(`Error sending password reset email for '${email}'`, error);

                          let errorCode: ErrorCode = ErrorCode.Unknown;
                          switch (error.code) {
                            case AuthErrorCode.UserNotFound:
                              errorCode = ErrorCode.UserNotFound;
                              break;

                            case AuthErrorCode.InvalidEmail:
                            case AuthErrorCode.MissingAndroidPkgName:
                            case AuthErrorCode.MissingContinueUri:
                            case AuthErrorCode.MissingIosBundleId:
                            case AuthErrorCode.InvalidContinueUri:
                            case AuthErrorCode.UnauthorizedContinueUri:
                              errorCode = ErrorCode.Unknown;
                              break;

                            default:
                              this._log.warn(`Unrecognised auth error code - ${error.code}`, error);
                              break;
                          }

                          observer.error(errorCode);
                          observer.complete();
                        });
    });
  }

  /** The returned observable will automatically complete after the first 'value' is emitted. */
  public resetPassword(code: string, newPassword: string): Observable<void> {
    return new Observable<void>(observer => {
      confirmPasswordReset(this._fireAuthService, code, newPassword)
                     .then(() => {
                        observer.next();
                        observer.complete();
                      }, (error: any) => {
                        this._log.error(`Error resetting password`, error);

                        const errorCode: ErrorCode = this.getErrorCode(error.code);
                        observer.error(errorCode);
                        observer.complete();
                      });
    });
  }

  /** The returned observable will automatically complete after the first 'value' is emitted. */
  public changePassword(currentPassword: string, newPassword: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const email: string = this._currentEmail;
      const log: Logger = this._log;

      /* First, try to sign the user in, which will confirm that the email and current password
        are correct.  It will also ensure that the user has logged in recently enough that the
        change password request is accepted.
      */
      signInWithEmailAndPassword(this._fireAuthService, email, currentPassword)
                           .then((userCredential: UserCredential) => {
                              if (userCredential.user) {
                                updatePassword(userCredential.user, newPassword)
                                         .then(() => {
                                            observer.next(true);
                                            observer.complete();
                                          })
                                          .catch(function(error) {
                                            log.error(`Error trying to change password for ${email}`, error);
                                            observer.error(error);
                                            observer.complete();
                                          });
                              } else {
                                log.error(`No user returned in credentials during password change for ${email}`);
                                observer.error();
                                observer.complete();
                              }
                            }, (error: any) => {
                              if (error.code === AuthErrorCode.WrongPassword) {
                                observer.next(false);
                              } else {
                                this._log.error(`Error checking credentials password change for ${email}`, error);
                                observer.error(error);
                              }

                              observer.complete();
                            });
    });
  }

  public validateApiKey(apiKey: string): boolean {
    return apiKey === this._authConfig.apiKey;
  }

  public completeAuthAction(code: string): Promise<void> {
    return applyActionCode(this._fireAuthService, code)
              .then(() => {
                /* Nothing to do */
              }, (error: FirebaseError) => {
                this._log.error(`Error occurred processing Firebase action code: ${code}`, error);
                throw new AppError(this.getErrorCode(error.code), 'Error occurred processing Firebase action code', error);
              });
  }

  public getPasswordExcludes = (email?: string): string[] => {
    const passwordExcludes: string[] = [
      'workery'
    ];

    if (typeof(email) === 'undefined') {
      email = this._currentEmail;
    }

    if (email.length > 0) {
      let parts: string[] = email.split('@');
      passwordExcludes.push(email, parts[0]);

      if (parts.length > 1) {
        passwordExcludes.push(parts[1]);

        /* It's too complicated (and time-consuming) to extract the domain name 'properly', so
          we'll cheat and just include any parts that are long enough to be a valid password
          (which will exclude pretty much all of the gTLDs).
        */
        parts = parts[1].split('.');
        for (const part of parts) {
          if (part.length >= NewAccount.MinStringLengths.Password) {
            passwordExcludes.push(part);
          }
        }
      }
    }

    return passwordExcludes;
  };

  private onAuthStateChanged = (user: User | null): void => {
    if (user) {
      user.getIdTokenResult()
          .then((idTokenResult) => {
            this._log.debug('AngularFireAuth.idTokenResult received');

            /* Perform initialization BEFORE updating the auth state to ensure that
              everything's ready for use prior to any components trying to reference
              them.
            */
            this.initializeFromAccessToken(idTokenResult.token);
            this.setAuthenticationState(true);
          })
          .catch((error) => {
            this._log.error('Error getting id token result - logging user out', error);
            this.logout();
          });
    } else {
      this._log.debug('AngularFireAuth.idTokenResult not available');

      /* Update the auth state BEFORE shutting down to ensure that components
        (hopefully) remove any references before they disappear.
      */
      this.setAuthenticationState(false);
      this.shutdown();
    }
  };

  private setAuthenticationState(isAuthenticated: boolean): void {
    this._log.debug(`Auth State => ${isAuthenticated ? 'authenticated' : 'NOT authenticated'}`);
    this._isAuthenticated$.next(isAuthenticated);
  }

  private initializeFromAccessToken(accessToken: string): boolean {
    let isValid: boolean = false;

    try {
      const header: any = jwt_decode(accessToken, { header: true });
      const token: any = jwt_decode(accessToken);

      isValid = header.typ === 'JWT'
             && header.alg === this._authConfig.accessTokenHeaderAlg
             && token.iss === this._authConfig.issuer
             && token.aud.includes(this._authConfig.projectId)
             // eslint-disable-next-line @typescript-eslint/no-magic-numbers
             && (token.exp * 1000) > Date.now();    /* token.exp is in seconds, so convert to millisecs */

      if (isValid) {
        const accountId: string = token[`${this._authConfig.namespace}accountid`];
        const userIdToken: base32guid = token.user_id;
        const serverUri: string = token[`${this._authConfig.namespace}serverUri`];
        const database: string = token[`${this._authConfig.namespace}database`];
        const username: string = token[`${this._authConfig.namespace}username`];
        const password: string = token[`${this._authConfig.namespace}password`];
        const edition: Editions = token[`${this._authConfig.namespace}edition`];
        const expiryDate: Date = new Date(token[`${this._authConfig.namespace}expiryDate`]);

        this._accessToken = accessToken;
        this._currentEmail = token.email;
        this._serverUri = serverUri;

        this._pouchDbService.initialize(serverUri, database, username, password, (initialized: boolean) => {
          if (initialized) {
            this._settingsService.initialize(accountId, userIdToken, edition, expiryDate);
            this._userSettingsService.initialize(userIdToken);
            this._pouchDbService.registerForChanges(accountId, this._settingsService.onAccountChanges);
          } else {
            /* Any initialization failure is pretty much catastrophic */
            this._log.error('Initialization of PouchDbService failed (shutting down)');
            this.shutdown(true);
          }
        });
      } else {
        this._log.warn('Invalid access token (shutting down)');
        this.shutdown(true);
      }
    } catch (error) {
      this._log.error('Error checking token state (shutting down)', error);
      this.shutdown(true);
    }

    return isValid;
  }

  private shutdown(onError: boolean = false): void {
    this._accessToken = '';
    this._currentEmail = '';
    this._settingsService.shutdown(onError);
    this._pouchDbService.shutdown();
  }

  private getErrorCode(authErrorCode: AuthErrorCode | string): ErrorCode {
    let errorCode: ErrorCode = ErrorCode.Unknown;

    switch (authErrorCode) {
      case AuthErrorCode.ExpiredActionCode:
        errorCode = ErrorCode.ExpiredAuthCode;
        break;

      case AuthErrorCode.InvalidActionCode:
        errorCode = ErrorCode.InvalidAuthCode;
        break;

      case AuthErrorCode.UserDisabled:
        errorCode = ErrorCode.UserDisabled;
        break;

      case AuthErrorCode.UserNotFound:
        errorCode = ErrorCode.UserNotFound;
        break;

      case AuthErrorCode.WeakPassword:
        errorCode = ErrorCode.WeakPassword;
        break;

      default:
        this._log.warn(`Unrecognised auth error code - ${authErrorCode}`);
        break;
    }

    return errorCode;
  }
}
