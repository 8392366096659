import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, from, Observable, switchMap } from 'rxjs';

import { AuthService } from '../services';
import { AppConfig, appConfigToken } from './app-config';
import { Logger, LogService } from './logging';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  private readonly _log: Logger;
  private readonly _workeryApiServiceBaseUri: string;

  constructor(private _authService: AuthService,
              @Inject(appConfigToken) appConfig: AppConfig,
              logService: LogService) {
     this._log = logService.getLogger('AuthInterceptor');
     this._workeryApiServiceBaseUri = appConfig.workeryApiServiceBaseUri.toUpperCase();
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this._authService.getAccessToken())
              .pipe(switchMap((accessToken) => {
                      const isApiUrl: boolean = request.url.toUpperCase()
                                                           .startsWith(this._workeryApiServiceBaseUri);
                      if (isApiUrl && accessToken.length > 0) {
                        this._log.debug(`Setting 'Authorization' header on HTTP request`);
                        request = request.clone({
                                                  setHeaders: {
                                                    Authorization: `Bearer ${accessToken}`
                                                  }
                                                });
                      }

                      return next.handle(request)
                                 .pipe(catchError((error: any) => {
                                        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Unauthorized) {
                                          /* Either the user isn't logged in or the access token credentials are no longer valid */
                                          this._log.debug('Request is unauthorized');
                                        }

                                        throw error;
                                       }));
                    }));
  }
}
