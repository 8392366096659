import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,   /* To prevent data always being locally cached, amongst other things */
  useEmulators: false,
  config: {
    schemaVersion: 1,
    webUri: 'https://test.workery.net/',
    termsUrl: 'https://www.workery.net/terms',
    trialPeriodInDays: 45,
    workeryApiServiceBaseUri: 'https://wky-test-asvc-web-api.azurewebsites.net/',
    dataUris: [
      'https://data-gb.workery.net:6984/'
    ],
    auth: {
      namespace: 'https://api.workery.io/',
      apiKey: 'AIzaSyDAIOk5C9WeY7lrjwFJRYrJCgGvteAACbo',
      authDomain: 'workery-test.firebaseapp.com',
// TODO: TASK - is this being used for the audience?
      projectId: 'workery-test',
      storageBucket: 'workery-test.appspot.com',
      locationId: 'europe-west',
      messagingSenderId: '952267810794',
      appId: '1:952267810794:web:4924d2ff30ddab4e8db493',
      issuer: 'https://securetoken.google.com/workery-test',
      accessTokenHeaderAlg: 'RS256'
    },
    logging: {
      routing: false,
      pouchDb: false
    }
  }
};
