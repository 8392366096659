/**
 * @returns true if the arrays contain the same string values, ignoring the array order
 */
export function isEqualStringArrays(array1: string[], array2: string[]): boolean {
  return array1
      && array2
      && (array1.length === array2.length)
      && (array1.filter(item => !array2.includes(item)).length === 0);
}

/**
 * @param format String format using numeric placeholders - {0}, {1}, etc - for the location
 * of each argument in the output.
 */
export function stringFormat(format: string, ...args: any[] ): string {
  return format.replace(/(?<!\\){(\d+)}/g, (_match, index) => {
                  const arg: any = args[index];
                  return typeof(arg) === 'undefined' ? ''
                                                     : (arg instanceof Date ? arg.toLocaleDateString()
                                                                            : arg);
                })
               .replace('\\{', '{');
}

/**
 * Formats the value as a quantity of bytes.
 * Taken from: https://stackoverflow.com/a/18650828/4591974
 *
 * @param bytes The number of bytes to format
 * @param decimals The number of decimal places to format the value to
 * @returns The formatted value
 */
export function formatBytes(bytes: number, decimals: number = 2): string {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k: number = 1024;
  const dm: number = decimals < 0 ? 0 : decimals;
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i: number = Math.floor(Math.log(bytes) / Math.log(k));

  return `${Number.parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/**
 * Performs locale-specific, case-insensitive comparison of two strings
 */
export function stringEqualsIgnoreCase(string1: string, string2: string): boolean {
  return string1.toLocaleUpperCase() === string2.toLocaleUpperCase();
}
