export const enum AccountStatus {
  None             = 0x00,
  MissingTemplate  = 0x01,
  MissingColours   = 0x02,
  MissingContent   = 0x04,
  FinishOnboarding = 0x08,
  // eslint-disable-next-line no-bitwise -- we know what we're doing
  StartOnboarding  = MissingTemplate | MissingColours | MissingContent | FinishOnboarding
}

export const enum AddressFormat {
// TODO: TASK - sort out other formats (at least a default)
  Default,
  British,
  European,
  NorthAmerican
}

export const enum CompanyType {
  NotSpecified                = 0,
  SoleTrader                  = 1,
  LimitedCompany              = 2,
  Partnership                 = 3,
  LimitedLiabilityPartnership = 4,
  PublicLimitedCompany        = 5,
  Other                       = 99
}

export const enum Continent {
  Europe = 'EU',
  Asia = 'AS',
  NorthAmerica = 'NA',
  SouthAmerica = 'SA',
  Africa = 'AF',
  Oceania = 'OC'
}

export /* not const */ enum Editions {
  NotSpecified = 0x0,
  Trial        = 0x1,
  Standard     = 0x2
}

/* Okay, this is not really an enum; more a convenient group of string constants */
export const enum IdPrefix {
  Account = 'account-',
  Customer = 'customer-',
  OrderDetails = 'orderdetails-',
  OrderItem = 'orderitem-',
  UserProfile = 'user-',
  UserSettings = 'usersettings-'
}

/* IMPORTANT  These values are used to order the rows when returned for the Order List, so must
  be in the appropriate order numerically.
*/
export const enum OrderState {
  /** Used to indicate a new, i.e. unsaved, order */
  NotSpecified = 0x0,
  InProgress   = 0x1,
  Raised       = 0x2,
  Paid         = 0x4
}

export enum OrderType {   /* Cannot be const */
  NotSpecified = 0x0,
  Invoice      = 0x1,
  Quote        = 0x2
}
