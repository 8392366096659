export enum DateRangePeriod {   /* Cannot be const */
  Custom,
  ThisMonth,
  LastMonth,
  /** The last 3 full months, plus the current month */
  Last3MonthsPlus,
  ThisFinQuarter,
  LastFinQuarter,
  ThisFinYear,
  LastFinYear
}

export class DateRange {
  constructor(public period: DateRangePeriod,
              public from: Date,
              public to: Date) {}

  public toInterval(): Interval {
    return { start: this.from,
             end: this.to
           };
  }
}
