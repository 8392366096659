import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { canActivateIfUnexpired } from './core/account-expiry.guard';
import { canActivateIfAuthorized } from './core/auth.guard';
import { OrderType } from './core/model';
import { canActivateForOnboarding } from './core/onboarding.guard';
import { SidebarComponent } from './sidebar.component';
import { TabsComponent } from './tabs.component';
import { DashboardPage } from './views/dashboard/dashboard.module';
import { LoadingPage } from './views/loading/loading.module';
import { InvoiceListPage, QuoteListPage } from './views/order-list/order-list.module';

const routes: Routes = [
  /* Pages that don't need the menu/tabs displaying */
  { path: 'signup', pathMatch: 'full', loadChildren: () => import('./views/sign-up/sign-up.module').then(m => m.SignUpModule) },
  { path: 'signup/complete', redirectTo: '/onboarding' },
  { path: 'onboarding', loadChildren: () => import('./views/onboarding/onboarding.module').then(m => m.OnboardingModule) },
  { path: 'login', loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule) },
  { path: 'logout', loadChildren: () => import('./views/logout/logout.module').then(m => m.LogoutModule) },
  { path: 'loading', component: LoadingPage },
  { path: 'forgotten-password', loadChildren: () => import('./views/forgotten-password/forgotten-password.module').then(m => m.ForgottenPasswordModule) },
  { path: 'reset-password', loadChildren: () => import('./views/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'verify-email', loadChildren: () => import('./views/verify-email/verify-email.module').then(m => m.VerifyEmailModule) },
  { path: 'auth', loadChildren: () => import('./views/auth-action/auth-action.module').then(m => m.AuthActionModule) },

  /* The order print and view pages are accessible to both anonymous and authenticated users.
    Using distinct routes means that we can use the AuthGuard for authenticated access to
    ensure that initialization of the AuthService, etc, is triggered.

    So for anonymous access...
  */
  { path: 'order/:id/print', loadChildren: () => import('./views/order-print/order-print.module').then(m => m.OrderPrintModule) },
  { path: 'order/:id/view', loadChildren: () => import('./views/order-view/order-view.module').then(m => m.OrderViewModule) },

  { path: '', component: SidebarComponent, children: [
      { path: '', component: TabsComponent, children: [
          { path: '', pathMatch: 'full', component: DashboardPage, ...canActivateForOnboarding() },
          { path: 'dashboard', component: DashboardPage, ...canActivateIfAuthorized() },

          /* ...and now for authenticated access */
          { path: 'quotes/:id/print', loadChildren: () => import('./views/order-print/order-print.module').then(m => m.OrderPrintModule), ...canActivateIfAuthorized() },
          { path: 'invoices/:id/print', loadChildren: () => import('./views/order-print/order-print.module').then(m => m.OrderPrintModule), ...canActivateIfAuthorized() },
          { path: 'quotes/:id/view', loadChildren: () => import('./views/order-view/order-view.module').then(m => m.OrderViewModule), ...canActivateIfAuthorized() },
          { path: 'invoices/:id/view', loadChildren: () => import('./views/order-view/order-view.module').then(m => m.OrderViewModule), ...canActivateIfAuthorized() },

          { path: 'quotes/:orderid/item/:itemid', loadChildren: () => import('./views/order-item/order-item.module').then(m => m.OrderItemModule), ...canActivateIfAuthorized() },
          { path: 'invoices/:orderid/item/:itemid', loadChildren: () => import('./views/order-item/order-item.module').then(m => m.OrderItemModule), ...canActivateIfAuthorized() },
          { path: 'quotes/add', loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule), ...canActivateIfUnexpired({ orderType: OrderType.Quote }) },
          { path: 'invoices/add', loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule), ...canActivateIfUnexpired({ orderType: OrderType.Invoice }) },
          { path: 'quotes/:id', loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule), ...canActivateIfAuthorized({ orderType: OrderType.Quote }) },
          { path: 'invoices/:id', loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule), ...canActivateIfAuthorized({ orderType: OrderType.Invoice }) },
          { path: 'quotes', component: QuoteListPage, ...canActivateIfAuthorized() },
          { path: 'invoices', component: InvoiceListPage, ...canActivateIfAuthorized() },

          { path: 'account', loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule) },
          { path: 'settings', loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule) },
          { path: '.well-known/change-password', loadChildren: () => import('./views/change-password/change-password.module').then(m => m.ChangePasswordModule) },
          { path: 'about', loadChildren: () => import('./views/about/about.module').then(m => m.AboutModule) },
          { path: 'not-found', loadChildren: () => import('./views/not-found/not-found.module').then(m => m.NotFoundModule) }
        ]
      }
    ]
  },
  { path: '**', loadChildren: () => import('./views/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
                         {
                          enableTracing: environment.config.logging.routing,
                          preloadingStrategy: PreloadAllModules,
                          relativeLinkResolution: 'legacy'
                         })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
