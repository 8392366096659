import { CouchDbEntity } from './couchdb-entity';
import { OrderType } from './enums';

export interface IUserSettings {
  firstName?: string;
  lastName?: string;
  email?: string;
  notificationFlags?: number;
  quoteMessage?: string;
  invoiceMessage?: string;
  lastPreviewOrderType?: OrderType;
}

export class UserSettings extends CouchDbEntity implements IUserSettings {
  public readonly firstName: string = '';
  public readonly lastName: string = '';
  public readonly email: string = '';
  public readonly notificationFlags: number = 0;
  public readonly quoteMessage: string = '';
  public readonly invoiceMessage: string = '';
  public readonly lastPreviewOrderType: OrderType = OrderType.Invoice;

  constructor(userSettings?: UserSettings) {
    super(userSettings);

    if (userSettings) {
      this.firstName = userSettings.firstName ?? '';
      this.lastName = userSettings.lastName ?? '';
      this.email = userSettings.email ?? '';
      this.notificationFlags = userSettings.notificationFlags;
      this.quoteMessage = userSettings.quoteMessage ?? '';
      this.invoiceMessage = userSettings.invoiceMessage ?? '';
      this.lastPreviewOrderType = userSettings.lastPreviewOrderType ?? OrderType.Invoice;
    }
  }
}
