import { getDate } from '../../utility';
import { CouchDbEntity } from './couchdb-entity';
import { Customer, IHasCustomer } from './customer';
import { OrderState, OrderType } from './enums';
import { base32guid } from './types';

enum MaxStringLengths {
  CustomerName = Customer.MaxStringLengths.FullName,
  OrderNumber = 50,
  Description = 2000
}

export class OrderDetails extends CouchDbEntity implements IHasCustomer {
  public static readonly MaxStringLengths = MaxStringLengths;

  public readonly accountId: base32guid = '';
  public readonly customerId: base32guid = '';
  public readonly customerName: string = '';
  public readonly streetAddress: string = '';
  public readonly city: string = '';
  public readonly state: string = '';
  public readonly postcode: string = '';
  public readonly countryCode: string = '';
  public readonly country: string = '';
  public readonly orderType: OrderType = OrderType.NotSpecified;
  public readonly orderNumber: string = '';
  public readonly description: string = '';
  /**
   * Although the OrderState enum values are bit-flags, the `orderState` field is only ever set
   * to a single value (so a paid invoice has a state of 'Paid' even though, strictly speaking,
   * it is also 'Raised').
   */
  public readonly orderState: OrderState = OrderState.NotSpecified;
  public readonly raisedByUserId: base32guid = '';
  /** If set, will be the date and time that the order was raised */
  public readonly dateRaised?: Date;
  /** If set, should be 23:59:59.999 on the specified day */
  public readonly dateDue?: Date;
  public readonly datePaid?: Date;
  public readonly isFixedPrice: boolean = true;
  public readonly currencyCode: string = '';
  public readonly includeTax: boolean = false;
  public readonly netTotal: number = 0;
  public readonly taxTotal: number = 0;
  public readonly grossTotal: number = 0;
  public readonly archived: boolean = false;

  constructor(orderOrType: OrderDetails | OrderType) {
    super(typeof(orderOrType) === 'number' ? undefined : orderOrType as OrderDetails);

    if (typeof(orderOrType) === 'number') {
      this.orderType = orderOrType as OrderType;
    } else {
      const order: OrderDetails = orderOrType as OrderDetails;

      this.accountId = order.accountId;
      this.customerId = order.customerId ?? '';
      this.customerName = order.customerName ?? '';
      this.streetAddress = order.streetAddress ?? '';
      this.city = order.city ?? '';
      this.state = order.state ?? '';
      this.postcode = order.postcode ?? '';
      this.countryCode = order.countryCode ?? '';
      this.country = order.country ?? '';
      this.orderType = order.orderType ?? OrderType.NotSpecified;
      this.orderNumber = order.orderNumber ?? '';
      this.description = order.description ?? '';
      this.orderState = order.orderState ?? OrderState.NotSpecified;
      this.raisedByUserId = order.raisedByUserId ?? '';
      this.dateRaised = order.dateRaised ? getDate(order.dateRaised) : undefined;
      this.dateDue = order.dateDue ? getDate(order.dateDue) : undefined;
      this.datePaid = order.datePaid ? getDate(order.datePaid) : undefined;
      this.isFixedPrice = order.isFixedPrice;
      this.currencyCode = order.currencyCode ?? '';
      this.includeTax = order.includeTax;
      this.netTotal = order.netTotal ?? 0;
      this.taxTotal = order.taxTotal ?? 0;
      this.grossTotal = order.grossTotal ?? 0;
      this.archived = order.archived;
    }
  }
}
