import { Observable } from 'rxjs';

import { LogService } from '../core/logging';
import { CouchDbEntity } from '../core/model';
import { stringify } from '../utility';
import { EntityDataHelper } from './entity-data-helper.service';
import { PouchDbDataService } from './pouchdb-data.service';
import { PouchDbService } from './pouchdb.service';

export abstract class PouchDbEntityDataService<T extends CouchDbEntity> extends PouchDbDataService<T> {
  private readonly _entityDataHelper: EntityDataHelper<T>;

  constructor(protected readonly idPrefix: string,
              pouchDbService: PouchDbService,
              logService: LogService) {
    super(pouchDbService,
          logService);
    this._entityDataHelper = new EntityDataHelper<T>(idPrefix);
  }

  protected abstract getInstanceFromDoc(doc: any): T;

  protected getInstanceFromRow(row: any): T {
    return this.getInstanceFromDoc(row.doc);
  }

  protected EnsureUniqueIdAssigned(entity: T): T {
    return this._entityDataHelper.EnsureUniqueIdAssigned(entity);
  }

  protected assignUniqueId(entity: T): T {
    return this._entityDataHelper.assignUniqueId(entity);
  }

  protected clone(entity: T, rev?: string): T {
    return this._entityDataHelper.clone(entity, rev);
  }

// TODO: TASK - work out the best alternative to disabling this
  // eslint-disable-next-line @typescript-eslint/ban-types -- corresponds to return type of Object.assign()
  protected toObject(entity: T): {} {
    return this._entityDataHelper.toObject(entity);
  }

//   public getAll(): Observable<T[]> {
//     return new Observable<T[]>(observer => {
//       this.pouchDbService.Database.allDocs<T>({ include_docs: true,
//                                                 startkey: this.idPrefix,
//                                                 endkey: this.idPrefix + HIGHEST_CHARACTER
//                                               })
//                                   .then((response: PouchDB.Core.AllDocsResponse<T>) => {
//                                     const docs: T[] = response.rows.map((row: any) => {
//                                       return this.getInstanceFromDoc(row.doc);
//                                     });

//                                     observer.next(docs);
//                                     observer.complete();
//                                    }, (reason: any) => {
// // TODO: TASK - handle 404s (as they're expected by PouchDB when it checks for remote endpoints)
//                                     this._log.error(stringify(reason));
//                                     observer.error(reason);
//                                     observer.complete();
//                                    });
//     });
//   }

  public getById(id: string): Observable<T> {
    this.log.debug(`Calling getById('${id}') [${this.idPrefix}]`);

    if (!id.startsWith(this.idPrefix)) {
      id = this.idPrefix + id;
    }

    return new Observable<T>(observer => {
      this.pouchDbService.Database.get<T>(id)
                                  .then((doc: T) => {
                                    observer.next(this.getInstanceFromDoc(doc));
                                    observer.complete();
                                  }, (error: any) => {
                                    observer.error(error);
                                    observer.complete();
                                  });
    });
  }

  public upsert(entity: T): Observable<T> {
    entity = this._entityDataHelper.EnsureUniqueIdAssigned(entity);

    return new Observable<T>(observer => {
      this.pouchDbService.Database.put(this._entityDataHelper.toObject(entity))
                                  .then((response: any) => {
                                    let updatedEntity: T;

                                    if (response.ok) {
                                      updatedEntity = this._entityDataHelper.clone(entity, response.rev);
                                    } else {
// TODO: TASK - handle error somehow
                                      console.error('save() - Something went wrong (but don\'t know where the error info is yet)',
                                                    stringify(response));
                                      updatedEntity = this._entityDataHelper.clone(entity);
                                    }

                                    observer.next(updatedEntity);
                                    observer.complete();
                                  }, (error: any) => {
                                    observer.error(error);
                                    observer.complete();
                                  });
    });
  }

  // public delete(id: string | number): Observable<string | number> {
  //   throw new Error('Method delete() not implemented.');
  // }
}
