import { Inject, Injectable } from '@angular/core';
import { catchError, concatMap, from, map, Observable, of } from 'rxjs';

import { AppConfig, appConfigToken } from '../core';
import { Logger, LogService } from '../core/logging';
import { base32guid } from '../core/model';
import { NotificationService } from './notification.service';
import { ToastService } from './toast.service';
import { WorkeryApiService } from './workery-api.service';

@Injectable({
  providedIn: 'root'
})
export class SchemaCheckService {
  private _appSchemaVersion: number;
  private readonly _log: Logger;

  constructor(@Inject(appConfigToken) appConfig: AppConfig,
              private _workeryApiService: WorkeryApiService,
              private _notificationService: NotificationService,
              private _toastService: ToastService,
              logService: LogService) {
    this._log = logService.getLogger(this.constructor.name);
    this._appSchemaVersion = appConfig.schemaVersion;
  }

  public ensureLatestVersion(accountIdToken: base32guid, dbSchemaVersion: number): Observable<boolean> {
    if (this._appSchemaVersion > dbSchemaVersion) {
      return from(this._notificationService.showModal('We\'re just updating your account to the latest version - it shouldn\'t take long, so please wait...'))
                .pipe(concatMap((popover: void | HTMLIonPopoverElement) => {
                        if (popover) {
                          return this._workeryApiService.patch(`accounts/${accountIdToken}/schema`,
                                                               this._appSchemaVersion)
                                                        .pipe(map(() => {
                                                                popover.dismiss();
                                                                return true;
                                                              }),
                                                              catchError((err) => {
                                                                this._log.error(err);
                                                                popover.dismiss();
                                                                this._toastService.showError('Sorry - there was a problem updating your account info to the latest version; please contact the Workery support team');
                                                                return of(false);
                                                              }));
                        } else {
                          return of(false);
                        }
                      }));

//     } else if (this._appSchemaVersion < dbSchemaVersion) {
// // TODO: app needs updating - what should we do?
    }

    return of(false);
  }
}
