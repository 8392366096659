import { Injectable } from '@angular/core';
import { concatMap, Observable, of } from 'rxjs';

import { QueryParams } from '../core';
import { LogService } from '../core/logging';
import { IdPrefix, OrderDetails, OrderItem, Settings } from '../core/model';
import { OrderDetailsService, ORDERS_BY_ORDERDETAILSID_START_INDEX } from './order-details.service';
import { PouchDbEntityDataService } from './pouchdb-entity-data.service';
import { PouchDbService } from './pouchdb.service';
import { SettingsService } from './settings.service';

@Injectable({ providedIn: 'root' })
export class OrderItemService extends PouchDbEntityDataService<OrderItem> {
  constructor(private _orderDetailsService: OrderDetailsService,
              private _settingsService: SettingsService,
              pouchDbService: PouchDbService,
              logService: LogService) {
    super(IdPrefix.OrderItem, pouchDbService, logService);
  }

  public getByOrderId(orderDetailsId: string): Observable<OrderItem[]> {
    const queryParams: QueryParams = new QueryParams('orderItems/byOrderDetailsId',
                                                     [ orderDetailsId, 0 ],
                                                     [ orderDetailsId, Number.MAX_SAFE_INTEGER ]);
    return this.getWithQuery(queryParams);
  }

  public getNewInstance(orderId: string): Observable<OrderItem> {
    const settings: Settings = this._settingsService.currentSettings;

    return of({ ...new OrderItem(),
                orderId,
                itemNumber: Date.now(), /* Use ticks so item is at least in 'expected' order even if not contiguous */
                taxRate: settings.taxRate
              });
  }

  public upsert(entity: OrderItem): Observable<OrderItem> {
    let updatedOrderItem: OrderItem;

    return super.upsert(entity)
                .pipe(concatMap((orderItem: OrderItem) => {
                        updatedOrderItem = orderItem;

                        const orderDetailsId: string = IdPrefix.OrderDetails + orderItem.orderId;
                        const queryParams: QueryParams = new QueryParams('orders/byOrderDetailsId',
                                                                         [ orderDetailsId, ORDERS_BY_ORDERDETAILSID_START_INDEX ],
                                                                         [ orderDetailsId, Number.MAX_SAFE_INTEGER ]);

                        return this.getMultiWithQuery(queryParams);
                      }),
                      concatMap((docs: any[]) => {
                        if (docs.length === 0) {    /* Just in case */
                          const message: string = `No database entries found for order '${entity.orderId}' (item #${entity._id})`;
                          this.log.error(message);
                          throw new Error(message);
                        }

                        let netTotal: number = 0;
                        let taxTotal: number = 0;
                        let grossTotal: number = 0;

                        for (let i: number = 1; i < docs.length; i++) {
                          const orderItem: OrderItem = new OrderItem(docs[i]);

                          netTotal += orderItem.netAmount;
                          taxTotal += orderItem.taxAmount;
                          grossTotal += orderItem.grossAmount;
                        }

                        const orderDetails: OrderDetails = new OrderDetails({ ...docs[0],
                                                                              netTotal,
                                                                              taxTotal,
                                                                              grossTotal
                                                                            });
                        return this._orderDetailsService.upsert(orderDetails);
                      }),
                      concatMap((_: OrderDetails) => {
                        return of(updatedOrderItem);
                      }));
  }

  public add(orderItems: OrderItem[]): Observable<OrderItem[]> {
    return new Observable<OrderItem[]>(observer => {
      this.pouchDbService.Database.bulkDocs<OrderItem>(orderItems)
                                  .then((responses: any[]) => {
                                    const updatedItems: OrderItem[] = [];
                                    let updatedItem: OrderItem;

                                    for (const [i, response] of responses.entries()) {
                                      if (response.ok) {
                                        updatedItem = this.clone(orderItems[i], response.rev);
                                      } else {
// TODO: TASK - handle error somehow
                                        console.error('save() - Something went wrong (but don\'t know where the error info is yet)');
                                        updatedItem = this.clone(orderItems[i]);
                                      }
                                      updatedItems.push(updatedItem);
                                    }

                                    observer.next(updatedItems);
                                    observer.complete();
                                  });
    });
  }

  public delete(entity: OrderItem): Observable<OrderItem> {
    /* Deleting is really just an upsert; flag the entity as deleted and save, then we can update the totals on the order */
    const deletedOrderItem: OrderItem = { ...entity,
                                          _deleted: true
                                        };
    return this.upsert(deletedOrderItem);
  }

  protected getInstanceFromDoc(doc: any): OrderItem {
    const isOrderItem: boolean = (doc instanceof OrderItem);
    const orderItem: OrderItem = isOrderItem ? doc as OrderItem
                                             : new OrderItem(doc);

    return orderItem;
  }
}
