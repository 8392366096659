import { AddressFormat, Continent, CountryInfo } from '../core/model';

export const SUPPORTED_COUNTRIES: Map<string, CountryInfo> = new Map([
  ['AD', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Andorra
  ['AE', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: true , currencyCode: 'AED' }],   // UNCHECKED United Arab Emirates
  ['AF', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'AFN' }],   // UNCHECKED Afghanistan
  ['AG', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XCD' }],   // UNCHECKED Antigua and Barbuda
  ['AI', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XCD' }],   // UNCHECKED Anguilla
  ['AL', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'ALL' }],   // UNCHECKED Albania
  ['AM', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'AMD' }],   // UNCHECKED Armenia
  ['AO', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'AOA' }],   // UNCHECKED Angola
  ['AR', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'ARS' }],   // UNCHECKED Argentina
  ['AS', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED American Samoa
  ['AT', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Austria
  ['AU', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: true , currencyCode: 'AUD' }],   // UNCHECKED Australia
  ['AW', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'AWG' }],   // UNCHECKED Aruba
  ['AX', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Åland Islands
  ['AZ', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'AZN' }],   // UNCHECKED Azerbaijan
  ['BA', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'BAM' }],   // UNCHECKED Bosnia and Herzegovina
  ['BB', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'BBD' }],   // UNCHECKED Barbados
  ['BD', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'BDT' }],   // UNCHECKED Bangladesh
  ['BE', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Belgium
  ['BF', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XOF' }],   // UNCHECKED Burkina Faso
  ['BG', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'BGN' }],   // UNCHECKED Bulgaria
  ['BH', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'BHD' }],   // UNCHECKED Bahrain
  ['BI', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'BIF' }],   // UNCHECKED Burundi
  ['BJ', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XOF' }],   // UNCHECKED Benin
  ['BL', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Saint Barthélemy
  ['BM', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'BMD' }],   // UNCHECKED Bermuda
  ['BN', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'BND' }],   // UNCHECKED Brunei
  ['BO', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'BOB' }],   // UNCHECKED Bolivia
  ['BQ', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED Bonaire, Sint Eustatius and Saba
  ['BR', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: true , currencyCode: 'BRL' }],   // UNCHECKED Brazil
  ['BS', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'BSD' }],   // UNCHECKED Bahamas
  ['BT', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'BTN' }],   // UNCHECKED Bhutan
  ['BV', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'NOK' }],   // UNCHECKED Bouvet Island
  ['BW', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'BWP' }],   // UNCHECKED Botswana
  ['BY', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'BYN' }],   // UNCHECKED Belarus
  ['BZ', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'BZD' }],   // UNCHECKED Belize
  ['CA', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.NorthAmerican, hasStates: true , currencyCode: 'CAD' }],   // Canada
  ['CC', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'AUD' }],   // UNCHECKED Cocos (Keeling) Islands
  ['CD', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'CDF' }],   // UNCHECKED Congo (DRC)
  ['CF', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XAF' }],   // UNCHECKED Central African Republic
  ['CG', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XAF' }],   // UNCHECKED Congo
  ['CH', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'CHF' }],   // UNCHECKED Switzerland
  ['CI', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XOF' }],   // UNCHECKED Côte d’Ivoire
  ['CK', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'NZD' }],   // UNCHECKED Cook Islands
  ['CL', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'CLP' }],   // UNCHECKED Chile
  ['CM', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XAF' }],   // UNCHECKED Cameroon
  ['CN', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'CNY' }],   // UNCHECKED China
  ['CO', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'COP' }],   // UNCHECKED Colombia
  ['CR', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'CRC' }],   // UNCHECKED Costa Rica
  ['CU', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'CUP' }],   // UNCHECKED Cuba
  ['CV', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'CVE' }],   // UNCHECKED Cabo Verde
  ['CW', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'ANG' }],   // UNCHECKED Curaçao
  ['CX', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'AUD' }],   // UNCHECKED Christmas Island
  ['CY', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Cyprus
  ['CZ', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'CZK' }],   // UNCHECKED Czech Republic
  ['DE', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Germany
  ['DJ', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'DJF' }],   // UNCHECKED Djibouti
  ['DK', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'DKK' }],   // UNCHECKED Denmark
  ['DM', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XCD' }],   // UNCHECKED Dominica
  ['DO', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'DOP' }],   // UNCHECKED Dominican Republic
  ['DZ', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'DZD' }],   // UNCHECKED Algeria
  ['EC', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED Ecuador
  ['EE', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Estonia
  ['EG', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'EGP' }],   // UNCHECKED Egypt
  ['EH', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'MAD' }],   // UNCHECKED Western Sahara
  ['ER', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'ERN' }],   // UNCHECKED Eritrea
  ['ES', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Spain
  ['ET', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'ETB' }],   // UNCHECKED Ethiopia
  ['FI', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // Finland
  ['FJ', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'FJD' }],   // UNCHECKED Fiji
  ['FK', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'FKP' }],   // UNCHECKED Falkland Islands
  ['FM', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: true , currencyCode: 'USD' }],   // UNCHECKED Micronesia
  ['FO', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'DKK' }],   // UNCHECKED Faroe Islands
  ['FR', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // France
  ['GA', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XAF' }],   // UNCHECKED Gabon
  ['GB', { continent: Continent.Europe,       addressFormat: AddressFormat.British,       hasStates: false, currencyCode: 'GBP' }],   // United Kingdom
  ['GD', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XCD' }],   // UNCHECKED Grenada
  ['GE', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'GEL' }],   // UNCHECKED Georgia
  ['GF', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED French Guiana
  ['GG', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'GBP' }],   // UNCHECKED Guernsey
  ['GH', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'GHS' }],   // UNCHECKED Ghana
  ['GI', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'GIP' }],   // UNCHECKED Gibraltar
  ['GL', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'DKK' }],   // UNCHECKED Greenland
  ['GM', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'GMD' }],   // UNCHECKED Gambia
  ['GN', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'GNF' }],   // UNCHECKED Guinea
  ['GP', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Guadeloupe
  ['GQ', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XAF' }],   // UNCHECKED Equatorial Guinea
  ['GR', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Greece
  ['GT', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'GTQ' }],   // UNCHECKED Guatemala
  ['GU', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED Guam
  ['GW', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XOF' }],   // UNCHECKED Guinea-Bissau
  ['GY', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'GYD' }],   // UNCHECKED Guyana
  ['HK', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'HKD' }],   // UNCHECKED Hong Kong SAR
  ['HN', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'HNL' }],   // UNCHECKED Honduras
  ['HR', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'HRK' }],   // UNCHECKED Croatia
  ['HT', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'HTG' }],   // UNCHECKED Haiti
  ['HU', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'HUF' }],   // UNCHECKED Hungary
  ['ID', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'IDR' }],   // UNCHECKED Indonesia
  ['IE', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Ireland
  ['IL', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'ILS' }],   // UNCHECKED Israel
  ['IM', { continent: Continent.Europe,       addressFormat: AddressFormat.British,       hasStates: false, currencyCode: 'GBP' }],   // Isle of Man
  ['IN', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'INR' }],   // UNCHECKED India
  ['IO', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED British Indian Ocean Territory
  ['IQ', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'IQD' }],   // UNCHECKED Iraq
  ['IR', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'IRR' }],   // UNCHECKED Iran
  ['IS', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'ISK' }],   // UNCHECKED Iceland
  ['IT', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Italy
  ['JE', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'GBP' }],   // UNCHECKED Jersey
  ['JM', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'JMD' }],   // UNCHECKED Jamaica
  ['JO', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'JOD' }],   // UNCHECKED Jordan
  ['JP', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'JPY' }],   // UNCHECKED Japan
  ['KE', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'KES' }],   // UNCHECKED Kenya
  ['KG', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'KGS' }],   // UNCHECKED Kyrgyzstan
  ['KH', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'KHR' }],   // UNCHECKED Cambodia
  ['KI', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'AUD' }],   // UNCHECKED Kiribati
  ['KM', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: true , currencyCode: 'KMF' }],   // UNCHECKED Comoros
  ['KN', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: true , currencyCode: 'XCD' }],   // UNCHECKED Saint Kitts and Nevis
  ['KP', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'KPW' }],   // UNCHECKED North Korea
  ['KR', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'KRW' }],   // UNCHECKED Korea
  ['KW', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'KWD' }],   // UNCHECKED Kuwait
  ['KY', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'KYD' }],   // UNCHECKED Cayman Islands
  ['KZ', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'KZT' }],   // UNCHECKED Kazakhstan
  ['LA', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'LAK' }],   // UNCHECKED Laos
  ['LB', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'LBP' }],   // UNCHECKED Lebanon
  ['LC', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XCD' }],   // UNCHECKED Saint Lucia
  ['LI', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'CHF' }],   // UNCHECKED Liechtenstein
  ['LK', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'LKR' }],   // UNCHECKED Sri Lanka
  ['LR', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'LRD' }],   // UNCHECKED Liberia
  ['LS', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'LSL' }],   // UNCHECKED Lesotho
  ['LT', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Lithuania
  ['LU', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Luxembourg
  ['LV', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Latvia
  ['LY', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'LYD' }],   // UNCHECKED Libya
  ['MA', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'MAD' }],   // UNCHECKED Morocco
  ['MC', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Monaco
  ['MD', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'MDL' }],   // UNCHECKED Moldova
  ['ME', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Montenegro
  ['MF', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Saint Martin
  ['MG', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'MGA' }],   // UNCHECKED Madagascar
  ['MH', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED Marshall Islands
  ['MK', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'MKD' }],   // UNCHECKED North Macedonia
  ['ML', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XOF' }],   // UNCHECKED Mali
  ['MM', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'MMK' }],   // UNCHECKED Burma (Myanmar)
  ['MN', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'MNT' }],   // UNCHECKED Mongolia
  ['MO', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'MOP' }],   // UNCHECKED Macao SAR
  ['MP', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED Northern Mariana Islands
  ['MQ', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Martinique
  ['MR', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'MRU' }],   // UNCHECKED Mauritania
  ['MS', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XCD' }],   // UNCHECKED Montserrat
  ['MT', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Malta
  ['MU', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'MUR' }],   // UNCHECKED Mauritius
  ['MV', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'MVR' }],   // UNCHECKED Maldives
  ['MW', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'MWK' }],   // UNCHECKED Malawi
  ['MX', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: true , currencyCode: 'MXN' }],   // UNCHECKED Mexico
  ['MY', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: true , currencyCode: 'MYR' }],   // UNCHECKED Malaysia
  ['MZ', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'MZN' }],   // UNCHECKED Mozambique
  ['NA', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'NAD' }],   // UNCHECKED Namibia
  ['NC', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XPF' }],   // UNCHECKED New Caledonia
  ['NE', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XOF' }],   // UNCHECKED Niger
  ['NF', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'AUD' }],   // UNCHECKED Norfolk Island
  ['NG', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: true , currencyCode: 'NGN' }],   // UNCHECKED Nigeria
  ['NI', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'NIO' }],   // UNCHECKED Nicaragua
  ['NL', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // Netherlands
  ['NO', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'NOK' }],   // UNCHECKED Norway
  ['NP', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: true , currencyCode: 'NPR' }],   // UNCHECKED Nepal
  ['NR', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'AUD' }],   // UNCHECKED Nauru
  ['NU', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'NZD' }],   // UNCHECKED Niue
  ['NZ', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'NZD' }],   // UNCHECKED New Zealand
  ['OM', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'OMR' }],   // UNCHECKED Oman
  ['PA', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'PAB' }],   // UNCHECKED Panama
  ['PE', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'PEN' }],   // UNCHECKED Peru
  ['PF', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XPF' }],   // UNCHECKED French Polynesia
  ['PG', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'PGK' }],   // UNCHECKED Papua New Guinea
  ['PH', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'PHP' }],   // UNCHECKED Philippines
  ['PK', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'PKR' }],   // UNCHECKED Pakistan
  ['PL', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'PLN' }],   // UNCHECKED Poland
  ['PM', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Saint Pierre and Miquelon
  ['PN', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'NZD' }],   // UNCHECKED Pitcairn Islands
  ['PR', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED Puerto Rico
  ['PS', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'ILS' }],   // UNCHECKED Palestinian Authority
  ['PT', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Portugal
  ['PW', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED Palau
  ['PY', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'PYG' }],   // UNCHECKED Paraguay
  ['QA', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'QAR' }],   // UNCHECKED Qatar
  ['RE', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Réunion
  ['RO', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'RON' }],   // UNCHECKED Romania
  ['RS', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'RSD' }],   // UNCHECKED Serbia
  ['RU', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: true , currencyCode: 'RUB' }],   // UNCHECKED Russia
  ['RW', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'RWF' }],   // UNCHECKED Rwanda
  ['SA', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SAR' }],   // UNCHECKED Saudi Arabia
  ['SB', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SBD' }],   // UNCHECKED Solomon Islands
  ['SC', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SCR' }],   // UNCHECKED Seychelles
  ['SD', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SDG' }],   // UNCHECKED Sudan
  ['SE', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'SEK' }],   // UNCHECKED Sweden
  ['SG', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SGD' }],   // UNCHECKED Singapore
  ['SH', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SHP' }],   // UNCHECKED St Helena, Ascension and Tristan da Cunha
  ['SI', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Slovenia
  ['SJ', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'NOK' }],   // UNCHECKED Svalbard and Jan Mayen
  ['SK', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Slovakia
  ['SL', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SLL' }],   // UNCHECKED Sierra Leone
  ['SM', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED San Marino
  ['SN', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XOF' }],   // UNCHECKED Senegal
  ['SO', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SOS' }],   // UNCHECKED Somalia
  ['SR', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SRD' }],   // UNCHECKED Suriname
  ['SS', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SSP' }],   // UNCHECKED South Sudan
  ['ST', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'STN' }],   // UNCHECKED São Tomé and Príncipe
  ['SV', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED El Salvador
  ['SX', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'ANG' }],   // UNCHECKED Sint Maarten
  ['SY', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SYP' }],   // UNCHECKED Syria
  ['SZ', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'SZL' }],   // UNCHECKED Swaziland
  ['TC', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED Turks and Caicos Islands
  ['TD', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XAF' }],   // UNCHECKED Chad
  ['TF', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED French Southern Territories
  ['TG', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XOF' }],   // UNCHECKED Togo
  ['TH', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'THB' }],   // UNCHECKED Thailand
  ['TJ', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'TJS' }],   // UNCHECKED Tajikistan
  ['TK', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'NZD' }],   // UNCHECKED Tokelau
  ['TL', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED Timor-Leste
  ['TM', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'TMT' }],   // UNCHECKED Turkmenistan
  ['TN', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'TND' }],   // UNCHECKED Tunisia
  ['TO', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'TOP' }],   // UNCHECKED Tonga
  ['TR', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'TRY' }],   // UNCHECKED Turkey
  ['TT', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'TTD' }],   // UNCHECKED Trinidad and Tobago
  ['TV', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'AUD' }],   // UNCHECKED Tuvalu
  ['TW', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'TWD' }],   // UNCHECKED Taiwan
  ['TZ', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'TZS' }],   // UNCHECKED Tanzania
  ['UA', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'UAH' }],   // UNCHECKED Ukraine
  ['UG', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'UGX' }],   // UNCHECKED Uganda
  ['UM', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED U.S. Outlying Islands
  ['US', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.NorthAmerican, hasStates: false, currencyCode: 'USD' }],   // United States
  ['UY', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'UYU' }],   // UNCHECKED Uruguay
  ['UZ', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'UZS' }],   // UNCHECKED Uzbekistan
  ['VA', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Vatican City
  ['VC', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XCD' }],   // UNCHECKED Saint Vincent and the Grenadines
  ['VE', { continent: Continent.SouthAmerica, addressFormat: AddressFormat.Default,       hasStates: true , currencyCode: 'VES' }],   // UNCHECKED Venezuela
  ['VG', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED British Virgin Islands
  ['VI', { continent: Continent.NorthAmerica, addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }],   // UNCHECKED U.S. Virgin Islands
  ['VN', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'VND' }],   // UNCHECKED Vietnam
  ['VU', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'VUV' }],   // UNCHECKED Vanuatu
  ['WF', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'XPF' }],   // UNCHECKED Wallis and Futuna
  ['WS', { continent: Continent.Oceania,      addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'WST' }],   // UNCHECKED Samoa
  ['XK', { continent: Continent.Europe,       addressFormat: AddressFormat.European,      hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Kosovo
  ['YE', { continent: Continent.Asia,         addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'YER' }],   // UNCHECKED Yemen
  ['YT', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'EUR' }],   // UNCHECKED Mayotte
  ['ZA', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'ZAR' }],   // UNCHECKED South Africa
  ['ZM', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'ZMW' }],   // UNCHECKED Zambia
  ['ZW', { continent: Continent.Africa,       addressFormat: AddressFormat.Default,       hasStates: false, currencyCode: 'USD' }]    // UNCHECKED Zimbabwe
]);

export const COUNTRIES_BY_LANGUAGE: Map<string, string> = new Map([
  ['aa',  'ET'],    // Afar (Ethiopia)
  ['af',  'ZA'],    // Afrikaans (South Africa)
  ['agq', 'CM'],    // Aghem (Cameroon)
  ['ak',  'GH'],    // Akan (Ghana)
  ['am',  'ET'],    // Amharic (Ethiopia)
  ['ar',  'EG'],    // Arabic (Egypt)
  ['arn', 'CL'],    // Mapudungun (Chile)
  ['as',  'IN'],    // Assamese (India)
  ['asa', 'TZ'],    // Asu (Tanzania)
  ['ast', 'ES'],    // Asturian (Spain)
  ['az',  'AZ'],    // Azerbaijani (Latin, Azerbaijan)
  ['ba',  'RU'],    // Bashkir (Russia)
  ['bas', 'CM'],    // Basaa (Cameroon)
  ['be',  'BY'],    // Belarusian (Belarus)
  ['bem', 'ZM'],    // Bemba (Zambia)
  ['bez', 'TZ'],    // Bena (Tanzania)
  ['bg',  'BG'],    // Bulgarian (Bulgaria)
  ['bin', 'NG'],    // Edo (Nigeria)
  ['bm',  'ML'],    // Bambara (Latin, Mali)
  ['bn',  'BD'],    // Bangla (Bangladesh)
  ['bo',  'CN'],    // Tibetan (PRC)
  ['br',  'FR'],    // Breton (France)
  ['brx', 'IN'],    // Bodo (India)
  ['bs',  'BA'],    // Bosnian (Latin, Bosnia and Herzegovina)
  ['byn', 'ER'],    // Blin (Eritrea)
  ['ca',  'AD'],    // Catalan (Andorra)
  ['ce',  'RU'],    // Chechen (Russia)
  ['cgg', 'UG'],    // Chiga (Uganda)
  ['chr', 'US'],    // Cherokee (Cherokee)
  ['co',  'FR'],    // Corsican (France)
  ['cs',  'CZ'],    // Czech (Czech Republic)
  ['cu',  'RU'],    // Church Slavic (Russia)
  ['cy',  'GB'],    // Welsh (United Kingdom)
  ['da',  'DK'],    // Danish (Denmark)
  ['dav', 'KE'],    // Taita (Kenya)
  ['de',  'DE'],    // German
  ['dje', 'NE'],    // Zarma (Niger)
  ['dsb', 'DE'],    // Lower Sorbian (Germany)
  ['dua', 'CM'],    // Duala (Cameroon)
  ['dv',  'MV'],    // Divehi (Maldives)
  ['dyo', 'SN'],    // Jola-Fonyi (Senegal)
  ['dz',  'BT'],    // Dzongkha (Bhutan)
  ['ebu', 'KE'],    // Embu (Kenya)
  ['ee',  'TG'],    // Ewe (Togo)
  ['el',  'GR'],    // Greek (Greece)
  ['en',  'US'],    // English (United States)
  ['eo ', 'US'],    // Esperanto
  ['es',  'ES'],    // Spanish
  ['et',  'EE'],    // Estonian (Estonia)
  ['eu',  'ES'],    // Basque (Basque)
  ['ewo', 'CM'],    // Ewondo (Cameroon)
  ['fa',  'IR'],    // Persian (Iran)
  ['ff',  'NG'],    // Fulah (Nigeria)
  ['fi',  'FI'],    // Finnish (Finland)
  ['fil', 'PH'],    // Filipino (Philippines)
  ['fo',  'FO'],    // Faroese (Faroe Islands)
  ['fr',  'FR'],    // French
  ['fur', 'IT'],    // Friulian (Italy)
  ['fy',  'NL'],    // Frisian (Netherlands)
  ['ga',  'IE'],    // Irish (Ireland)
  ['gd',  'GB'],    // Scottish Gaelic (United Kingdom)
  ['gl',  'ES'],    // Galician (Galician)
  ['gn',  'PY'],    // Guarani (Paraguay)
  ['gsw', 'FR'],    // Alsatian (France)
  ['gu',  'IN'],    // Gujarati (India)
  ['guz', 'KE'],    // Gusii (Kenya)
  ['gv',  'IM'],    // Manx (Isle of Man)
  ['ha',  'NE'],    // Hausa (Latin, Niger)
  ['haw', 'US'],    // Hawaiian (United States)
  ['he',  'IL'],    // Hebrew (Israel)
  ['hi',  'IN'],    // Hindi (India)
  ['hr',  'HR'],    // Croatian (Croatia)
  ['hsb', 'DE'],    // Upper Sorbian (Germany)
  ['hu',  'HU'],    // Hungarian (Hungary)
  ['hy',  'AM'],    // Armenian (Armenia)
  ['ia ', 'US'],    // Interlingua
  ['ibb', 'NG'],    // Ibibio (Nigeria)
  ['id',  'ID'],    // Indonesian (Indonesia)
  ['ig',  'NG'],    // Igbo (Nigeria)
  ['ii',  'CN'],    // Yi (PRC)
  ['is',  'IS'],    // Icelandic (Iceland)
  ['it',  'IT'],    // Italian (Italy)
  ['iu',  'CA'],    // Inuktitut (Latin, Canada)
  ['ja',  'JP'],    // Japanese (Japan)
  ['jgo', 'CM'],    // Ngomba (Cameroon)
  ['jmc', 'TZ'],    // Machame (Tanzania)
  ['jv',  'ID'],    // Javanese (Indonesia)
  ['ka',  'GE'],    // Georgian (Georgia)
  ['kab', 'DZ'],    // Kabyle (Algeria)
  ['kam', 'KE'],    // Kamba (Kenya)
  ['kde', 'TZ'],    // Makonde (Tanzania)
  ['kea', 'CV'],    // Kabuverdianu (Cabo Verde)
  ['khq', 'ML'],    // Koyra Chiini (Mali)
  ['ki',  'KE'],    // Kikuyu (Kenya)
  ['kk',  'KZ'],    // Kazakh (Kazakhstan)
  ['kkj', 'CM'],    // Kako (Cameroon)
  ['kl',  'GL'],    // Greenlandic (Greenland)
  ['kln', 'KE'],    // Kalenjin (Kenya)
  ['km',  'KH'],    // Khmer (Cambodia)
  ['kn',  'IN'],    // Kannada (India)
  ['ko',  'KR'],    // Korean (Korea)
  ['kok', 'IN'],    // Konkani (India)
  ['kr',  'NG'],    // Kanuri (Nigeria)
  ['ks',  'IN'],    // Kashmiri (Devanagari, India)
  ['ksb', 'TZ'],    // Shambala (Tanzania)
  ['ksf', 'CM'],    // Bafia (Cameroon)
  ['ksh', 'DE'],    // Ripuarian (Germany)
  ['ku',  'IQ'],    // Central Kurdish (Iraq)
  ['kw',  'GB'],    // Cornish (United Kingdom)
  ['ky',  'KG'],    // Kyrgyz (Kyrgyzstan)
  ['la',  'US'],    // Latin
  ['lag', 'TZ'],    // Langi (Tanzania)
  ['lb',  'LU'],    // Luxembourgish (Luxembourg)
  ['lg',  'UG'],    // Ganda (Uganda)
  ['lkt', 'US'],    // Lakota (United States)
  ['ln',  'CD'],    // Lingala (Congo DRC)
  ['lo',  'LA'],    // Lao (Lao P.D.R.)
  ['lrc', 'IR'],    // Northern Luri (Iran)
  ['lt',  'LT'],    // Lithuanian (Lithuania)
  ['lu',  'CD'],    // Luba-Katanga (Congo DRC)
  ['luo', 'KE'],    // Luo (Kenya)
  ['luy', 'KE'],    // Luyia (Kenya)
  ['lv',  'LV'],    // Latvian (Latvia)
  ['mas', 'KE'],    // Masai (Kenya)
  ['mer', 'KE'],    // Meru (Kenya)
  ['mfe', 'MU'],    // Morisyen (Mauritius)
  ['mg',  'MG'],    // Malagasy (Madagascar)
  ['mgh', 'MZ'],    // Makhuwa-Meetto (Mozambique)
  ['mgo', 'CM'],    // Meta' (Cameroon)
  ['mi',  'NZ'],    // Maori (New Zealand)
  ['mk',  'MK'],    // Macedonian (Former Yugoslav Republic of Macedonia)
  ['ml',  'IN'],    // Malayalam (India)
  ['mn',  'MN'],    // Mongolian (Cyrillic, Mongolia)
  ['mni', 'IN'],    // Manipuri (India)
  ['moh', 'CA'],    // Mohawk (Mohawk)
  ['mr',  'IN'],    // Marathi (India)
  ['ms',  'ID'],    // Malay
  ['mt',  'MT'],    // Maltese (Malta)
  ['mua', 'CM'],    // Mundang (Cameroon)
  ['my',  'MM'],    // Burmese (Myanmar)
  ['mzn', 'IR'],    // Mazanderani (Iran)
  ['naq', 'NA'],    // Nama (Namibia)
  ['nb',  'NO'],    // Norwegian, Bokmål (Norway)
  ['nds', 'DE'],    // Low German (Germany)
  ['nd',  'ZW'],    // North Ndebele (Zimbabwe)
  ['ne',  'NP'],    // Nepali (Nepal)
  ['nl',  'NL'],    // Dutch (Netherlands)
  ['nmg', 'CM'],    // Kwasio (Cameroon)
  ['nn',  'NO'],    // Norwegian, Nynorsk (Norway)
  ['nnh', 'CM'],    // Ngiemboon (Cameroon)
  ['no',  'NO'],    // Norwegian
  ['nqo', 'GN'],    // N'ko (Guinea)
  ['nr',  'ZA'],    // South Ndebele (South Africa)
  ['nso', 'ZA'],    // Sesotho sa Leboa (South Africa)
  ['nus', 'SS'],    // Nuer (South Sudan)
  ['nyn', 'UG'],    // Nyankole (Uganda)
  ['oc',  'FR'],    // Occitan (France)
  ['om',  'ET'],    // Oromo (Ethiopia)
  ['or',  'IN'],    // Odia (India)
  ['os',  'RU'],    // Ossetian (Cyrillic, Russia)
  ['pa',  'PK'],    // Punjabi (Islamic Republic of Pakistan)
  ['pap', 'CW'],    // Papiamento (Curaçao)
  ['pl',  'PL'],    // Polish (Poland)
  ['prs', 'AF'],    // Dari (Afghanistan)
  ['ps',  'AF'],    // Pashto (Afghanistan)
  ['pt',  'PT'],    // Portuguese (Portugal)
  ['quc', 'GT'],    // K'iche' (Guatemala)
  ['quz', 'PE'],    // Quechua (Peru)
  ['rm',  'CH'],    // Romansh (Switzerland)
  ['rn',  'BI'],    // Rundi (Burundi)
  ['ro',  'RO'],    // Romanian (Romania)
  ['rof', 'TZ'],    // Rombo (Tanzania)
  ['ru',  'RU'],    // Russian (Russia)
  ['rw',  'RW'],    // Kinyarwanda (Rwanda)
  ['rwk', 'TZ'],    // Rwa (Tanzania)
  ['sa',  'IN'],    // Sanskrit (India)
  ['sah', 'RU'],    // Sakha (Russia)
  ['saq', 'KE'],    // Samburu (Kenya)
  ['sbp', 'TZ'],    // Sangu (Tanzania)
  ['sd',  'PK'],    // Sindhi (Islamic Republic of Pakistan)
  ['se',  'NO'],    // Sami, Northern (Norway)
  ['seh', 'MZ'],    // Sena (Mozambique)
  ['ses', 'ML'],    // Koyraboro Senni (Mali)
  ['sg',  'CF'],    // Sango (Central African Republic)
  ['shi', 'MA'],    // Tachelhit (Latin, Morocco)
  ['si',  'LK'],    // Sinhala (Sri Lanka)
  ['sk',  'SK'],    // Slovak (Slovakia)
  ['sl',  'SI'],    // Slovenian (Slovenia)
  ['sma', 'NO'],    // Sami, Southern (Norway)
  ['smj', 'NO'],    // Sami, Lule (Norway)
  ['smn', 'FI'],    // Sami, Inari (Finland)
  ['sms', 'FI'],    // Sami, Skolt (Finland)
  ['sn',  'ZW'],    // Shona (Latin, Zimbabwe)
  ['so',  'SO'],    // Somali (Somalia)
  ['sq',  'AL'],    // Albanian (Albania)
  ['sr',  'RS'],    // Serbian (Latin, Serbia)
  ['ss',  'SZ'],    // Swati (Eswatini former Swaziland)
  ['ssy', 'ER'],    // Saho (Eritrea)
  ['st',  'LS'],    // Sesotho (Lesotho)
  ['sv',  'SE'],    // Swedish (Sweden)
  ['sw',  'TZ'],    // Kiswahili (Tanzania)
  ['syr', 'SY'],    // Syriac (Syria)
  ['ta',  'IN'],    // Tamil (India)
  ['te',  'IN'],    // Telugu (India)
  ['teo', 'UG'],    // Teso (Uganda)
  ['tg',  'TJ'],    // Tajik (Cyrillic, Tajikistan)
  ['th',  'TH'],    // Thai (Thailand)
  ['ti',  'ER'],    // Tigrinya (Eritrea)
  ['tig', 'ER'],    // Tigre (Eritrea)
  ['tk',  'TM'],    // Turkmen (Turkmenistan)
  ['tn',  'ZA'],    // Setswana (South Africa)
  ['to',  'TO'],    // Tongan (Tonga)
  ['tr',  'TR'],    // Turkish (Turkey)
  ['ts',  'ZA'],    // Tsonga (South Africa)
  ['tt',  'RU'],    // Tatar (Russia)
  ['twq', 'NE'],    // Tasawaq (Niger)
  ['tzm', 'MA'],    // Central Atlas Tamazight (Latin, Morocco)
  ['ug',  'CN'],    // Uyghur (PRC)
  ['uk',  'UA'],    // Ukrainian (Ukraine)
  ['ur',  'PK'],    // Urdu (Islamic Republic of Pakistan)
  ['uz',  'UZ'],    // Uzbek (Latin, Uzbekistan)
  ['vai', 'LR'],    // Vai (Latin, Liberia)
  ['ve',  'ZA'],    // Venda (South Africa)
  ['vi',  'VN'],    // Vietnamese (Vietnam)
  ['vun', 'TZ'],    // Vunjo (Tanzania)
  ['wae', 'CH'],    // Walser (Switzerland)
  ['wal', 'ET'],    // Wolaytta (Ethiopia)
  ['wo',  'SN'],    // Wolof (Senegal)
  ['xh',  'ZA'],    // isiXhosa (South Africa)
  ['xog', 'UG'],    // Soga (Uganda)
  ['yav', 'CM'],    // Yangben (Cameroon)
  ['yi',  'IL'],    // Yiddish
  ['yo',  'NG'],    // Yoruba (Nigeria)
  ['zgh', 'MA'],    // Standard Moroccan Tamazight (Tifinagh, Morocco)
  ['zh',  'CN'],    // Chinese (Simplified, PRC)
  ['zu',  'ZA']     // isiZulu (South Africa)
]);

/* eslint-disable @typescript-eslint/no-magic-numbers */
export const TAX_RATES_BY_COUNTRY_STATE: Map<string, number> = new Map([
  /* See https://en.wikipedia.org/wiki/List_of_countries_by_tax_rates
    and https://en.wikipedia.org/wiki/Value-added_tax#Tax_rates
  */
  ['AD',    0.045  ],   // Andorra
  ['AE',    0.05   ],   // United Arab Emirates
  ['AF',    0      ],   // Afghanistan
  ['AG',    0.15   ],   // Antigua and Barbuda
  ['AI',    0      ],   // Anguilla
  ['AL',    0.2    ],   // Albania
  ['AM',    0.2    ],   // Armenia
  ['AO',    0.1    ],   // Angola
  ['AR',    0.21   ],   // Argentina
  ['AS',    0      ],   // American Samoa
  ['AT',    0.2    ],   // Austria
  ['AU',    0.1    ],   // Australia
  ['AW',    0.015  ],   // Aruba
  ['AX',    0      ],   // Åland Islands (TBC)
  ['AZ',    0.18   ],   // Azerbaijan
  ['BA',    0.17   ],   // Bosnia and Herzegovina
  ['BB',    0.175  ],   // Barbados
  ['BD',    0.15   ],   // Bangladesh
  ['BE',    0.21   ],   // Belgium
  ['BF',    0.18   ],   // Burkina Faso
  ['BG',    0.2    ],   // Bulgaria
  ['BH',    0.05   ],   // Bahrain
  ['BI',    0.18   ],   // Burundi
  ['BJ',    0.18   ],   // Benin
  ['BL',    0      ],   // Saint Barthélemy (TBC)
  ['BM',    0      ],   // Bermuda
  ['BN',    0      ],   // Brunei
  ['BO',    0.13   ],   // Bolivia
  ['BQ',    0      ],   // Bonaire, Sint Eustatius and Saba (TBC)
  ['BR',    0.39   ],   // Brazil
  ['BS',    0.12   ],   // Bahamas
  ['BT',    0      ],   // Bhutan
  ['BV',    0      ],   // Bouvet Island (TBC)
  ['BW',    0.12   ],   // Botswana
  ['BY',    0.2    ],   // Belarus
  ['BZ',    0.125  ],   // Belize
  ['CA-AB', 0.05   ],   // Canada - Alberta
  ['CA-BC', 0.12   ],   // Canada - British Columbia
  ['CA-MB', 0.12   ],   // Canada - Manitoba
  ['CA-NB', 0.15   ],   // Canada - New Brunswick
  ['CA-NL', 0.15   ],   // Canada - Newfoundland & Labrador
  ['CA-NS', 0.15   ],   // Canada - Nova Scotia
  ['CA-ON', 0.13   ],   // Canada - Ontario
  ['CA-PE', 0.15   ],   // Canada - Prince Edward Island
  ['CA-QC', 0.14975],   // Canada - Quebec
  ['CA-SK', 0.11   ],   // Canada - Saskatchewan
  ['CC',    0      ],   // Cocos (Keeling) Islands (TBC)
  ['CD',    0.16   ],   // Congo (DRC)
  ['CF',    0.19   ],   // Central African Republic
  ['CG',    0.16   ],   // Congo
  ['CH',    0.077  ],   // Switzerland
  ['CI',    0.18   ],   // Côte d’Ivoire
  ['CK',    0.15   ],   // Cook Islands
  ['CL',    0.19   ],   // Chile
  ['CM',    0.1925 ],   // Cameroon
  ['CN',    0.13   ],   // China
  ['CO',    0.19   ],   // Colombia
  ['CR',    0.13   ],   // Costa Rica
  ['CU',    0.2    ],   // Cuba
  ['CV',    0.15   ],   // Cabo Verde
  ['CW',    0.09   ],   // Curaçao
  ['CX',    0      ],   // Christmas Island (TBC)
  ['CY',    0.19   ],   // Cyprus
  ['CZ',    0.21   ],   // Czech Republic
  ['DE',    0.19   ],   // Germany
  ['DJ',    0.1    ],   // Djibouti
  ['DK',    0.25   ],   // Denmark
  ['DM',    0.15   ],   // Dominica
  ['DO',    0.18   ],   // Dominican Republic
  ['DZ',    0.19   ],   // Algeria
  ['EC',    0.12   ],   // Ecuador
  ['EE',    0.2    ],   // Estonia
  ['EG',    0.14   ],   // Egypt
  ['EH',    0      ],   // Western Sahara (TBC)
  ['ER',    0.05   ],   // Eritrea
  ['ES',    0.21   ],   // Spain
  ['ET',    0.15   ],   // Ethiopia
  ['FI',    0.24   ],   // Finland
  ['FJ',    0.15   ],   // Fiji
  ['FK',    0      ],   // Falkland Islands
  ['FM',    0      ],   // Micronesia
  ['FO',    0.25   ],   // Faroe Islands
  ['FR',    0.2    ],   // France
  ['GA',    0.18   ],   // Gabon
  ['GB',    0.2    ],   // United Kingdom
  ['GD',    0.15   ],   // Grenada
  ['GE',    0.18   ],   // Georgia
  ['GF',    0      ],   // French Guiana (TBC)
  ['GG',    0      ],   // Guernsey
  ['GH',    0.15   ],   // Ghana
  ['GI',    0      ],   // Gibraltar
  ['GL',    0      ],   // Greenland (TBC)
  ['GM',    0.15   ],   // Gambia
  ['GN',    0.18   ],   // Guinea
  ['GP',    0      ],   // Guadeloupe (TBC)
  ['GQ',    0.15   ],   // Equatorial Guinea
  ['GR',    0.24   ],   // Greece
  ['GT',    0.12   ],   // Guatemala
  ['GU',    0.04   ],   // Guam
  ['GW',    0.15   ],   // Guinea-Bissau
  ['GY',    0.16   ],   // Guyana
  ['HK',    0      ],   // Hong Kong SAR
  ['HN',    0.15   ],   // Honduras
  ['HR',    0.25   ],   // Croatia
  ['HT',    0.1    ],   // Haiti
  ['HU',    0.27   ],   // Hungary
  ['ID',    0.1    ],   // Indonesia
  ['IE',    0.23   ],   // Ireland
  ['IL',    0.17   ],   // Israel
  ['IM',    0.2    ],   // Isle of Man
  ['IN',    0.055  ],   // India
  ['IO',    0      ],   // British Indian Ocean Territory
  ['IQ',    0      ],   // Iraq
  ['IR',    0.09   ],   // Iran
  ['IS',    0.24   ],   // Iceland
  ['IT',    0.22   ],   // Italy
  ['JE',    0.05   ],   // Jersey
  ['JM',    0.125  ],   // Jamaica
  ['JO',    0.16   ],   // Jordan
  ['JP',    0.1    ],   // Japan
  ['KE',    0.16   ],   // Kenya
  ['KG',    0.2    ],   // Kyrgyzstan
  ['KH',    0.1    ],   // Cambodia
  ['KI',    0      ],   // Kiribati
  ['KM',    0.1    ],   // Comoros
  ['KN',    0.17   ],   // Saint Kitts and Nevis
  ['KP',    0.04   ],   // North Korea
  ['KR',    0.1    ],   // Korea
  ['KW',    0      ],   // Kuwait
  ['KY',    0      ],   // Cayman Islands
  ['KZ',    0.12   ],   // Kazakhstan
  ['LA',    0.1    ],   // Laos
  ['LB',    0.11   ],   // Lebanon
  ['LC',    0      ],   // Saint Lucia (TBC)
  ['LI',    0.077  ],   // Liechtenstein
  ['LK',    0.12   ],   // Sri Lanka
  ['LR',    0      ],   // Liberia
  ['LS',    0.14   ],   // Lesotho
  ['LT',    0.21   ],   // Lithuania
  ['LU',    0.17   ],   // Luxembourg
  ['LV',    0.21   ],   // Latvia
  ['LY',    0      ],   // Libya
  ['MA',    0.2    ],   // Morocco
  ['MC',    0.196  ],   // Monaco
  ['MD',    0.2    ],   // Moldova
  ['ME',    0.21   ],   // Montenegro
  ['MF',    0      ],   // Saint Martin (TBC)
  ['MG',    0.2    ],   // Madagascar
  ['MH',    0.04   ],   // Marshall Islands
  ['MK',    0.18   ],   // North Macedonia
  ['ML',    0.18   ],   // Mali
  ['MM',    0      ],   // Burma (Myanmar)
  ['MN',    0.1    ],   // Mongolia
  ['MO',    0      ],   // Macao SAR
  ['MP',    0      ],   // Northern Mariana Islands (TBC)
  ['MQ',    0      ],   // Martinique (TBC)
  ['MR',    0.14   ],   // Mauritania
  ['MS',    0      ],   // Montserrat
  ['MT',    0.18   ],   // Malta
  ['MU',    0.15   ],   // Mauritius
  ['MV',    0.06   ],   // Maldives
  ['MW',    0.165  ],   // Malawi
  ['MX',    0.16   ],   // Mexico
  ['MY',    0.06   ],   // Malaysia
  ['MZ',    0.17   ],   // Mozambique
  ['NA',    0.15   ],   // Namibia
  ['NC',    0      ],   // New Caledonia (TBC)
  ['NE',    0.19   ],   // Niger
  ['NF',    0      ],   // Norfolk Island (TBC)
  ['NG',    0.075  ],   // Nigeria
  ['NI',    0.15   ],   // Nicaragua
  ['NL',    0.21   ],   // Netherlands
  ['NO',    0.25   ],   // Norway
  ['NP',    0.13   ],   // Nepal
  ['NR',    0      ],   // Nauru
  ['NU',    0.05   ],   // Niue
  ['NZ',    0.15   ],   // New Zealand
  ['OM',    0.05   ],   // Oman
  ['PA',    0.07   ],   // Panama
  ['PE',    0.18   ],   // Peru
  ['PF',    0      ],   // French Polynesia (TBC)
  ['PG',    0.1    ],   // Papua New Guinea
  ['PH',    0.12   ],   // Philippines
  ['PK',    0.17   ],   // Pakistan
  ['PL',    0.23   ],   // Poland
  ['PM',    0      ],   // Saint Pierre and Miquelon (TBC)
  ['PN',    0      ],   // Pitcairn Islands
  ['PR',    0.115  ],   // Puerto Rico
  ['PS',    0.16   ],   // Palestinian Authority
  ['PT',    0.23   ],   // Portugal
  ['PT-AZ', 0.18   ],   // Portugal - Azores
  ['PT-MD', 0.22   ],   // Portugal - Madeira
  ['PW',    0      ],   // Palau
  ['PY',    0.1    ],   // Paraguay
  ['QA',    0      ],   // Qatar
  ['RE',    0      ],   // Réunion (TBC)
  ['RO',    0.19   ],   // Romania
  ['RS',    0.2    ],   // Serbia
  ['RU',    0.2    ],   // Russia
  ['RW',    0.18   ],   // Rwanda
  ['SA',    0.15   ],   // Saudi Arabia
  ['SB',    0      ],   // Solomon Islands
  ['SC',    0.15   ],   // Seychelles
  ['SD',    0.17   ],   // Sudan
  ['SE',    0.25   ],   // Sweden
  ['SG',    0.07   ],   // Singapore
  ['SH',    0      ],   // St Helena, Ascension and Tristan da Cunha
  ['SI',    0.22   ],   // Slovenia
  ['SJ',    0      ],   // Svalbard and Jan Mayen
  ['SK',    0.2    ],   // Slovakia
  ['SL',    0.15   ],   // Sierra Leone
  ['SM',    0      ],   // San Marino
  ['SN',    0.18   ],   // Senegal
  ['SO',    0      ],   // Somalia
  ['SR',    0      ],   // Suriname
  ['SS',    0.18   ],   // South Sudan
  ['ST',    0      ],   // São Tomé and Príncipe
  ['SV',    0.13   ],   // El Salvador
  ['SX',    0      ],   // Sint Maarten (TBC)
  ['SY',    0      ],   // Syria
  ['SZ',    0.15   ],   // Swaziland
  ['TC',    0      ],   // Turks and Caicos Islands
  ['TD',    0.18   ],   // Chad
  ['TF',    0      ],   // French Southern Territories (TBC)
  ['TG',    0.18   ],   // Togo
  ['TH',    0.1    ],   // Thailand
  ['TJ',    0.2    ],   // Tajikistan
  ['TK',    0      ],   // Tokelau (TBC)
  ['TL',    0      ],   // Timor-Leste
  ['TM',    0.15   ],   // Turkmenistan
  ['TN',    0.18   ],   // Tunisia
  ['TO',    0.15   ],   // Tonga
  ['TR',    0.18   ],   // Turkey
  ['TT',    0.125  ],   // Trinidad and Tobago
  ['TV',    0      ],   // Tuvalu
  ['TW',    0.05   ],   // Taiwan
  ['TZ',    0.18   ],   // Tanzania
  ['UA',    0.2    ],   // Ukraine
  ['UG',    0.18   ],   // Uganda
  ['UM',    0      ],   // U.S. Outlying Islands (TBC)
  ['US-AL', 0.04   ],   // United States - Alabama
  ['US-AK', 0      ],   // United States - Alaska
  ['US-AZ', 0.056  ],   // United States - Arizona
  ['US-AK', 0.065  ],   // United States - Arkansas
  ['US-CA', 0.0725 ],   // United States - California
  ['US-CO', 0.029  ],   // United States - Colorado
  ['US-CT', 0.0635 ],   // United States - Connecticut
  ['US-DE', 0      ],   // United States - Delaware
  ['US-DC', 0.06   ],   // United States - District of Columbia
  ['US-FL', 0.06   ],   // United States - Florida
  ['US-GA', 0.04   ],   // United States - Georgia
  ['US-HI', 0.04166],   // United States - Hawaii
  ['US-ID', 0.06   ],   // United States - Idaho
  ['US-IL', 0.0625 ],   // United States - Illinois
  ['US-IN', 0.07   ],   // United States - Indiana
  ['US-IA', 0.06   ],   // United States - Iowa
  ['US-KS', 0.065  ],   // United States - Kansas
  ['US-KY', 0.06   ],   // United States - Kentucky
  ['US-LA', 0.0445 ],   // United States - Louisiana
  ['US-ME', 0.055  ],   // United States - Maine
  ['US-MD', 0.06   ],   // United States - Maryland
  ['US-MA', 0.0625 ],   // United States - Massachusetts
  ['US-MI', 0.06   ],   // United States - Michigan
  ['US-MN', 0.06875],   // United States - Minnesota
  ['US-MS', 0.07   ],   // United States - Mississippi
  ['US-MO', 0.04225],   // United States - Missouri
  ['US-MT', 0      ],   // United States - Montana
  ['US-NE', 0.055  ],   // United States - Nebraska
  ['US-NV', 0.0685 ],   // United States - Nevada
  ['US-NH', 0      ],   // United States - New Hampshire
  ['US-NJ', 0.06625],   // United States - New Jersey
  ['US-NM', 0.05125],   // United States - New Mexico
  ['US-NY', 0.04   ],   // United States - New York
  ['US-NC', 0.0475 ],   // United States - North Carolina
  ['US-ND', 0.05   ],   // United States - North Dakota
  ['US-OH', 0.0575 ],   // United States - Ohio
  ['US-OK', 0.045  ],   // United States - Oklahoma
  ['US-OR', 0      ],   // United States - Oregon
  ['US-PA', 0.06   ],   // United States - Pennsylvania
  ['US-RI', 0.07   ],   // United States - Rhode Island
  ['US-SC', 0.06   ],   // United States - South Carolina
  ['US-SD', 0.04   ],   // United States - South Dakota
  ['US-TN', 0.07   ],   // United States - Tennessee
  ['US-TX', 0.0625 ],   // United States - Texas
  ['US-UT', 0.0595 ],   // United States - Utah
  ['US-VT', 0.06   ],   // United States - Vermont
  ['US-VA', 0.053  ],   // United States - Virginia
  ['US-WA', 0.065  ],   // United States - Washington
  ['US-WV', 0.06   ],   // United States - West Virginia
  ['US-WI', 0.05   ],   // United States - Wisconsin
  ['US-WY', 0.04   ],   // United States - Wyoming
  ['UY',    0.22   ],   // Uruguay
  ['UZ',    0.2    ],   // Uzbekistan
  ['VA',    0      ],   // Vatican City
  ['VC',    0.15   ],   // Saint Vincent and the Grenadines
  ['VE',    0.12   ],   // Venezuela
  ['VG',    0      ],   // British Virgin Islands
  ['VI',    0      ],   // U.S. Virgin Islands (TBC)
  ['VN',    0.1    ],   // Vietnam
  ['VU',    0.13   ],   // Vanuatu
  ['WF',    0      ],   // Wallis and Futuna (TBC)
  ['WS',    0.15   ],   // Samoa
  ['XK',    0.18   ],   // Kosovo
  ['YE',    0.02   ],   // Yemen
  ['YT',    0      ],   // Mayotte (TBC)
  ['ZA',    0.15   ],   // South Africa
  ['ZM',    0.16   ],   // Zambia
  ['ZW',    0.15   ]    // Zimbabwe
]);
/* eslint-enable @typescript-eslint/no-magic-numbers */
