import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { first, ReplaySubject } from 'rxjs';

import { Logger, LogService } from '../core/logging';
import { Account, base32guid, Editions, EMPTY_SETTINGS, OrderTheme, Settings } from '../core/model';
import { AccountService } from './account.service';
import { LocaleService } from './locale.service';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const MILLISECS_TO_DAYS: number = 1000 * 60 * 60 * 24;

@Injectable({
  providedIn: 'root'
})
export class SettingsService implements OnDestroy {
  public readonly currentSettings$: ReplaySubject<Settings> = new ReplaySubject<Settings>(1);
  public currentSettings: Settings = EMPTY_SETTINGS;
  public settingsInitialized: boolean = false;
  private readonly _log: Logger;

  constructor(private _localeService: LocaleService,
              private _accountService: AccountService,
              logService: LogService) {
    this._log = logService.getLogger('SettingsService');

    this.assignToCurrentSettings();
  }

  public ngOnDestroy(): void {
    this.currentSettings$.complete();
  }

  public initialize(accountId: string, userIdToken: base32guid, edition: Editions, expiryDate: Date): void {
    if (   accountId !== this.currentSettings.accountId
        || userIdToken !== this.currentSettings.userIdToken) {
      if (accountId.length > 0) {
        this._accountService.getById(accountId)
                            .pipe(first())
                            .subscribe({
                              next: (account: Account) => {
                                this.assignToCurrentSettings(account,
                                                             { ...EMPTY_SETTINGS,
                                                               userIdToken,
                                                               edition,
                                                               expiryDate
                                                             });
                              },
                              error: (error) => {
                                this._log.error('Error obtaining account info', error);
                                this.shutdown(true);
                              }});
      } else {
        this.assignToCurrentSettings();
      }
    }
  }

  public shutdown(onError: boolean = false): void {
    this.assignToCurrentSettings();
    if (onError) {
      this.currentSettings$.error('Shutdown initiated by error condition');
    }
  }

  /* This must be defined as an arrow function */
  // eslint-disable-next-line @typescript-eslint/ban-types -- required by PouchDB API
  public onAccountChanges = (change: PouchDB.Core.ChangesResponseChange<{}>): void => {
    if (!change.deleted) {
      const account: Account = new Account(change.doc as any);
      this.assignToCurrentSettings(account);
    } else {
      this.assignToCurrentSettings();
    }
  };

  public formatCurrency(value: number, roundToInt: boolean = false): string {
    const currencyCode: string = getCurrencySymbol(this.currentSettings.currencyCode,
                                                   'narrow',
                                                   this.currentSettings.localeId);
    const digitsInfo: string | undefined = roundToInt ? '1.0-0' : undefined;
    return formatCurrency(value, this.currentSettings.localeId, currencyCode, this.currentSettings.currencyCode, digitsInfo);
  }

  public hasAccountExpired(): boolean {
    return this.currentSettings.expiryDate.valueOf() < Date.now();
  }

  public daysToAccountExpiry(): number {
    return (this.currentSettings.expiryDate.valueOf() - Date.now()) / MILLISECS_TO_DAYS;
  }

  private assignToCurrentSettings(account?: Account, settings: Settings = EMPTY_SETTINGS): void {
    /* The current locale info will set the countrycode/currencycode to the default,
      but will be overridden by the values on the account, if that's passed.
    */
    settings = { ...settings,
                 ...this._localeService.getCurrentLocaleInfo()
               };

    /* Note that some settings are not set here as they're not held in the account
      document, so won't change when the account is updated:
        * userIdToken
        * edition
        * expiryDate
      They may be set earlier and passed in via the `settings` param however.
    */
    if (account) {
      settings = { ...settings,
                   accountId: account._id,
                   accountIdToken: account.idToken,
                   accountStatus: account.accountStatus,
                   countryCode: account.countryCode,
                   showCountry: account.showCountry,
                   financialYearStart: account.financialYearStart,
                   currencyCode: account.currencyCode,
                   taxRate: account.taxRate,
                   includeTax: account.includeTax,
                   orderTheme: OrderTheme.fromString(account.orderTheme),
                   schemaVersion: account.schemaVersion
                 };
    }

    this.currentSettings = settings;
    this.settingsInitialized = this.currentSettings.accountIdToken.length > 0;
    this._log.debug(this.settingsInitialized ? `Settings configured for account ${this.currentSettings.accountId}`
                                             : 'Settings cleared');

    this.currentSettings$.next(this.currentSettings);
  }
}
