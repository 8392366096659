import { Injectable } from '@angular/core';
import { LogService } from '../core/logging';

import { Address, AddressFormat, CountryInfo, IHasAddress } from '../core/model';
import { BaseService } from './base.service';
import { SUPPORTED_COUNTRIES } from './locale-data';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService extends BaseService {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(settingsService: SettingsService,
              logService: LogService) {
    super(settingsService, logService);
  }

// https://www.informatica.com/products/data-quality/data-as-a-service/address-verification/address-formats.html?code=USA

  public getAddressLines(address: IHasAddress): string[] {
    let addressLines: string[];
    const countryInfo: [string, boolean] = this.shouldIncludeCountry(address);
    const countryCode: string = countryInfo[0];
    const includeCountry: boolean = countryInfo[1];
    const addressFormat: AddressFormat = this.getAddressFormat(countryCode);

    switch (addressFormat) {
      case AddressFormat.British:
        addressLines = this.getGbAddressLines(address, includeCountry);
        break;

      case AddressFormat.NorthAmerican:
        addressLines = this.getNorthAmericanAddressLines(address, includeCountry);
        break;

      case AddressFormat.European:
        addressLines = this.getEuropeanAddressLines(address, includeCountry);
        break;

      default:
        if (addressFormat !== AddressFormat.Default) {
          this.log.warn(`Unrecognized AddressFormat enum value - '${addressFormat}'`);
        }
        addressLines = this.getDefaultAddressLines(address, includeCountry);
        break;
    }

    return addressLines;
  }

  public getSingleLineAddress(address: IHasAddress): string {
    let addressLine: string;
    const countryInfo: [string, boolean] = this.shouldIncludeCountry(address);
    const countryCode: string = countryInfo[0];
    const includeCountry: boolean = countryInfo[1];
    const addressFormat: AddressFormat = this.getAddressFormat(countryCode);

    switch (addressFormat) {
      case AddressFormat.British:
        addressLine = this.getGbSingleLineAddress(address, includeCountry);
        break;

      case AddressFormat.NorthAmerican:
        addressLine = this.getNorthAmericanSingleLineAddress(address, includeCountry);
        break;

      case AddressFormat.European:
        addressLine = this.getEuropeanSingleLineAddress(address, includeCountry);
        break;

      default:
        if (addressFormat !== AddressFormat.Default) {
          this.log.warn(`Unrecognized AddressFormat enum value - '${addressFormat}'`);
        }
        addressLine = this.getDefaultSingleLineAddress(address, includeCountry);
        break;
    }

    return addressLine;
  }

  private getGbAddressLines(address: IHasAddress, includeCountry: boolean): string[] {
    const addressLines: string[] = this.splitStreetAddress(address.streetAddress);

    this.appendIfNotEmpty(addressLines, address.city);
    this.appendIfNotEmpty(addressLines, address.postcode.toUpperCase());

    if (includeCountry && addressLines.length > 0) {
      this.appendIfNotEmpty(addressLines, address.country);
    }

    return addressLines;
  }

  private getGbSingleLineAddress(address: IHasAddress, includeCountry: boolean): string {
    const addressLines: string[] = this.getGbAddressLines(address, includeCountry);
    return addressLines.map(line => line.trim().replace(/,$/, ''))
                       .join(', ');
  }

  private getEuropeanAddressLines(address: IHasAddress, includeCountry: boolean): string[] {
    const addressLines: string[] = this.splitStreetAddress(address.streetAddress);

    const cityState: string = this.concatenate(address.postcode.toUpperCase(), ' ', address.city);
    this.appendIfNotEmpty(addressLines, cityState);

    if (includeCountry && addressLines.length > 0) {
      this.appendIfNotEmpty(addressLines, address.country);
    }

    return addressLines;
  }

  private getEuropeanSingleLineAddress(address: IHasAddress, includeCountry: boolean): string {
    const addressLines: string[] = this.getEuropeanAddressLines(address, includeCountry);
    return addressLines.map(line => line.trim().replace(/,$/, ''))
                       .join(', ');
  }

  private getNorthAmericanAddressLines(address: IHasAddress, includeCountry: boolean): string[] {
    const addressLines: string[] = this.splitStreetAddress(address.streetAddress);

    const cityState: string = this.concatenate(address.city, ', ', address.state, ' ', address.postcode.toUpperCase());
    this.appendIfNotEmpty(addressLines, cityState);

    if (includeCountry && addressLines.length > 0) {
      this.appendIfNotEmpty(addressLines, address.country);
    }

    return addressLines;
  }

  private getNorthAmericanSingleLineAddress(address: IHasAddress, includeCountry: boolean): string {
    const addressLines: string[] = this.getNorthAmericanAddressLines(address, includeCountry);
    return addressLines.map(line => line.trim().replace(/,$/, ''))
                       .join(', ');
  }

  private getDefaultAddressLines(address: IHasAddress, includeCountry: boolean): string[] {
    const addressLines: string[] = this.splitStreetAddress(address.streetAddress);

    const cityState: string = this.concatenate(address.city, ', ', address.state, ' ', address.postcode.toUpperCase());
    this.appendIfNotEmpty(addressLines, cityState);

    if (includeCountry && addressLines.length > 0) {
      this.appendIfNotEmpty(addressLines, address.country);
    }

    return addressLines;
  }

  private getDefaultSingleLineAddress(address: IHasAddress, includeCountry: boolean): string {
    const addressLines: string[] = this.getDefaultAddressLines(address, includeCountry);
    return addressLines.map(line => line.trim().replace(/,$/, ''))
                       .join(', ');
  }

  private shouldIncludeCountry(address: IHasAddress): [string, boolean] {
    const countryCode: string = address.countryCode.length > 0 ? address.countryCode
                                                               : this.currentSettings.countryCode;
    return [countryCode, (countryCode !== this.currentSettings.countryCode)];
  }

  private getAddressFormat(countryCode: string): AddressFormat {
    const countryInfo: CountryInfo | undefined = SUPPORTED_COUNTRIES.get(countryCode);
    return countryInfo ? countryInfo.addressFormat : AddressFormat.Default;
  }

  public hasStates(countryCode: string): boolean {
    const countryInfo: CountryInfo | undefined = SUPPORTED_COUNTRIES.get(countryCode);
    return countryInfo ? countryInfo.hasStates : false;
  }

  private splitStreetAddress(streetAddress: string): string[] {
    return streetAddress.split(/\r?\n|\r/)
                        .filter(line => line.trim().length > 0);
  }

  private concatenate(firstPart: string, ...parts: string[]): string {
    let text: string = firstPart;

    for (let i: number = 0; i < parts.length; i++) {
      const delimiter: string = parts[i++];
      const part: string = parts[i];

      if (part.length > 0) {
        if (text.length > 0) {
          text += delimiter;
        }
        text += part;
      }
    }

    return text;
  }

  private appendIfNotEmpty(addressLines: string[], line: string): void {
    if (line.length > 0) {
      addressLines.push(line);
    }
  }

  public getDummyAddress(countryCode: string, country: string, alt: boolean = false): Address {
    let address: Address;

    switch (countryCode.toUpperCase()) {
      case 'CA':
        address = alt ? new Address('223 Gladys Allison Pl', 'North York', 'ON', 'M2N 4T5', countryCode, country)
                      : new Address('1 Lakeport Rd', 'St. Catharines', 'ON', 'L2N 5B3', countryCode, country);
        break;

      case 'GB':
        address = alt ? new Address('12 Lavister Rd', 'Rossett', '', 'LL12 0DL', countryCode, country)
                      : new Address('31 Westgate Rd', 'Chester', '', 'CH1 1BE', countryCode, country);
        break;

      case 'US':
      default:
        address = alt ? new Address('3119 Deva Way', 'Aurora', 'CO', '80016', 'US', 'United States')
                      : new Address('2900 Spring Creek Ave', 'Boulder', 'CO', '80303', 'US', 'United States');
        break;
    }

    return address;
  }
}
