import { getDate } from '../../utility';
import { AccountOrderSettings } from './account-order-settings';
import { AccountStatus, CompanyType } from './enums';

enum MaxStringLengths {
  CurrencyCode = 3
}

export class Account extends AccountOrderSettings {
  public static readonly MaxStringLengths = { ...MaxStringLengths, ...AccountOrderSettings.MaxStringLengths };
  public readonly schemaVersion: number = 0;
  public readonly showCountry: boolean = false;
  /* The company type is initialized during sign-up/account creation as a convenience, but isn't
    actually used anywhere. So to keep things simple, it's not currently displayed or editable
    in the UI.
  */
  public readonly companyType: CompanyType = CompanyType.NotSpecified;
  public readonly financialYearStart: Date = new Date();
  public readonly currencyCode: string = '';
  public readonly includeTax: boolean = false;
  public readonly accountStatus: AccountStatus = AccountStatus.None;

  constructor(accountSettings?: Account) {
    super(accountSettings);

    if (accountSettings) {
      this.schemaVersion = accountSettings.schemaVersion;
      this.showCountry = accountSettings.showCountry;
      this.companyType = accountSettings.companyType;
      this.financialYearStart = getDate(accountSettings.financialYearStart);
      this.currencyCode = accountSettings.currencyCode;
      this.includeTax = accountSettings.includeTax;
      this.accountStatus = accountSettings.accountStatus;
    }
  }
}
