import { ThemeSettings } from './core/model';
import { Size } from './utility';

export const noop: () => void = () => {};

export const HUNDRED_PERCENT: number = 100;
export const BASE16_RADIX: number = 16;
export const PAPERSIZE_A4: Size = { width: 210, height: 297 };
export const PAPERSIZE_LETTER: Size = { width: 215.9, height: 279.4 };

export const AVAILABLE_ORDER_THEMES: { [name: string]: ThemeSettings } = {
  euclid: {colour: '#1582dc', altColour: '#ffffff' },
  alta:   {colour: '#a6181d', altColour: '#778899' },
  deco:   {colour: '#cbf1eb', altColour: '#333333' },
  baron:  {colour: '#424242', altColour: '#adf6b4' }
};
export const THEME_COLOUR_CSS_VARIABLE: string = '--theme-color';
export const THEME_ALT_COLOUR_CSS_VARIABLE: string = '--theme-alt-color';
export const TEMPLATE_VIEW_SCALE_CSS_VARIABLE: string = '--template-view-scale';
export const TEMPLATE_VIEW_MARGIN_CSS_VARIABLE: string = '--template-view-margin';
export const BORDER_DARKENING_AMOUNT: number = 5;

export const AUTOCOMPLETE_SEARCH_DELAY: number = 500;
export const JSON_STRINGIFY_SPACES: number = 2;
export const MIN_AUTOCOMPLETE_TERM_LENGTH: number = 3;
export const PASSWORD_NOT_SCORED_YET: number = -1;   /* Zxcvbn can return 0 to 4 */
export const MIN_PASSWORD_STRENGTH: number = 3;
export const MAX_PASSWORD_LENGTH: number = 128;   /* Practical limit - see https://cheatsheetseries.owasp.org/cheatsheets/Password_Storage_Cheat_Sheet.html#maximum-password-lengths */

export const MIN_DATE: Date = new Date(0);
export const MIN_DATE_STRING: string = MIN_DATE.toISOString();
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const MAX_DATE: Date = new Date(9999, 11, 31);
export const MAX_DATE_STRING: string = MAX_DATE.toISOString();
export const MAX_DATE_TICKS: number = MAX_DATE.valueOf();

export const DEFAULT_LIST_PAGE_SIZE: number = 25;
export const HIGHEST_CHARACTER: string = '\uFFFF';

export const CUSTOMER_NAME_PLACEHOLDER: string = '{{CUSTOMER}}';
export const COMPANY_NAME_PLACEHOLDER: string = '{{COMPANY}}';
export const ORDER_URL_PLACEHOLDER: string = '{{LINK}}';   /* Obviously, this must match the placeholder in the web API */

export const DUMMY_ROUTE_SEGMENT_FOR_NEW_ORDER: string = 'add';
