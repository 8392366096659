import { Injectable } from '@angular/core';

import { Account, AccountStatus, Address, Order, OrderDetails, OrderState, OrderType } from '../core/model';
import { AddressService } from './address.service';

interface AccountValues {
  phone: string;
  mobile: string;
  web: string;
  companyTaxNumber: string;
}

interface OrderValues {
  customerName: string;
}

@Injectable()
export class DummyDataService {
  constructor(private _addressService: AddressService) {}

  public getDummyAccount(account: Account): Account {
    let address: Address = Address.getAddress(account);

    if (address.streetAddress.length === 0 && address.city.length === 0) {
      address = this._addressService.getDummyAddress(account.countryCode, account.country);
    }

    const dummyValues: AccountValues = this.getDummyAccountValues(account.countryCode);

    const dummyAccount: Account = {
      ...account,
      idToken: '00000000000000000000000000',
      _id: 'account-00000000000000000000000000',
      _rev: '1-00000000000000000000000000000000',
      streetAddress: address.streetAddress,
      city: address.city,
      state: address.state,
      postcode: address.postcode,
      phone: this.getNonEmptyValue(account.phone, dummyValues.phone),
      mobile: this.getNonEmptyValue(account.mobile, dummyValues.mobile),
      web: this.getNonEmptyValue(account.web, dummyValues.web),
      companyTaxNumber: this.getNonEmptyValue(account.companyTaxNumber, dummyValues.companyTaxNumber),
      taxRate: 0.2,
      includeTax: true,
      quoteNotes: this.getNonEmptyValue(account.quoteNotes, 'These are the notes for any quotes\n - terms and conditions\n - validity\n - etc'),
      invoiceNotes: this.getNonEmptyValue(account.invoiceNotes, 'These are the notes for any invoices\n - payment methods\n - payment terms\n - etc'),
      smallPrint: this.getNonEmptyValue(account.smallPrint, 'This is the small print if you need it'),
      accountStatus: AccountStatus.None
    };

    return dummyAccount;
  }

  private getDummyAccountValues(countryCode: string): AccountValues {
    let values: AccountValues;

    switch (countryCode.toUpperCase()) {
      case 'GB':
        values = {
          phone: '01632 960960',
          mobile: '07700 900900',
          web: 'www.example.co.uk',
          companyTaxNumber: ''
        };
        break;

      case 'US':
      default:
        values = {
          phone: '303-568-6538',
          mobile: '712-550-5073',
          web: 'www.example.com',
// TODO: TASK - work out what this should look like
          companyTaxNumber: '987654321'
        };
        break;
    }

    return values;
  }

  public getDummyOrder(account: Account): Order {
    const address: Address = this._addressService.getDummyAddress(account.countryCode, account.country, true);
    const dummyValues: OrderValues = this.getDummyOrderValues(account.countryCode);

    const dummyOrder: Order = {
      details: new OrderDetails({
        idToken: '00000000000000000000000000',
        _id: 'orderdetails-00000000000000000000000000',
        _rev: '1-00000000000000000000000000000000',
        $doctype: 'orderDetails',
        $docver: '1.0.0',
        accountId: '00000000000000000000000000',
        customerId: '00000000000000000000000000',
        customerName: dummyValues.customerName,
        streetAddress: address.streetAddress,
        city: address.city,
        state: address.state,
        postcode: address.postcode,
        countryCode: account.countryCode,
        country: account.country,
        orderType: OrderType.Invoice,
        orderNumber: '01234',
        description: 'Decorating services for living room, hallway and kitchen',
        orderState: OrderState.Paid,
        raisedByUserId: '',
        dateRaised: new Date(),
        dateDue: new Date(),
        datePaid: new Date(),
        isFixedPrice: true,
        currencyCode: account.currencyCode,
        includeTax: account.includeTax,
        netTotal: 940,
        taxTotal: 188,
        grossTotal: 1128,
        archived: false
      }),
      items: [
        {
          idToken: '00000000000000000000000001',
          _id: 'orderitem-00000000000000000000000001',
          _rev: '1-00000000000000000000000000000000',
          $doctype: 'orderItem',
          $docver: '1.0.0',
          orderId: '00000000000000000000000000',
          itemNumber: 0,
          description: 'Wallpaper\nLiving room - 4 rolls\nHallway - 2 rolls',
          quantity: 6,
          units: '',
          unitAmount: 12,
          netAmount: 72,
          taxAmount: 14.4,
          taxRate: 0.2,
          grossAmount: 86.4
        },
        {
          idToken: '00000000000000000000000002',
          _id: 'orderitem-00000000000000000000000002',
          _rev: '1-00000000000000000000000000000000',
          $doctype: 'orderItem',
          $docver: '1.0.0',
          orderId: '00000000000000000000000000',
          itemNumber: 1,
          description: 'Paint',
          quantity: 4,
          units: '',
          unitAmount: 22,
          netAmount: 88,
          taxAmount: 17.6,
          taxRate: 0.2,
          grossAmount: 105.6
        },
        {
          idToken: '00000000000000000000000003',
          _id: 'orderitem-00000000000000000000000003',
          _rev: '1-00000000000000000000000000000000',
          $doctype: 'orderItem',
          $docver: '1.0.0',
          orderId: '00000000000000000000000000',
          itemNumber: 2,
          description: 'Labour',
          quantity: 6,
          units: '',
          unitAmount: 130,
          netAmount: 780,
          taxAmount: 156,
          taxRate: 0.2,
          grossAmount: 936
        // },
        // { idToken: '', _id: '', _rev: '', $doctype: '', $docver: '', orderId: '', itemNumber: 2, description: 'Labour',
        //   quantity: 111.11, units: '', unitAmount: 11.11, netAmount: 111.11, taxAmount: 111.11, taxRate: 0.2, grossAmount: 666.66 },
        // { idToken: '', _id: '', _rev: '', $doctype: '', $docver: '', orderId: '', itemNumber: 2, description: 'Labour',
        //   quantity: 222.22, units: '', unitAmount: 22.22, netAmount: 222.22, taxAmount: 222.22, taxRate: 0.2, grossAmount: 777.77 },
        // { idToken: '', _id: '', _rev: '', $doctype: '', $docver: '', orderId: '', itemNumber: 2, description: 'Labour',
        //   quantity: 333.33, units: '', unitAmount: 33.33, netAmount: 333.33, taxAmount: 333.33, taxRate: 0.2, grossAmount: 888.88 },
        // { idToken: '', _id: '', _rev: '', $doctype: '', $docver: '', orderId: '', itemNumber: 2, description: 'Labour',
        //   quantity: 444.44, units: '', unitAmount: 44.44, netAmount: 444.44, taxAmount: 444.44, taxRate: 0.2, grossAmount: 999.99 },
        // { idToken: '', _id: '', _rev: '', $doctype: '', $docver: '', orderId: '', itemNumber: 2, description: 'Labour',
        //   quantity: 555.55, units: '', unitAmount: 55.55, netAmount: 555.55, taxAmount: 555.55, taxRate: 0.2, grossAmount: 1000
        }
      ]
    };

    return dummyOrder;
  }

  private getDummyOrderValues(countryCode: string): OrderValues {
    let values: OrderValues;

    switch (countryCode.toUpperCase()) {
      case 'GB':
        values = {
          customerName: 'Sue Jameson'
        };
        break;

      case 'US':
      default:
        values = {
          customerName: 'Colette Vega'
        };
        break;
    }

    return values;
  }

  private getNonEmptyValue(value: string, defaultValue: string): string {
    return value.length > 0 ? value : defaultValue;
  }
}
