import { Inject, Injectable } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';

import { AppConfig, appConfigToken } from '../core';
import { Logger, LogService } from '../core/logging';
import { NamedColour } from '../core/model';
import { CssClass, Notification } from '../enums';
import { NotificationComponent } from '../shared/common-ui/common-ui.module';
import { stringFormat } from '../utility';
import { SettingsService } from './settings.service';
import { UserSettingsService } from './user-settings.service';

export interface NotificationOptions {
  notification?: Notification;
  icon?: IconProp;
  showCloseButton?: boolean;
  color?: NamedColour;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly _log: Logger;

  constructor(private _popoverController: PopoverController,
              private _userSettingsService: UserSettingsService,
              private _settingsService: SettingsService,
              @Inject(appConfigToken) private _appConfig: AppConfig,
              logService: LogService) {
    this._log = logService.getLogger(this.constructor.name);
  }

  /** Shows the notification if it hasn't been displayed before (so does not give the user
   * the option of 'Don't show this again').
   */
  public showOnce(markdown: string, options?: NotificationOptions, heading: string = ''): void {
    this.showNotification(options?.notification ?? Notification.None,
                          markdown,
                          heading,
                          options?.icon,
                          true,
                          false,
                          options?.color ?? '');
  }

  /** Shows the notification regardless of previous display (so does not give the user
   * the option of 'Don't show this again').
   */
  public show(markdown: string, options?: NotificationOptions, heading: string = ''): void {
    this.showNotification(options?.notification ?? Notification.None,
                          markdown,
                          heading,
                          options?.icon,
                          true,
                          false,
                          options?.color ?? '');
  }

  /** Shows the notification regardless of previous display (so does not give the user
   * the option of 'Don't show this again').
   */
  public showModal(markdown: string, options?: NotificationOptions, heading: string = ''): Promise<void | HTMLIonPopoverElement> {
    return this.showNotification(options?.notification ?? Notification.None,
                                 markdown,
                                 heading,
                                 options?.icon,
                                 options?.showCloseButton ?? false,
                                 false,
                                 options?.color ?? 'primary');
  }

  /** Shows the notification with the option of 'Don't show this again' if this option
   * was not selected previously.
   */
  public showOptionally(markdown: string, options?: NotificationOptions, heading: string = ''): void {
    this.showNotification(options?.notification ?? Notification.None,
                          markdown,
                          heading,
                          options?.icon,
                          true,
                          true,
                          options?.color ?? '');
  }

  /** Displays a modal warning if the account has expired
   *
   * @param messageFormat String format using `{0}` as a numeric placeholder for the
   * location of the URL where the user can renew their subscription
   * @returns true if the account has expired
   */
  public showIfAccountExpired(messageFormat: string): boolean {
    const hasAccountExpired: boolean = this._settingsService.hasAccountExpired();

    if (hasAccountExpired) {
      const link: string = new URL('/account/billing', this._appConfig.webUri).toString();
      const message: string = stringFormat(messageFormat, link);
      const options: NotificationOptions = {
        icon: 'triangle-exclamation',
        showCloseButton: true,
        color: 'warning'
      };

      this.showModal(message, options, 'Account Expired');
    }

    return hasAccountExpired;
  }

  private showNotification(notification: Notification,
                           markdown: string,
                           heading: string,
                           icon: IconProp | undefined,
                           showCloseButton: boolean,
                           showCheckbox: boolean,
                           color: NamedColour): Promise<void | HTMLIonPopoverElement> {
    let promise: Promise<void | HTMLIonPopoverElement> = Promise.resolve();

    if (notification === Notification.None || !this._userSettingsService.getNotificationFlag(notification)) {
      promise = this._popoverController.create({
                                          component: NotificationComponent,
                                          componentProps: {
                                            showCloseButton,
                                            showCheckbox,
                                            icon,
                                            heading,
                                            markdown,
                                            color
                                          },
                                          cssClass: [CssClass.PopoverIsWide, color],
                                          backdropDismiss: false
                                        })
                                       .then((popover: HTMLIonPopoverElement) => {
                                          popover.onDidDismiss()
                                                 .then((eventDetail: OverlayEventDetail<any>) => {
                                                    const dontShowAgain: boolean = eventDetail.data;
                                                    if (   dontShowAgain
                                                        || (notification !== Notification.None && !showCheckbox)) {
                                                      this._userSettingsService.setNotificationFlag(notification);
                                                    }
                                                  });
                                          popover.present();
                                          return popover;
                                        })
                                       .catch(error => {
                                          this._log.error(`Error displaying help info (${notification})`, error);
                                        });
    }

    return promise;
  }
}
