import { Injectable } from '@angular/core';
import { Auth, signInWithCustomToken } from '@angular/fire/auth';
import { AuthGuard as FireAuthGuard } from '@angular/fire/auth-guard';
import { ActivatedRouteSnapshot, CanActivate, createUrlTreeFromSnapshot, Data, Navigation, Params, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, of, switchMap } from 'rxjs';

import { QuerystringKey } from '../enums';
import { SettingsService } from '../services';
import { getResolvedUrl, stringEqualsIgnoreCase } from '../utility';
import { redirectUnauthorizedToLogin } from './auth-pipe';
import { AuthErrorCode } from './firebase-auth';
import { Logger, LogService } from './logging';

// eslint-disable-next-line @typescript-eslint/typedef, @typescript-eslint/explicit-function-return-type
export const canActivateIfAuthorized = (data: Data = {}) => ({
  canActivate: [ AuthGuard ],
  data: {
    authGuardPipe: redirectUnauthorizedToLogin,
    ...data
  }
});

@Injectable({
  providedIn: 'any'
})
export class AuthGuard implements CanActivate {
  private readonly _fireAuthGuard: FireAuthGuard;
  private readonly _log: Logger;

  constructor(private _settingsService: SettingsService,
              private _router: Router,
              private _auth: Auth,
              logService: LogService) {
    this._fireAuthGuard = new FireAuthGuard(_router, _auth);
    this._log = logService.getLogger('AuthGuard');
  }

  public canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
    return this.checkForAuthToken(next)
               .pipe(switchMap((redirection: boolean | UrlTree) => {
                      return redirection ? of(redirection)
                                         : this._fireAuthGuard.canActivate(next, state);
                     }),
                     map((can: boolean | UrlTree) => {
                      if (typeof(can) === 'boolean' && !this._settingsService.settingsInitialized) {
                        this._log.debug('Settings not initialized yet - redirecting...');
                        can = this.getRedirectionToLoadingPage(next);
                      }

                      return can;
                    }));
  };

  private checkForAuthToken(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>(observer => {
      const token: string = next.queryParams[QuerystringKey.Token];

      if (token && token.length > 0) {
        /* We'll remove the token from route so that, regardless of whether the token is valid
          or not, it can be removed from the address bar.
        */
        const queryParams: Params = {};
        Object.keys(next.queryParams)
              .forEach(key => {
                if (!stringEqualsIgnoreCase(key, QuerystringKey.Token)) {
                  queryParams[key] = next.queryParams[key];
                }
              });
        const urlTree: UrlTree = createUrlTreeFromSnapshot(next, [], queryParams);

        signInWithCustomToken(this._auth, token)
                        .then(userCredential => {
                          this._log.debug(`Authentication completed from querystring token (${userCredential.user.email})`);
                          observer.next(urlTree);
                        })
                        .catch(error => {
                          if (error.code === AuthErrorCode.UserNotFound) {
                            this._log.warn('Authentication failed for querystring token', error);
                          } else {
                            this._log.fatal('Authentication failed for querystring token', error);
                          }
                          observer.next(urlTree);
                        });
      } else {
        observer.next(false);
      }
    });
  }

  private getRedirectionToLoadingPage(next: ActivatedRouteSnapshot): UrlTree {
    /* HACK ALERT!  Until https://github.com/angular/angular/issues/27148 is fixed,
      there is no way to pass in navigation extras, such as `skipLocationChange`,
      to the UrlTree returned to the guard.

      However, as noted in https://github.com/angular/angular/issues/27148#issuecomment-831414318,
      the guard will reuse the navigation extras from the original route, so if we
      update that, we should get the desired effect (ie. the loading page will be
      hidden in the browser history).
    */
    const navigation: Navigation | null = this._router.getCurrentNavigation();
    if (navigation) {
      navigation.extras.skipLocationChange = true;
    }

    const targetUrl: string = getResolvedUrl(next);
    return this._router.createUrlTree(['/loading'],
                                      {
                                        queryParams: { [QuerystringKey.RedirectUrl]: targetUrl }
                                      });
  }
}
