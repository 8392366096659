import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MenuDisplayService } from './services';

@Component({
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss']
})
export class SidebarComponent {
  public showMenu$: BehaviorSubject<boolean>;

  constructor(menuDisplayService: MenuDisplayService) {
    this.showMenu$ = menuDisplayService.showMenu$;
  }
}
