import { AuthPipe, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { ActivatedRouteSnapshot } from '@angular/router';

import { QuerystringKey } from '../enums';
import { getResolvedUrl } from '../utility';

export function redirectUnauthorizedToLogin(next: ActivatedRouteSnapshot): AuthPipe {
  const targetUrl: string = getResolvedUrl(next);
  const redirectTo: string = `/login?${QuerystringKey.RedirectUrl}=${encodeURIComponent(targetUrl)}`;

  return redirectUnauthorizedTo(redirectTo);
}
