<ion-button *ngIf="this.showExpiryWarning" [routerLink]="this.internalBillingLink" [href]="this.externalBillingLink" [target]="this.billingLinkTarget" [color]="this.expiryWarningColour" expand="full">
	{{ this.expiryWarning }}
</ion-button>
<ion-content class="ion-padding">
	<swiper [breakpoints]="this.swiperBreakpoints" [grabCursor]="true" [pagination]="this.swiperPagination" [touchStartPreventDefault]="false">
		<ng-template swiperSlide>
			<label>Outstanding</label>
			{{ this.outstandingToDate }}
			<label>Total</label>
		</ng-template>
		<ng-template swiperSlide>
			<label>Invoiced</label>
			{{ this.invoicedThisMonth }}
			<label>This Month</label>
		</ng-template>
		<ng-template swiperSlide>
			<label>Quoted</label>
			{{ this.quotedThisMonth }}
			<label>This Month</label>
		</ng-template>
	</swiper>
	<wky-bar-chart [datasets]="this.datasets" [labels]="this.labels" [options]="this.chartOptions" [legend]="false" [showEmptyState]="this.showEmptyState">
		<div slot="empty-state">
			<fa-duotone-icon [icon]="['fad', 'analytics']"></fa-duotone-icon>
			<h1>Not issued anything yet?</h1>
			<p>Once you've issued some quotes and invoices, your monthly totals will be shown here.</p>
<!-- TODO: only display this if tabs are visible -->
			<p>Click the <ion-icon name="add"></ion-icon> button to start</p>
		</div>
	</wky-bar-chart>
<!-- TODO: only display this if tabs are visible -->
	<ion-fab horizontal="end" vertical="bottom" slot="fixed">
		<ion-fab-button color="primary">
			<ion-icon name="add"></ion-icon>
		</ion-fab-button>
		<ion-fab-list side="top">
			<ion-fab-button [routerLink]="['/invoices', DUMMY_ROUTE_SEGMENT_FOR_NEW_ORDER]" color="secondary">
				<fa-icon icon="file-invoice" [fixedWidth]="true"></fa-icon>
			</ion-fab-button>
			<ion-fab-button [routerLink]="['/quotes', DUMMY_ROUTE_SEGMENT_FOR_NEW_ORDER]" color="secondary">
				<fa-icon icon="comment-dollar" [fixedWidth]="true"></fa-icon>
			</ion-fab-button>
		</ion-fab-list>
	</ion-fab>
</ion-content>
