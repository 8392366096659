import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

const REVOKE_OBJECT_URL_TIMEOUT: number = 30_000;

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private _http: HttpClient) {}

  public download(url: string, filename: string): Observable<void> {
    return this._http.get(url, {
                            responseType: 'blob',
                            observe: 'response'
                          })
                     .pipe(map((response) => {
                            const blob: Blob | null = response.body;

                            if (blob) {
                              const disposition: string = response.headers.get('Content-Disposition') ?? '';
                              const results: RegExpExecArray | null = /^(?:.*;\s*)?filename="([^"]+)/i.exec(disposition);

                              if (results && results.length > 1) {
                                filename = results[1];
                              }

                              this.downloadToBrowser(blob, filename);
                            }
                          }));
  }

  private downloadToBrowser(blob: Blob, name: string): void {
    const objectUrl: string = URL.createObjectURL(blob);
    const a: HTMLAnchorElement = document.createElement('a');

    a.href = objectUrl;
    a.download = name;
    a.rel = 'noopener';   // Prevent reverse tabnabbing
    a.target = '_blank';
    a.click();

    setTimeout(() => {
      URL.revokeObjectURL(a.href);
    }, REVOKE_OBJECT_URL_TIMEOUT);
  }
}
