import { Inject, Injectable } from '@angular/core';
import { add, endOfDay, intervalToDuration, startOfDay } from 'date-fns';
import { catchError, concatMap, Observable, of } from 'rxjs';

import { AppConfig, appConfigToken } from '../core';
import { LogService } from '../core/logging';
import { Account, AccountOrder, AccountOrderSettings, Address, base32guid, Email, IdPrefix, Order, OrderDetails, OrderItem, OrderNumber, OrderState, OrderType } from '../core/model';
import { Uuid } from '../utility';
import { AccountService } from './account.service';
import { BaseService } from './base.service';
import { LocaleService } from './locale.service';
import { OrderDetailsService } from './order-details.service';
import { OrderItemService } from './order-item.service';
import { OrderNumberService } from './order-number.service';
import { SettingsService } from './settings.service';
import { WorkeryApiService } from './workery-api.service';

/* This service uses the server API rather than the local DB because it's for use by the public
  order view page which supports anonymous web-based access, so there is no local DB.
*/
@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseService {
  constructor(@Inject(appConfigToken) private _appConfig: AppConfig,
              private _accountService: AccountService,
              private _orderDetailsService: OrderDetailsService,
              private _orderItemService: OrderItemService,
              private _orderNumberService: OrderNumberService,
              private _localeService: LocaleService,
              private _workeryApiService: WorkeryApiService,
              settingsService: SettingsService,
              logService: LogService) {
    super(settingsService, logService);
  }

  public getByKey(accountOrderId: string): Observable<AccountOrder> {
    const idParts: string[] = accountOrderId.split('-');
    let accountId: base32guid = idParts[0];
    let orderId: base32guid = idParts[1];
    let details: OrderDetails;
    let items: OrderItem[];

// TODO: TASK - once ngrx has been removed, we should replace this with a call to OrderService that returns the
// order and its items in one call
    return this._orderDetailsService.getById(orderId)
                                    .pipe(concatMap((orderDetails: OrderDetails) => {
                                            details = orderDetails;

                                            return this._orderItemService.getByOrderId(orderDetails._id);
                                          }),
                                          concatMap((orderItems: OrderItem[]) => {
                                            items = orderItems;

                                            return this._accountService.getById(accountId);
                                          }),
                                          concatMap((account: Account) => {
                                            const settings: AccountOrderSettings = account as AccountOrderSettings;
                                            const accountOrder: AccountOrder = {
                                              settings,
                                              details,
                                              items
                                            };
                                            return of(accountOrder);
                                          }),
                                          catchError(err => {
                                            this.log.info(`Error loading order '${accountOrderId}'; trying web API instead`, err);

                                            /* In the event of an error, we'll try the web API as it could be that
                                              this is an anonymous request by the customer for their order.
                                            */
                                            accountId = encodeURIComponent(accountId);
                                            orderId = encodeURIComponent(orderId);
                                            return this._workeryApiService.get<AccountOrder>(`accounts/${accountId}/orders/${orderId}`);
                                          }));
  }

  public copy(order: Order, newOrderType: OrderType, keepCustomer: boolean): Observable<base32guid> {
    let newOrderId: base32guid = '';

    return this._orderNumberService.getNext(newOrderType)
                                   .pipe(concatMap((nextNumber: OrderNumber) => {
                                          const newOrderNumber: string = nextNumber.value;
                                          const newOrderDetails: OrderDetails = this.copyOrderDetails(order,
                                                                                                      newOrderType,
                                                                                                      newOrderNumber,
                                                                                                      keepCustomer);

                                          return this._orderDetailsService.upsert(newOrderDetails);
                                        }),
                                        concatMap((newOrderDetails: OrderDetails) => {
                                          newOrderId = newOrderDetails.idToken;

                                          const orderItems: OrderItem[] = order.items.sort((a, b) => a.itemNumber - b.itemNumber);
                                          const newItems: OrderItem[] = [];

                                          for (const [i, orderItem] of orderItems.entries()) {
                                            const idToken: base32guid = new Uuid().toBase32();
                                            const item: OrderItem = new OrderItem({ ...orderItem,
                                                                                    _id: IdPrefix.OrderItem + idToken,
                                                                                    _rev: '',
                                                                                    idToken,
                                                                                    orderId: newOrderId,
                                                                                    itemNumber: i
                                                                                  });
                                            newItems.push(item);
                                          }

                                          return this._orderItemService.add(newItems);
                                        }),
                                        concatMap((newItems: OrderItem[]) => {
                                          return of(newOrderId);
                                        }));
  }

  private copyOrderDetails(order: Order, newOrderType: OrderType, newOrderNumber: string, keepCustomer: boolean): OrderDetails {
    const orderDetails: OrderDetails = order.details;
    let address: Address = Address.getAddress(orderDetails);

    if (!keepCustomer) {
      const countryCode: string = this.currentSettings.countryCode;
      const countryName: string = this._localeService.getCountryName(countryCode);
      address = new Address('', '', '', '', countryCode, countryName);
    }

    const dateRaised: Date | undefined = new Date();
    let dateDue: Date | undefined;

    if (orderDetails.dateRaised && orderDetails.dateDue) {
      const timeToPay: Duration = intervalToDuration({
                                    start: startOfDay(orderDetails.dateRaised),
                                    end: startOfDay(orderDetails.dateDue)
                                  });
      dateDue = endOfDay(add(dateRaised, timeToPay));
    }

    return new OrderDetails({ ...new OrderDetails(newOrderType),
                              accountId: orderDetails.accountId,
                              customerId: keepCustomer ? orderDetails.customerId : '',
                              customerName: keepCustomer ? orderDetails.customerName : '',
                              streetAddress: address.streetAddress,
                              city: address.city,
                              state: address.state,
                              postcode: address.postcode,
                              countryCode: address.countryCode,
                              country: address.country,
                              orderNumber: newOrderNumber,
                              description: orderDetails.description,
                              orderState: OrderState.InProgress,
                              raisedByUserId: '',
                              dateRaised,
                              dateDue,
                              datePaid: undefined,
                              isFixedPrice: orderDetails.isFixedPrice,
                              currencyCode: orderDetails.currencyCode,
                              includeTax: orderDetails.includeTax,
                              netTotal: orderDetails.netTotal,
                              taxTotal: orderDetails.taxTotal,
                              grossTotal: orderDetails.grossTotal,
                              archived: false
                            });
  }

  public getPdfDownloadUrl(accountOrderId: string): string {
    const apiServiceBaseUri: string = this._appConfig.workeryApiServiceBaseUri;
    const idParts: string[] = accountOrderId.split('-');
    let accountId: base32guid = idParts[0];
    let orderId: base32guid = idParts[1];
    let pdfDownloadUrl: string = '';

    if (accountOrderId.length > 0 && apiServiceBaseUri.length > 0) {
      accountId = encodeURIComponent(accountId);
      orderId = encodeURIComponent(orderId);
      pdfDownloadUrl = `${apiServiceBaseUri}accounts/${accountId}/orders/${orderId}/print`;
    }

    return pdfDownloadUrl;
  }

  public send(accountId: base32guid, orderId: base32guid, email: Email): Observable<boolean> {
    accountId = encodeURIComponent(accountId);
    orderId = encodeURIComponent(orderId);
    return this._workeryApiService.post<boolean>(`accounts/${accountId}/orders/${orderId}/send`, email);
  }
}
