import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

import { AppConfig, appConfigToken } from '../core';
import { Logger, LogService } from '../core/logging';

@Injectable()
export class WorkeryApiService {
  private readonly _log: Logger;

  constructor(@Inject(appConfigToken) private _appConfig: AppConfig,
              private _http: HttpClient,
              logService: LogService) {
    this._log = logService.getLogger('WorkeryApiService');
  }

  public get<T>(url: string, onErrorValue?: T): Observable<T> {
    return this._http.get<T>(this._appConfig.workeryApiServiceBaseUri + url)
                     .pipe(catchError(err => {
                            this._log.error(`API service error`, err);
                            if (!onErrorValue) {
                              throw err;
                            }
                            return of<T>(onErrorValue);
                          }));
  }

  public put<T>(url: string, data: any): Observable<T> {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    return this._http.put<T>(this._appConfig.workeryApiServiceBaseUri + url, data,
                              {
                                headers: new HttpHeaders().set('Content-Type', 'application/json')
                              });
// TODO: TASK - handle retries intelligently, based on the error
                    //  .pipe(retry(requestRetryCount));
  }

  public post<T>(url: string, data: any): Observable<T> {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    return this._http.post<T>(this._appConfig.workeryApiServiceBaseUri + url, data,
                              {
                                headers: new HttpHeaders().set('Content-Type', 'application/json')
                              });
// TODO: TASK - handle retries intelligently, based on the error
                    //  .pipe(retry(requestRetryCount));
  }

  public patch<T>(url: string, data: any): Observable<T> {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    return this._http.patch<T>(this._appConfig.workeryApiServiceBaseUri + url, data,
                              {
                                headers: new HttpHeaders().set('Content-Type', 'application/json')
                              });
// TODO: TASK - handle retries intelligently, based on the error
                    //  .pipe(retry(requestRetryCount));
  }
}
