import { MAX_PASSWORD_LENGTH } from 'src/app/constants';
import { AccountOrderSettings } from './account-order-settings';
import { Address } from './address';
import { UserProfile } from './user-profile';

enum MaxStringLengths {
  Email = UserProfile.MaxStringLengths.Email,
  Password = MAX_PASSWORD_LENGTH,
  CompanyName = AccountOrderSettings.MaxStringLengths.CompanyName,
  CountryCode = Address.MaxStringLengths.CountryCode,
  CompanyRegNo = 100
}

enum MinStringLengths {
  Password = UserProfile.MinStringLengths.Password
}

export class NewAccount {
  public static readonly MaxStringLengths = MaxStringLengths;
  public static readonly MinStringLengths = MinStringLengths;

  constructor(public email: string,
              public password: string,
              public companyName: string,
              public countryCode: string,
              public companyRegNo: string) {}
}
