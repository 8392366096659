import { Injectable } from '@angular/core';

import { OrderType } from '../core/model';

@Injectable()
export class EnumService {
  private _orderTypes!: Map<number, string>;

  public getOrderTypes(): Map<number, string> {
    if (!this._orderTypes) {
      this._orderTypes = new Map([
        [Number(OrderType.Quote), 'Quote'],
        [Number(OrderType.Invoice), 'Invoice']
      ]);
    }

    return new Map(this._orderTypes);
  }

}
