import { AVAILABLE_ORDER_THEMES } from 'src/app/constants';
import { IHasAddress } from './address';
import { CouchDbEntity } from './couchdb-entity';
import { OrderTheme } from './order-theme';
import { ThemeSettings } from './theme-settings';

enum MaxStringLengths {
  CompanyName = 100,
  Phone = 50,
  Mobile = 50,
  Email = 320,
  Web = 500,
  CompanyTaxNumber = 50,
  QuoteNotes = 1048576,     /* 1MB should be enough */
  InvoiceNotes = 1048576,   /* 1MB should be enough */
  SmallPrint = 1000
}

export class AccountOrderSettings extends CouchDbEntity implements IHasAddress {
  public static readonly MaxStringLengths = MaxStringLengths;

  public readonly companyName: string = '';
  public readonly streetAddress: string = '';
  public readonly city: string = '';
  public readonly state: string = '';
  public readonly postcode: string = '';
  public readonly countryCode: string = '';
  public readonly country: string = '';
  public readonly phone: string = '';
  public readonly mobile: string = '';
  public readonly email: string = '';
  public readonly web: string = '';
  public readonly companyTaxNumber: string = '';
  public readonly taxRate: number = 0;
  public readonly paperSize: string = '';
  public readonly orderTheme: string = '';
  public readonly quoteNotes: string = '';
  public readonly invoiceNotes: string = '';
  public readonly smallPrint: string = '';

  constructor(accountOrderSettings?: AccountOrderSettings) {
    super(accountOrderSettings);

    if (accountOrderSettings) {
      this.companyName = accountOrderSettings.companyName;
      this.streetAddress = accountOrderSettings.streetAddress;
      this.city = accountOrderSettings.city;
      this.state = accountOrderSettings.state;
      this.postcode = accountOrderSettings.postcode;
      this.countryCode = accountOrderSettings.countryCode;
      this.country = accountOrderSettings.country;
      this.phone = accountOrderSettings.phone;
      this.mobile = accountOrderSettings.mobile;
      this.email = accountOrderSettings.email;
      this.web = accountOrderSettings.web;
      this.companyTaxNumber = accountOrderSettings.companyTaxNumber;
      this.taxRate = accountOrderSettings.taxRate;
      this.paperSize = accountOrderSettings.paperSize;
      this.orderTheme = accountOrderSettings.orderTheme;
      this.quoteNotes = accountOrderSettings.quoteNotes;
      this.invoiceNotes = accountOrderSettings.invoiceNotes;
      this.smallPrint = accountOrderSettings.smallPrint;
    }

    /* Ideally, there will always be an order theme set, initialized when the account is created.
      But we don't really want to duplicate the settings for a theme in the web API.  So if it's
      not set, we'll just assign a default one here from the predefined values.
    */
    if (this.orderTheme.length === 0) {
      const themeName: string = Object.keys(AVAILABLE_ORDER_THEMES)[0];
      const themeSettings: ThemeSettings = AVAILABLE_ORDER_THEMES[themeName];

      this.orderTheme = OrderTheme.fromSettings(themeName,
                                                themeSettings.colour,
                                                themeSettings.altColour);
    }
  }
}
