import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CompanyProfile, CompanySearchResult } from '../core/model';
import { WorkeryApiService } from './workery-api.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyDataService {
  constructor(private _workeryApiService: WorkeryApiService) {}

  public search(countryCode: string, term: string): Observable<CompanySearchResult[]> {
    countryCode = encodeURIComponent(countryCode);
    term = encodeURIComponent(term);

    const serviceUrl: string = `companies/search/${countryCode}/${term}`;
    return this._workeryApiService.get<CompanySearchResult[]>(serviceUrl, []);
  }

  public lookup(countryCode: string, registrationNumber: string): Observable<CompanyProfile> {
    countryCode = encodeURIComponent(countryCode);
    registrationNumber = encodeURIComponent(registrationNumber);

    const serviceUrl: string = `companies/lookup/${countryCode}/${registrationNumber}`;

    return this._workeryApiService.get<CompanyProfile>(serviceUrl);
  }
}
