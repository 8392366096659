import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import PouchDB from 'pouchdb';
import PouchDebug from 'pouchdb-debug';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
} else {
  PouchDB.plugin(PouchDebug);

  if (environment.config.logging.pouchDb) {
    PouchDB.debug.enable('*');
  } else {
    PouchDB.debug.disable();
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
                        // eslint-disable-next-line unicorn/prefer-top-level-await, no-console
                        .catch(error => console.error(error));
