enum MaxStringLengths {
  StreetAddress = 500,
  City = 50,
  State = 50,
  Postcode = 20,
  CountryCode = 3,
  Country = 50
}

export interface IHasAddress {
  streetAddress: string;
  city: string;
  state: string;
  postcode: string;
  countryCode: string;
  country: string;
}

export class Address {
  public static readonly MaxStringLengths = MaxStringLengths;

  constructor();
  constructor(streetAddress: string,
              city: string,
              state: string,
              postcode: string,
              countryCode: string,
              country: string
             );
  constructor(public streetAddress: string = '',
              public city: string = '',
              public state: string = '',
              public postcode: string = '',
              public countryCode: string = '',
              public country: string = '') {}

  public static getAddress(hasAddress: IHasAddress): Address {
    return new Address(hasAddress.streetAddress,
                       hasAddress.city,
                       hasAddress.state,
                       hasAddress.postcode,
                       hasAddress.countryCode,
                       hasAddress.country);
  }
}
