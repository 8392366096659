import { Directive, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';

import { stringFormat } from '../utility';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix -- required for abstract base classes that would otherwise have to be decorated with @Component to avoid NG2007 errors
export abstract class PageComponent implements OnDestroy {
  public isLeavingView$: Subject<void> = new Subject();
  public isBeingDestroyed$: Subject<void> = new Subject();
  private _pageTitle: string = '';

  constructor(protected titleService: Title) {}

  public ionViewWillEnter(): void {
    this.setPageTitle();
  }

  public ionViewDidLeave(): void {
    this.isLeavingView$.next();
  }

  public ngOnDestroy(): void {
    this.isLeavingView$.complete();
    this.isBeingDestroyed$.next();
    this.isBeingDestroyed$.complete();
  }

  public get pageTitle(): string {
    return this._pageTitle;
  }
  public set pageTitle(title: string) {
    this._pageTitle = title;
    this.setPageTitle();
  }

  private setPageTitle(): void {
    if (this._pageTitle.length > 0) {
      this.titleService.setTitle(stringFormat('{0} - Workery', this._pageTitle));
    }
  }
}
