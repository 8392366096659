import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { HIGHEST_CHARACTER } from '../constants';
import { QueryParams } from '../core';
import { LogService } from '../core/logging';
import { CustomerSearchResult, Name } from '../core/model';
import { PouchDbDataService } from './pouchdb-data.service';
import { PouchDbService } from './pouchdb.service';

@Injectable()
export class CustomerSearchService extends PouchDbDataService<CustomerSearchResult> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(pouchDbService: PouchDbService,
              logService: LogService) {
    /* Technically not needed, but if omitted, there is no constructor for the base class to
      get the class name from (which, bizarrely, then leads to 'circular dependency' errors
      in the service's DI).
    */
    super(pouchDbService, logService);
  }

  public search(term: string): Observable<CustomerSearchResult[]> {
    let observable: Observable<CustomerSearchResult[]>;

    if (term.length > 0) {
      term = term.trim().toUpperCase();

      const queryParams: QueryParams = new QueryParams('customers/search',
                                                       [term, ''],
                                                       [term + HIGHEST_CHARACTER, HIGHEST_CHARACTER]);
      queryParams.includeDocs = false;

      observable = this.getWithQuery(queryParams);
    } else {
      observable = of([]);
    }

    return observable;
  }

  protected getInstanceFromRow(row: any): CustomerSearchResult {
    const nameParts: string[] = row.value.split('|');
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const name: Name = new Name(nameParts[0], nameParts[1], nameParts[2]);

    const customerSearchResult: CustomerSearchResult = {
      id: row.id,
      name
    };

    return customerSearchResult;
  }
}
