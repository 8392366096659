import { Injectable } from '@angular/core';
import { concatMap, map, Subject } from 'rxjs';

import { Logger, LogService } from '../core/logging';
import { base32guid, IdPrefix, IUserSettings, OrderType, UserSettings } from '../core/model';
import { Notification } from '../enums';
import { checkIfNotFound } from '../utility';
import { PouchDbService } from './pouchdb.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  // eslint-disable-next-line @typescript-eslint/ban-types -- type is defined in PouchDB library
  private _database!: PouchDB.Database<{}>;
  private _userSettingsId: string = '';
  private _userSettings!: UserSettings;
  private _userSettingsUpdates: Subject<IUserSettings> = new Subject<IUserSettings>();
  private readonly _log: Logger;

  constructor(private _pouchDbService: PouchDbService,
              logService: LogService) {
    this._log = logService.getLogger('UserSettingsService');

    this._userSettingsUpdates.pipe(map(updatedSettings => {
                                    /* Update the settings so the new value is visible immediately */
                                    this._userSettings = { ...this._userSettings,
                                                           ...updatedSettings
                                                         };
                                    return updatedSettings;
                                   }),
                                   concatMap(updatedSettings => {
                                    return this.updateSettings(updatedSettings);
                                  }))
                             .subscribe();
  }

  public async initialize(userIdToken: base32guid): Promise<void> {
    this._database = this._pouchDbService.Database;
    this._userSettingsId = IdPrefix.UserSettings + userIdToken;
    this._userSettings = await this.getSettings();
  }

  public getFirstName(): string {
    return this._userSettings.firstName;
  }
  public setFirstName(name: string): void {
    this._userSettingsUpdates.next({
      firstName: name
   });
  }

  public getLastName(): string {
    return this._userSettings.lastName;
  }
  public setLastName(name: string): void {
    this._userSettingsUpdates.next({
      lastName: name
   });
  }

  public getEmail(): string {
    return this._userSettings.email;
  }
  public setEmail(email: string): void {
    this._userSettingsUpdates.next({
      email
   });
  }

  public updateProfile(firstName: string, lastName: string, email: string): void {
    this._userSettingsUpdates.next({
      firstName,
      lastName,
      email
    });
  }

/* eslint-disable no-bitwise -- we know what we're doing here ;-) */
  /** Sets the flag for a notification indicating that it has been dismissed (and should not
   * be displayed again).  Note that it is presumed that there is no requirement for unsetting
   * a particular flag.
   */
  public getNotificationFlag(notification: Notification): boolean {
    const flag: number =  1 << (notification - 1);
    return (this._userSettings.notificationFlags & flag) === flag;
  }
  public setNotificationFlag(notification: Notification): void {
    const flag: number =  1 << (notification - 1);
    const notificationFlags: number = (this._userSettings.notificationFlags & ~flag) | flag;
    this._userSettingsUpdates.next({
                                     notificationFlags
                                  });
  }
/* eslint-enable no-bitwise */

  public getOrderMessage(orderType: OrderType): string {
    switch (orderType) {
      case OrderType.Quote:
        return this._userSettings.quoteMessage;

      case OrderType.Invoice:
      default:
        if (orderType !== OrderType.Invoice) {
          this._log.error(`Unexpected OrderType enum value - ${orderType}`);
        }

        return this._userSettings.invoiceMessage;
    }
  }
  public setOrderMessage(orderType: OrderType, message: string): void {
    if (this.getOrderMessage(orderType) !== message) {
      let settings: IUserSettings;

      switch (orderType) {
        case OrderType.Quote:
          settings = { quoteMessage: message };
          break;

        case OrderType.Invoice:
        default:
          if (orderType !== OrderType.Invoice) {
            this._log.error(`Unexpected OrderType enum value - ${orderType}`);
          }

          settings = { invoiceMessage: message };
          break;
      }

      this._userSettingsUpdates.next(settings);
    }
  }

  public getLastPreviewOrderType(): OrderType {
    return this._userSettings.lastPreviewOrderType;
  }
  public setLastPreviewOrderType(orderType: OrderType): void {
    if (this.getLastPreviewOrderType() !== orderType) {
      const settings: IUserSettings = { lastPreviewOrderType: orderType };
      this._userSettingsUpdates.next(settings);
    }
  }

  private getSettings(): Promise<UserSettings> {
    return this._database.get<UserSettings>(this._userSettingsId)
                         .then(doc => {
                            return this.getInstanceFromDoc(doc);
                          }, (error: any) => {
                            if (checkIfNotFound(error)) {
                              /* Can ignore since settings are only saved when updated */
                              this._log.debug(`User settings not yet available for ${this._userSettingsId}`);
                            } else {
                              this._log.error(`Unable to retrieve user settings for ${this._userSettingsId}`, error);
                            }

                            return { ...new UserSettings(),
                                     idToken: this._userSettingsId.replace(IdPrefix.UserSettings, ''),
                                     _id: this._userSettingsId
                                   };
                          });
  }

  private updateSettings(_updatedSettings: IUserSettings): Promise<void> {
    /* We're using the instance variable, rather than being passed in the updated version
      as this avoids the risk of using an out-of-date copy with the wrong _rev if updates
      are made in quick succession.  (It does mean we might be making redundant PUT calls
      if successive updates have all been applied to that while we were waiting for a
      previous PUT call to return.)

      We're also passing in the actual update being applied; currently, it's unused but we
      might need to reapply it if we find that we're getting 409 conflicts.
    */
    return this._database.put(this._userSettings)
                         .then((response: PouchDB.Core.Response) => {
                            this._userSettings = { ...this._userSettings,
                                                   _rev: response.rev
                                                 };
                          }, (error: any) => {
                            this._log.error(error);
                          });
  }

  private getInstanceFromDoc(doc: any): UserSettings {
    const isUserSettings: boolean = (doc instanceof UserSettings);
    const userSettings: UserSettings = isUserSettings ? doc as UserSettings
                                                      : new UserSettings(doc);

    return userSettings;
  }
}
