import { CouchDbEntity } from './couchdb-entity';
import { base32guid } from './types';

enum MaxStringLengths {
  Description = 2000
}

export class OrderItem extends CouchDbEntity {
  public static readonly MaxStringLengths = MaxStringLengths;

  public readonly orderId: base32guid = '';
  public readonly itemNumber: number = 0;
  public readonly description: string = '';
  public readonly quantity: number = 0;
  public readonly units: string = '';
  public readonly unitAmount: number = 0;
  public readonly netAmount: number = 0;
  public readonly taxAmount: number = 0;
  public readonly taxRate: number = 0;
  public readonly grossAmount: number = 0;

  constructor(orderItem?: OrderItem) {
    super(orderItem);

    if (orderItem) {
      this.orderId = orderItem.orderId;
      this.itemNumber = orderItem.itemNumber;
      this.description = orderItem.description;
      this.quantity = orderItem.quantity;
      this.units = orderItem.units;
      this.unitAmount = orderItem.unitAmount;
      this.netAmount = orderItem.netAmount;
      this.taxAmount = orderItem.taxAmount;
      this.taxRate = orderItem.taxRate;
      this.grossAmount = orderItem.grossAmount;
    }
  }
}
