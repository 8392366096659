import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Data, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { NotificationService, SettingsService } from '../services';
import { redirectUnauthorizedToLogin } from './auth-pipe';
import { AuthGuard } from './auth.guard';
import { LogService } from './logging';
import { OrderType } from './model';

// eslint-disable-next-line @typescript-eslint/typedef, @typescript-eslint/explicit-function-return-type
export const canActivateIfUnexpired = (data: Data = {}) => ({
  canActivate: [ AccountExpiryGuard ],
  data: {
    authGuardPipe: redirectUnauthorizedToLogin,
    ...data
  }
});

@Injectable({
  providedIn: 'any'
})
export class AccountExpiryGuard implements CanActivate {
  private _authGuard: AuthGuard;

  constructor(private _notificationService: NotificationService,
              private _settingsService: SettingsService,
              router: Router,
              auth: Auth,
              logService: LogService) {
    this._authGuard = new AuthGuard(_settingsService, router, auth, logService);
  }

  public canActivate = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
    return this._authGuard.canActivate(next, state)
                          .pipe(map((can: boolean | UrlTree) => {
                                  if (typeof(can) === 'boolean' && this._settingsService.settingsInitialized) {
                                    const message: string = next.data.orderType === OrderType.Invoice
                                                              ? 'Your account has expired.  If you want to add a new invoice, please [renew your subscription]({0}).'
                                                              : 'Your account has expired.  If you want to add a new quote, please [renew your subscription]({0}).';

                                    if (this._notificationService.showIfAccountExpired(message)) {
                                      can = false;
                                    }
                                  }

                                  return can;
                                }));
  };
}
