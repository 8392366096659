import { Injectable, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { Logger, LogService } from '../core/logging';
import { EMPTY_SETTINGS, Settings } from '../core/model';
import { SettingsService } from './settings.service';

@Injectable()
export abstract class BaseService implements OnDestroy {
  protected readonly log: Logger;
  protected currentSettings: Settings = EMPTY_SETTINGS;
  protected isBeingDestroyed$: Subject<void> = new Subject();

  constructor(settingsService: SettingsService,
              logService: LogService) {
    this.log = logService.getLogger(this.constructor.name);

    /* We'll use the observable since services are singletons so will need to keep
      track of changes without having to keep refetching the settings.
    */
    settingsService.currentSettings$
                   .pipe(takeUntil(this.isBeingDestroyed$))
                   .subscribe((settings: Settings) => {
                      this.currentSettings = settings;
                    });
  }

  public ngOnDestroy(): void {
    this.isBeingDestroyed$.next();
    this.isBeingDestroyed$.complete();
  }
}
