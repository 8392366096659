<wky-header [heading]="this.pageTitle"></wky-header>
<ion-content class="fill">
	<section class="filter-bar">
		<label>Filter</label>
		<div>
			<ion-chip role="switch" [attr.aria-checked]="getStateFilterStatus(stateFilterEnum.Draft)" color="secondary" [outline]="getStateFilterStatus(stateFilterEnum.Draft)" (click)="onClickState(stateFilterEnum.Draft)"><ion-label>Draft</ion-label></ion-chip>
			<ion-chip role="switch" [attr.aria-checked]="getStateFilterStatus(stateFilterEnum.Open)" color="secondary" [outline]="getStateFilterStatus(stateFilterEnum.Open)" (click)="onClickState(stateFilterEnum.Open)"><ion-label>Issued</ion-label></ion-chip>
			<ion-chip role="switch" [attr.aria-checked]="getStateFilterStatus(stateFilterEnum.Paid)" color="secondary" [outline]="getStateFilterStatus(stateFilterEnum.Paid)" (click)="onClickState(stateFilterEnum.Paid)" *ngIf="this.showingInvoices"><ion-label>Paid</ion-label></ion-chip>
			<ion-chip role="switch" [attr.aria-checked]="getStateFilterStatus(stateFilterEnum.Archived)" color="secondary" [outline]="getStateFilterStatus(stateFilterEnum.Archived)" (click)="onClickState(stateFilterEnum.Archived)"><ion-label>Archived</ion-label></ion-chip>
		</div>
	</section>
	<ion-list>
		<ion-item *ngFor="let order of this.orders; trackBy: getOrderId" button="true" detail="true" (click)="goToOrder(order.idToken)" [ngClass]="{'draft': this.isDraft(order)}">
			<ion-label>
				{{ order.customerName }}<div *ngIf="this.isOverdue(order)" class="overdue">Overdue</div><div *ngIf="order.archived" class="archived">Archived</div>
				<address>{{ this.formatAddress(order) }}</address>
			</ion-label>
			<ion-note slot="end">
				<div class="date-raised">{{ this.getDateRaised(order) }}</div>
				<div class="total">{{ order.grossTotal | currency:order.currencyCode:'symbol-narrow' }}</div>
			</ion-note>
		</ion-item>
	</ion-list>
	<div *ngIf="this.orders.length === 0" class="empty-state">
		{{ this.emptyStateText }}
	</div>
	<ion-infinite-scroll threshold="100px" (ionInfinite)="onInfiniteScroll($event)">
		<ion-infinite-scroll-content loadingSpinner="dots" loadingText="Loading more data...">
		</ion-infinite-scroll-content>
	</ion-infinite-scroll>
	<ion-fab vertical="bottom" horizontal="end" slot="fixed">
		<ion-fab-button (click)="goToOrder()" router-direction="forward">
		  <ion-icon name="add"></ion-icon>
		</ion-fab-button>
	 </ion-fab>
</ion-content>
