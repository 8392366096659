import { base32guid } from './types';

export function getCouchDbEntityId(entity: CouchDbEntity): string {
  return entity._id;
}

export abstract class CouchDbEntity {
  public readonly idToken: base32guid = '';   /* The 'real' id of the entity (a base32 guid) for 'public consumption' in links, etc */
  public readonly _id: string = '';           /* A CouchDB-specific id designed to support searching, etc */
  public readonly _rev?: string;
  public readonly _deleted?: boolean;
  public readonly $doctype: string = '';
  public readonly $docver: string = '';

  constructor(entity?: CouchDbEntity) {
    if (entity) {
      this.idToken = entity.idToken;
      this._id = entity._id;
      this._rev = entity._rev;
      this._deleted = entity._deleted;
      this.$doctype = entity.$doctype;
      this.$docver = entity.$docver;
    }

    if (!this.$doctype || this.$doctype.length === 0) {
      const doctype: string = this.constructor.name;
      this.$doctype = doctype.slice(0, 1).toLowerCase() + doctype.slice(1);
      this.$docver = '1.0.0';
    }
  }
}
