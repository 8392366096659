import { InjectionToken } from '@angular/core';

import { AuthConfig } from './auth-config';
import { LoggingConfig } from './logging-config';

export const appConfigToken: InjectionToken<AppConfig> = new InjectionToken<AppConfig>('AppConfig');

export interface AppConfig {
  schemaVersion: number;
  webUri: string;
  termsUrl: string;
  trialPeriodInDays: number;
  workeryApiServiceBaseUri: string;
  dataUris: string[];
  auth: AuthConfig;
  logging: LoggingConfig;
}
