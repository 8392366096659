import { HttpStatusCode } from '@angular/common/http';

export function checkIfNotFound(error: any): boolean {
  let notFound: boolean = false;

// TODO: TASK - are there any other error types that might signify a 404???
  if (error && error.status) {
    notFound = (error.status === HttpStatusCode.NotFound);
  }

  return notFound;
}
