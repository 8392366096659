import { Injectable, OnDestroy } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import PouchDB from 'pouchdb';

import { environment } from '../../environments/environment';
import { Logger, LogService } from '../core/logging';

const INITIAL_REPLICATION_INITIALIZATION_DELAY_MS: number = 60_000;
const STD_REPLICATION_INITIALIZATION_DELAY_MS: number = 300_000;

@Injectable({
  providedIn: 'root'
})
export class PouchDbService implements OnDestroy {
/* eslint-disable @typescript-eslint/ban-types -- use of {} to match PouchDB API */
  private readonly _log: Logger;
  private _localDb?: PouchDB.Database;
  private _remoteDb?: PouchDB.Database;
  private _syncHandler?: PouchDB.Replication.Sync<any>;
  private _changeHandler?: PouchDB.Core.Changes<{}>;
  private _idForChanges: string = '';
  private _logReplicationErrors: boolean = true;
  private _initialReplicationDelays: number[] = [INITIAL_REPLICATION_INITIALIZATION_DELAY_MS,
                                                 INITIAL_REPLICATION_INITIALIZATION_DELAY_MS,
                                                 INITIAL_REPLICATION_INITIALIZATION_DELAY_MS,
                                                 INITIAL_REPLICATION_INITIALIZATION_DELAY_MS,
                                                 INITIAL_REPLICATION_INITIALIZATION_DELAY_MS
                                                ];

  constructor(logService: LogService) {
    this._log = logService.getLogger('PouchDbService');
  }

  public ngOnDestroy(): void {
    this.shutdown();
  }

  public get Database(): PouchDB.Database {
    const database: PouchDB.Database | undefined = this._localDb ?? this._remoteDb;

    if (!database) {
      throw new Error('PouchDbService has not been initialized (has the user been authenticated?)');
    }

    return database;
  }

  public get initialized(): boolean {
    return !!(this._localDb ?? this._remoteDb);
  }

  public initialize(serverUri: string, database: string, username: string, password: string,
                    callback: (initialized: boolean) => any): void {
    const localDbName: string = this._localDb?.name ?? '';
    const remoteDbName: string = this._remoteDb?.name ?? '';
    const remoteDbAddress: URL = new URL(serverUri);
    remoteDbAddress.pathname = database;

    if (   database !== localDbName
        || remoteDbAddress.href !== remoteDbName) {
      const storeDataLocally: boolean = this.shouldStoreDataLocally();

      if (storeDataLocally) {
// TODO: TASK - consider specifying DB size - see https://pouchdb.com/errors.html#not_enough_space
        this._localDb = new PouchDB(database, {auto_compaction: true});
        this._log.info('Database is being cached locally');
      } else {
        this._localDb = undefined;
        this._log.info('Database is NOT being cached locally');
      }

      this._remoteDb = new PouchDB(remoteDbAddress.href,
                                   {
                                    auth: {
                                      username,
                                      password
                                    },
                                    skip_setup: true
                                   });
      this._log.debug('Service initialized');

      if (storeDataLocally) {
        this.initializeReplication(callback);
      } else if (callback) {
        callback(true);
      }
    } else {
      this._log.debug('Service already initialized');
    }
  }

  private shouldStoreDataLocally(): boolean {
    /* We shouldn't hold a local copy of the data if the user is running the app directly in a
      browser, since if it's a shared machine, the data will be available to all.
    */
// TODO: what about if it's being run as an installed PWA (or it's the user's own computer)?
// In that case, it's okay, but we need a robust way to identify status as PWA and/or possibly
// prompt the user about storing the data.  Maybe something like this for the PWA check?
//    return !environment.production
//         || Capacitor.isNativePlatform()
//         || window.matchMedia('(display-mode: standalone)').matches;

    return !environment.production
         || Capacitor.isNativePlatform();
  }

  private initializeReplication(callback?: ((initialized: boolean) => any)): void {
    /* The optimum way (according to the docs - see https://pouchdb.com/api.html#sync) to start
      syncing between two databases is to do an initial replication to get all of the remote
      changes into the local DB, and then set up the continuous syncing between the two.
    */
    if (this._localDb && this._remoteDb) {
      /* Note that we could use the 'retry' option for the initial replication but that doesn't
        raise any events until it eventually completes (or fatally errors?), so we would have no
        opportunity to call our callback until then, causing the app to appear to hang.  So we'll
        have to handle the retrying ourselves.

        HACK ALERT!  Each call to Database.replicate.from() causes a 'destroyed' event listener
        to be attached to each of the databases.  After sufficient retries, we then end up with
        an error about hitting the max no. of listeners (see https://pouchdb.com/errors.html#event_emitter_limit).
        Ideally, we'd identify exactly which listener(s) have been added, so they can be explicitly
        removed.  For now though, we'll just remove all existing 'destroyed' listeners, on the
        assumption that nothing else appears to be adding them.
      */
      if (typeof(callback) === 'undefined') {   /* Then this call is part of a retry */
        this._localDb.removeAllListeners('destroyed');
        this._remoteDb.removeAllListeners('destroyed');
      }

// TODO: filter on non-deleted documents
      this._localDb.replicate.from(this._remoteDb, { filter: undefined })
                             .on('complete', (info) => {
                                this._log.info('Replication complete', info);
                                this._logReplicationErrors = true;
                                this.getStats()
                                    .then(statsArray => {
                                      statsArray.forEach((stats, index) => {
                                        const db: string = ['local', 'remote'][index];
                                        this._log.debug(`Initial replication stats - ${db}: `, stats);
                                      });
                                    });

                                if (callback) {
                                  /* It's entirely possible that, while the database has been replicating,
                                    the user has logged out, so for convenience, we'll pass back the current
                                    state.
                                  */
                                  callback(this.initialized);
                                }

                                if (this._localDb && this._remoteDb) {  /* Again, in case the user's already logged out */
                                  const options: PouchDB.Replication.SyncOptions = {
                                    live: true,
                                    retry: true
                                  };

                                  this.cancelSyncHandler();
                                  this._syncHandler = this._localDb.sync(this._remoteDb, options)
                                                                   .on('paused', this.onSyncPaused)
                                                                   .on('active', this.onSyncActive)
                                                                   .on('denied', this.onSyncDenied)
                                                                   .on('error', this.onSyncError);
                                  this._log.debug('PouchDB synchronization handler registered');
                                }
                              })
                             .on('paused', this.onReplicationPaused)
                             .on('active', this.onReplicationActive)
                             .on('denied', (err: {}) => {
                                this.onReplicationDenied(err);
                                if (callback) {
                                  /* Replication should never be denied, so that's a serious problem */
                                  callback(false);
                                }
                              })
                             .on('error', (err: {}) => {
                                this.onReplicationError(err);
                                if (callback) {
                                  /* An error in setting up the replication shouldn't prevent the user
                                    from continuing (since the data is held locally anyway).  So we'll
                                    return true while we keep retrying the replication in the background.
                                  */
                                  callback(true);
                                }
                              });
    }
  }

  public shutdown(): void {
    this._log.debug('PouchDB shutting down');
    this.unregisterForChanges();
    this.cancelSyncHandler();
    this._localDb = undefined;
    this._remoteDb = undefined;
  }

  public getStats(): Promise<(PouchDB.Core.DatabaseInfo | undefined)[]> {
    const promises: (Promise<PouchDB.Core.DatabaseInfo> | undefined)[] = [];

    if (this._localDb) {
      promises.push(this._localDb.info());
    } else {
      promises.push(undefined);
    }

    if (this._remoteDb) {
      promises.push(this._remoteDb.info());
    } else {
      promises.push(undefined);
    }

// TODO: would be better using allSettled() - es2020 only?
    return Promise.all(promises);
  }

  /** Registers a 'change' event handler on the database for the user's account, so that any external
   * changes made - say, via the main website or another device - will be identified and cause the
   * user's settings, etc, to be updated dynamically.
   */
  public registerForChanges(id: string, listener: (value: PouchDB.Core.ChangesResponseChange<{}>) => any): void {
    if (!this._changeHandler || id !== this._idForChanges) {
      this.unregisterForChanges();

      if (this._localDb) {
        const options: PouchDB.Core.ChangesOptions = {
          doc_ids: [id],
          include_docs: true,
          live: true,
          since: 'now'
        };

        this._changeHandler = this._localDb.changes(options)
                                           .on('change', listener);
        this._idForChanges = id;
        this._log.debug('PouchDB changes event listener registered');
      }
    } else {
      this._log.debug('PouchDB changes event listener already registered');
    }
  }

  private unregisterForChanges(): void {
    if (this._changeHandler) {
      this._log.debug('Unregistering PouchDB changes event listener');
      const changeHandler: PouchDB.Core.Changes<{}> = this._changeHandler;

      changeHandler.on('complete', (_value: PouchDB.Core.ChangesResponse<{}>): any => {
        this._log.debug('...unregistering PouchDB changes event listener => complete');
      });
      changeHandler.cancel();

      this._changeHandler = undefined;
      this._idForChanges = '';
    }
  }

  private cancelSyncHandler = (): void => {
    if (this._syncHandler) {
      this._log.debug('Unregistering PouchDB synchronization handler...');
      const syncHandler: PouchDB.Replication.Sync<any> = this._syncHandler;

      syncHandler.on('complete', (_info: PouchDB.Replication.SyncResultComplete<{}>): any => {
        this._log.debug('...unregistering PouchDB synchronization handler => complete');
      });
      syncHandler.cancel();

      this._syncHandler = undefined;
    }
  };

  // private onSyncChange(info: PouchDB.Replication.ReplicationResult<{}>): any {
  //   // handle change
  // }

  // private onSyncComplete(info: PouchDB.Replication.ReplicationResultComplete<{}>): any {
  // // handle complete
  // }

  private onReplicationPaused = (_err: {}): any => {
    // Replication paused (e.g. replication up to date, user went offline)
    this._log.debug('PouchDB.replicate() => paused');
    this._logReplicationErrors = true;
  };

  private onReplicationActive = (): any => {
    // Replication resumed (e.g. new changes replicating, user went back online)
    this._log.debug('PouchDB.replicate() => active');
    this._logReplicationErrors = true;
  };

  private onReplicationDenied = (err: {}): any => {
    // A document failed to replicate (e.g. due to permissions)
    this._log.fatal('PouchDB.replicate() => denied', err);
  };

  private onReplicationError = (err: {}): any => {
    if (this._logReplicationErrors) {
      this._log.warn('PouchDB.replicate() => error', err);
      this._logReplicationErrors = false;
    }

    const delay: number = this._initialReplicationDelays.pop() ?? STD_REPLICATION_INITIALIZATION_DELAY_MS;

    setTimeout(() => {
      this._log.debug('Retrying replication initialization...');
      this.initializeReplication();
    }, delay);
  };

  private onSyncPaused = (_err: {}): any => {
    // Replication paused (e.g. replication up to date, user went offline)
    this._log.debug('PouchDB.sync() => paused');
    this._logReplicationErrors = true;
  };

  private onSyncActive = (): any => {
    // Replication resumed (e.g. new changes replicating, user went back online)
    this._log.debug('PouchDB.sync() => active');
    this._logReplicationErrors = true;
  };

  private onSyncDenied = (err: {}): any => {
    // A document failed to replicate (e.g. due to permissions)
    this._log.error('PouchDB.sync() => denied', err);
  };

  private onSyncError = (err: {}): any => {
    if (this._logReplicationErrors) {
      this._log.error('PouchDB.sync() => error', err);
      this._logReplicationErrors = false;
    }
  };
/* eslint-enable @typescript-eslint/ban-types */
}
