import { BASE16_RADIX } from '../constants';

export class Colour {
  constructor(public red: number,
              public green: number,
              public blue: number) {}

  public static fromString(colour: string): Colour {
    const value: number = Colour.getValueFromColour(colour);
    return new Colour(...Colour.splitColourIntoRgb(value));
  }

  /** This formula is taken from Stack Overflow (https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-colour-or-rgb-and-blend-colours#13542669)
   * using version 2 of the answer.
   *
   * @param colour #-prefixed hex string
   */
  public static shade(hexColour: string, percent: number): string {
    const colour: Colour = Colour.fromString(hexColour);
    return colour.shade(percent);
  }

  public static blend(fromColour: string, toColour: string, percent: number): string {
    const colour: Colour = Colour.fromString(fromColour);
    return colour.blend(toColour, percent);
  }

  /** This formula is taken from Stack Overflow (https://stackoverflow.com/a/596243) option 3
   *
   * @param colour #-prefixed hex string
   */
  public static getBrightnessFromRgb(hexColour: string): number {
    const colour: Colour = Colour.fromString(hexColour);
    return colour.brightness;
  }

  public static getValueFromColour(colour: string): number {
    return Number.parseInt(colour.slice(1), BASE16_RADIX);
  }

/* eslint-disable @typescript-eslint/no-magic-numbers */

  public static splitColourIntoRgb(colour: number): [ red: number, green: number, blue: number] {
    /* eslint-disable no-bitwise */
    return [
      colour >> 16,
      colour >> 8 & 0xFF,
      colour      & 0xFF
    ];
    /* eslint-enable no-bitwise */
  }

  public shade(percent: number): string {
    const t: number = percent < 0 ? 0 : 255;
    const p: number = percent < 0 ? percent * -1 : percent;

    const shade: number = 0x1000000
                        +   0x10000 * (Math.round((t - this.red)   * p) + this.red)
                        +     0x100 * (Math.round((t - this.green) * p) + this.green)
                        +             (Math.round((t - this.blue)  * p) + this.blue);
    return '#' + shade.toString(BASE16_RADIX)
                      .slice(1);
  }

  public blend(toColour: string, percent: number): string {
    const to: number = Colour.getValueFromColour(toColour);
    const [toRed, toGreen, toBlue] = Colour.splitColourIntoRgb(to);

    const blend: number = 0x1000000
                        +   0x10000 * (Math.round((toRed - this.red)     * percent) + this.red)
                        +     0x100 * (Math.round((toGreen - this.green) * percent) + this.green)
                        +             (Math.round((toBlue - this.blue)   * percent) + this.blue);
    return '#' + blend.toString(BASE16_RADIX)
                      .slice(1);
  }

  public get brightness(): number {
    return Math.sqrt((0.299 * this.red * this.red)
                   + (0.587 * this.green * this.green)
                   + (0.114 * this.blue * this.blue));
  }

  /* eslint-enable @typescript-eslint/no-magic-numbers */
};
