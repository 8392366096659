const THEME_SETTING_SEPARATOR: string = '.';

export class OrderTheme {
  constructor(public name: string,
              public colour: string,
              public altColour: string) {}

  public static fromSettings(name: string, colour: string, altColour: string): string {
    const themeSetting: string[] = [name,
                                    colour.replace('#', '').toLowerCase(),
                                    altColour.replace('#', '').toLowerCase()
                                   ];

    return themeSetting.join(THEME_SETTING_SEPARATOR);
  }

  public static fromString(themeSettings: string): OrderTheme {
    const [name, colour, altColour] = themeSettings.split(THEME_SETTING_SEPARATOR);
    return new OrderTheme(name, '#' + colour.toUpperCase(), '#' + altColour.toUpperCase());
  }

  public static toString(theme: OrderTheme): string {
    return OrderTheme.fromSettings(theme.name, theme.colour, theme.altColour);
  }
}
