import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AlertInput } from '@ionic/core';

import { INPUT_TYPE_MODES } from '../utility';
import { ToastService } from './toast.service';

// TODO: TASK - review input validation when https://github.com/ionic-team/ionic-framework/issues/19059 is fixed

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private _alertController: AlertController,
              private _toastService: ToastService) {}

  public async editTextValue(header: string,
                             message: string,
                             initialValue: string | undefined,
                             handler: ((value: any) => boolean | { [key: string]: any }),
                             required: boolean = false,
                             requiredMessage: string = ''): Promise<void> {
    const input: AlertInput = {
      name: 'inputName',
      type: 'text',
      value: initialValue
    };

    return this.editInputValue(header, message, input, handler, required, requiredMessage);
  }

  public async editNumericValue(header: string,
                                message: string,
                                initialValue: number | undefined,
                                min: number,
                                max: number,
                                handler: ((value: any) => boolean | { [key: string]: any }),
                                required: boolean = false,
                                requiredMessage: string = ''): Promise<void> {
    const input: AlertInput = {
      name: 'inputName',
      type: 'number',
      value: initialValue,
      min,
      max,
      attributes: {
        inputmode: 'decimal',
        step: 0
      }
    };

    return this.editInputValue(header, message, input, handler, required, requiredMessage);
  }

  public async editInputValue(header: string,
                              message: string,
                              input: AlertInput,
                              handler: ((value: any) => boolean | { [key: string]: any }),
                              required: boolean = false,
                              requiredMessage: string = ''): Promise<void> {
    this.setInputMode(input);

    const alert: HTMLIonAlertElement = await this._alertController.create({
      header,
      subHeader: required ? '(Required)' : undefined,
      message: message !== '' ? message : undefined,
      inputs: [input],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'OK',
          handler: (data): boolean | { [key: string]: any } => {
            let valid: boolean | { [key: string]: any } = false;

            if (!required || data.inputName.length > 0) {
              valid = handler(data.inputName);

              if (typeof valid === 'object') {
// TODO: TASK - do something with validation error
                valid = false;
              }
            } else {
              this._toastService.showError(requiredMessage);
            }

            return valid;
          }
        }
      ]
    });

    await alert.present();
  }

  public async editInputValues(header: string,
                               subHeader: string,
                               message: string,
                               inputs: AlertInput[],
                               handler: ((values: any) => boolean | void | { [key: string]: any })): Promise<void> {
    for (const input of inputs) {
      this.setInputMode(input);
    }

    const alert: HTMLIonAlertElement = await this._alertController.create({
      header,
      subHeader: subHeader !== '' ? subHeader : undefined,
      message: message !== '' ? message : undefined,
      inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'OK',
          handler
        }
      ]
    });

    await alert.present();
  }

  private setInputMode(input: AlertInput): void {
    if (input.type) {
      const inputmode: string | undefined = INPUT_TYPE_MODES.get(input.type);

      if (inputmode && inputmode.length > 0) {
        if (input.attributes) {
          const attributes: { [key: string]: any } = input.attributes;
          if (typeof(attributes.inputmode) === 'undefined') {
            input.attributes = {
              ...input.attributes,
              inputmode
            };
          }
        } else {
          input.attributes = {
            inputmode
          };
        }
      }
    }
  }
}
