import { IHasAddress } from './address';
import { CouchDbEntity } from './couchdb-entity';
import { Name } from './name';
import { base32guid } from './types';
import { UserProfile } from './user-profile';

enum MaxStringLengths {
  Title = 30,
  FirstName = UserProfile.MaxStringLengths.FirstName,
  LastName = UserProfile.MaxStringLengths.LastName,
  FullName = Title + 1 + FirstName + 1 + LastName,
  PhoneNumber = 50,
  Email = UserProfile.MaxStringLengths.Email
}

export interface IHasCustomer extends IHasAddress {
  customerId: string;
  customerName: string;
}

export class Customer extends CouchDbEntity implements IHasAddress {
  public static readonly MaxStringLengths = MaxStringLengths;

  public readonly accountId: base32guid = '';
  public readonly title: string = '';
  public readonly firstName: string = '';
  public readonly lastName: string = '';
  public readonly streetAddress: string = '';
  public readonly city: string = '';
  public readonly state: string = '';
  public readonly postcode: string = '';
  public readonly countryCode: string = '';
  public readonly country: string = '';
  public readonly phoneNumbers: string[] = [''];
  public readonly email: string = '';

  constructor(customer?: Customer) {
    super(customer);

    if (customer) {
      this.accountId = customer.accountId;
      this.title = customer.title;
      this.firstName = customer.firstName;
      this.lastName = customer.lastName;
      this.streetAddress = customer.streetAddress;
      this.city = customer.city;
      this.state = customer.state;
      this.postcode = customer.postcode;
      this.countryCode = customer.countryCode;
      this.country = customer.country;
      this.phoneNumbers = customer.phoneNumbers?.slice(0) ?? [''];
      this.email = customer.email;
      // this.dateCreated = getDate(customer.dateCreated);
    }
  }

  public static getName(customer: Customer): Name {
    return new Name(customer.title,
                    customer.firstName,
                    customer.lastName);
  }
}
