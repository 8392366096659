import { Observable } from 'rxjs';

import { QueryParams } from '../core';
import { Logger, LogService } from '../core/logging';
import { PouchDbService } from './pouchdb.service';

// TODO: TASK - handle 404s (as they're expected by PouchDB when it checks for remote endpoints)

/** Note that directly derived classes should provide a constructor, even if this does nothing more
 * than call the base class's constructor (and is flagged by the linter as being 'useless').  If not,
 * the call to `this.constructor.name` in the constructor here will fail.  Bizarrely, this then leads
 * to 'circular dependency' errors in the derived service's DI).
 */
export abstract class PouchDbDataService<T> {
/* eslint-disable @typescript-eslint/ban-types -- use of {} to match PouchDB API */
  protected readonly log: Logger;

  constructor(protected pouchDbService: PouchDbService,
              logService: LogService) {
    this.log = logService.getLogger(this.constructor.name);
  }

  protected abstract getInstanceFromRow(row: any): T;

  protected getWithQuery(queryParams: QueryParams): Observable<T[]> {
    const queryOptions: PouchDB.Query.Options<{}, any> = this.convertToQueryOptions(queryParams);

    return new Observable<T[]>(observer => {
      this.pouchDbService.Database.query<T>(queryParams.query, queryOptions)
                                  .then((response: PouchDB.Core.AllDocsResponse<T>) => {
                                    const docs: T[] = response.rows.map((row: any) => {
                                      return this.getInstanceFromRow(row);
                                    });

                                    observer.next(docs);
                                    observer.complete();
                                  }, (error: any) => {
                                    observer.error(error);
                                    observer.complete();
                                  });
    });
  }

  /**
   * This method differs from the `getWithQuery()` method in that it returns an array of the 'raw' documents
   * from the database.  This allows documents representing different classes to be returned in a single query.
   */
  protected getMultiWithQuery(queryParams: QueryParams): Observable<any[]> {
    if (queryParams.includeDocs === false) {
      this.log.warn('`queryParams.include_docs` has been passed as false, but will be ignored');
    }

    const queryOptions: PouchDB.Query.Options<{}, any> = this.convertToQueryOptions({ ...queryParams,
                                                                                      includeDocs: true
                                                                                    });

    return new Observable<any[]>(observer => {
      this.pouchDbService.Database.query(queryParams.query, queryOptions)
                                  .then((response: PouchDB.Core.AllDocsResponse<any>) => {
                                    const docs: any[] = response.rows.map((row: any) => {
                                      return row.doc;
                                    });

                                    observer.next(docs);
                                    observer.complete();
                                  }, (error: any) => {
                                    observer.error(error);
                                    observer.complete();
                                  });
    });
  }

  /**
   * This method differs from the `getWithQuery()` method in that it returns an array of the 'raw' rows
   * from the database.  Each row will include the corresponding document according to the `QueryParams.includeDocs`
   * value.
   *
   * The returned observable will automatically complete after the first value is emitted.
   */
   protected getRowsWithQuery(queryParams: QueryParams): Observable<any[]> {
    const queryOptions: PouchDB.Query.Options<{}, any> = this.convertToQueryOptions(queryParams);

    return new Observable<any[]>(observer => {
      this.pouchDbService.Database.query(queryParams.query, queryOptions)
                                  .then((response: PouchDB.Core.AllDocsResponse<any>) => {
                                    observer.next(response.rows);
                                    observer.complete();
                                  }, (error: any) => {
                                    observer.error(error);
                                    observer.complete();
                                  });
    });
  }

  protected getQueryLimit(pageSize: number | undefined): number | undefined {
    let limit: number | undefined;

    if (pageSize) {
      limit = (pageSize > 1 ? pageSize + 1 : 1);
    }

    return limit;
  }

  protected isResponse(responseOrError: PouchDB.Core.Response | PouchDB.Core.Error): responseOrError is PouchDB.Core.Response {
    return (responseOrError as PouchDB.Core.Response).ok !== undefined;
  }

  private convertToQueryOptions(queryParams: QueryParams): PouchDB.Query.Options<{}, any> {
    const queryOptions: PouchDB.Query.Options<{}, any> = {
      /** Reduce function, or the string name of a built-in function: '_sum', '_count', or '_stats'. */
      reduce: queryParams.reduce,
      /** Include the document in each row in the doc field. */
      include_docs: queryParams.includeDocs,
      // /** Include conflicts in the _conflicts field of a doc. */
      // conflicts?: boolean;
      // /** Include attachment data. */
      // attachments?: boolean;
      // /** Return attachment data as Blobs/Buffers, instead of as base64-encoded strings. */
      // binary?: boolean;
      /** Get rows with keys in a certain range (inclusive/inclusive). */
      startkey: queryParams.startkey,
      /** Get rows with keys in a certain range (inclusive/inclusive). */
      endkey: queryParams.endkey,
      // /** Include rows having a key equal to the given options.endkey. */
      // inclusive_end?: boolean;
      /** Maximum number of rows to return. */
      limit: this.getQueryLimit(queryParams.pageSize),
      /** Number of rows to skip before returning (warning: poor performance on IndexedDB/LevelDB!). */
      // skip: 1,
      /** Reverse the order of the output rows. */
      descending: !queryParams.ascending
      // /** Only return rows matching this key. */
      // key?: any;
      // /** Array of keys to fetch in a single shot. */
      // keys?: any[];
      // /** True if you want the reduce function to group results by keys, rather than returning a single result. */
      // group?: boolean;
      // /**
      //  * Number of elements in a key to group by, assuming the keys are arrays.
      //  * Defaults to the full length of the array.
      //  */
      // group_level?: number;
      // /**
      //  * unspecified (default): Returns the latest results, waiting for the view to build if necessary.
      //  * 'ok': Returns results immediately, even if they’re out-of-date.
      //  * 'update_after': Returns results immediately, but kicks off a build afterwards.
      //  */
      // stale?: 'ok' | 'update_after';
    };

    return queryOptions;
  }
/* eslint-enable @typescript-eslint/ban-types */
}
