import { formatDate } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { IonInfiniteScroll } from '@ionic/angular';
import { takeUntil } from 'rxjs';

import { DUMMY_ROUTE_SEGMENT_FOR_NEW_ORDER, HIGHEST_CHARACTER, MAX_DATE, MAX_DATE_TICKS } from '../../constants';
import { QueryParams } from '../../core';
import { Logger, LogService } from '../../core/logging';
import { base32guid, IHasAddress, OrderDetails, OrderState, OrderType } from '../../core/model';
import { AddressService, LocaleService, OrderDetailsService } from '../../services';
import { PageComponent } from '../../shared';
import { getResolvedUrl } from '../../utility';

const DEFAULT_TIMEOUT_MS: number = 500;
const FILTER_QUERYSTRING_KEY: string = 'filter';

const enum KeyElement {
  /* eslint-disable @typescript-eslint/no-shadow */
  OrderType,
  OrderState,
  DateRaisedTicks,
  IsArchived,
  IdToken
  /* eslint-enable @typescript-eslint/no-shadow */
}
enum StateFilter {
  NotSet   = 0x00,
  Draft    = 0x01,
  Open     = 0x02,
  Paid     = 0x08,
  Archived = 0x10,
  // eslint-disable-next-line no-bitwise
  All      = StateFilter.Draft | StateFilter.Open | StateFilter.Paid | StateFilter.Archived
}

// TODO: FEATURE - consider item groups for 'draft', 'this month', etc...
// TODO: TASK - change content alignment to side-by-side for wide screens
// TODO: BUG - filter doesn't appear to be giving correct results

@Component({
  template: ''
})
export abstract class OrderListPage extends PageComponent {
  @ViewChild(IonInfiniteScroll) private _infiniteScroll?: IonInfiniteScroll;
  public orders: OrderDetails[] = [];
  public itemRoute: string = '';
  public stateFilter: StateFilter = StateFilter.NotSet;
  public showingInvoices: boolean = false;
  public stateFilterEnum: typeof StateFilter = StateFilter;
  public emptyStateText: string = '';
  private _queryParams: QueryParams = new QueryParams();
  private readonly _log: Logger;

  constructor(private _orderDetailsService: OrderDetailsService,
              private _addressService: AddressService,
              private _localeService: LocaleService,
              private _route: ActivatedRoute,
              private _router: Router,
              logService: LogService,
              titleService: Title) {
    super(titleService);
    this._log = logService.getLogger('OrderListPage');
  }

  protected abstract get orderType(): OrderType;

  public ionViewWillEnter(): void {
    super.ionViewWillEnter();

    this._route.queryParamMap
               .pipe(takeUntil(this.isLeavingView$))
               .subscribe({
                  next: (queryParams: ParamMap) => {
                    const filter: string = queryParams.get(FILTER_QUERYSTRING_KEY) ?? StateFilter.NotSet.toString();
                    this.stateFilter = Number.parseInt(filter, 10);

                    this.setQueryKeysFromStateFilter();
                    this.loadOrderDetails(true);
                  },
                  error: (error: any) => {
                    this._log.warn(error);
                  }
                });
  }

  private loadOrderDetails(clearList: boolean): void {
    this._orderDetailsService.getByQuery(this._queryParams)
                             .subscribe((orderDetails: OrderDetails[]) => {
                                let disableInfiniteScroll: boolean = true;

                                if (!!clearList) {
                                  this.emptyStateText = '';   /* So it doesn't get displayed when we clear the data... */
                                  this.orders.length = 0;     /* Clear any previous data */
                                }

                                if (orderDetails.length > 0) {
                                  if (orderDetails.length > this._queryParams.pageSize) {  /* Because we actually request page size + 1 */
                                    const lastOrderDetails: OrderDetails | undefined = orderDetails.pop();

                                    if (lastOrderDetails) {
                                      /* The 'DateRaisedTicks' element of the key is the date raised (or max date,
                                        if not specified) converted to ticks and then subtracted from the max date
                                        in ticks to 'invert' it.  This means that undated orders will be first and
                                        then the rest in reverse date order.
                                      */
                                      const lastDateRaised: Date = new Date(lastOrderDetails.dateRaised ?? MAX_DATE);
                                      const lastDateRaisedTicks: number = MAX_DATE_TICKS - lastDateRaised.valueOf();

                                      this._queryParams.startkey[KeyElement.OrderState] = lastOrderDetails.orderState;
                                      this._queryParams.startkey[KeyElement.DateRaisedTicks] = lastDateRaisedTicks;
                                      this._queryParams.startkey[KeyElement.IdToken] = lastOrderDetails.idToken;

                                      disableInfiniteScroll = false;
                                    }
                                  }

                                  orderDetails.map(details => this.orders.push(details));
                                }

                                /* Set this here, rather than in, say, setQueryKeysFromStateFilter() so it's not
                                  set before we know whether there are any orders or not.  Otherwise, it'll be
                                  visible for a brief time while the data is loading.
                                */
                                this.emptyStateText = this.stateFilter === StateFilter.Archived
                                                          ? (this.showingInvoices ? 'You have no archived invoices'
                                                                                  : 'You have no archived quotes')
                                                          : (this.showingInvoices ? 'You have no invoices to display'
                                                                                  : 'You have no quotes to display');

                                if (this._infiniteScroll) {
                                  this._infiniteScroll.disabled = disableInfiniteScroll;
                                }
                              });
  }

  private setQueryKeysFromStateFilter(): void {
    /* The defaults here apply when no filter has been explicitly set */
    let startState: OrderState = OrderState.NotSpecified;
    let endState: OrderState = OrderState.Raised;

    /* eslint-disable no-bitwise */
    if (this.stateFilter & StateFilter.Draft) {
      startState = OrderState.InProgress;
    } else if (this.stateFilter & StateFilter.Open) {
      startState = OrderState.Raised;
    } else if (this.stateFilter & StateFilter.Paid) {
      startState = OrderState.Paid;
    }

    if (this.stateFilter & StateFilter.Paid) {
      endState = OrderState.Paid;
    } else if (this.stateFilter & StateFilter.Open) {
      endState = OrderState.Raised;
    } else if (this.stateFilter & StateFilter.Draft) {
      endState = OrderState.InProgress;
    }

    const archivedOnly: boolean = (this.stateFilter === StateFilter.Archived);
    const unarchivedOnly: boolean = (this.stateFilter & StateFilter.Archived) === 0;
    const archivedStartFlag: number = (archivedOnly ? 1 : 0);
    const archivedEndFlag: number = (unarchivedOnly ? 0 : 1);
// TODO: TASK - So how do we handle 'gaps' in the filter - e.g. draft/paid selected but not open?
    /* eslint-enable no-bitwise */

    this._queryParams.query = unarchivedOnly ? 'orderDetails/orderList'
                                             : (archivedOnly ? 'orderDetails/orderListArchived'
                                                             : 'orderDetails/orderListAll');
    this._queryParams.startkey = [this.orderType, startState, 0, archivedStartFlag, ''];
    this._queryParams.endkey =   [this.orderType, endState, MAX_DATE_TICKS, archivedEndFlag, HIGHEST_CHARACTER];
  }

  public onInfiniteScroll($event: Event): void {
    setTimeout(() => {
      if ($event?.target) {
        this.loadOrderDetails(false);

        const infiniteScroll: IonInfiniteScroll = $event.target as unknown as IonInfiniteScroll;
        infiniteScroll.complete();
      }
    }, DEFAULT_TIMEOUT_MS);
  }

  public getStateFilterStatus(state: number): boolean {
    // eslint-disable-next-line no-bitwise
    return (this.stateFilter & state) === 0;
  }

  public onClickState(state: StateFilter): void {
    /* eslint-disable no-bitwise */
    if (this.stateFilter & state) {
      this.stateFilter &= (~state);
    } else {
      this.stateFilter |= state;
    }
    /* eslint-enable no-bitwise */

    this.setQueryKeysFromStateFilter();
    this.loadOrderDetails(true);

    const path: string = getResolvedUrl(this._route.snapshot, false);
    this._router.navigate([path],
                          {
                            queryParams: { [FILTER_QUERYSTRING_KEY]: this.stateFilter },
                            replaceUrl: true
                          });
  }

  public getOrderId(_index: number, item: OrderDetails): string {
    return item.idToken;
  }

  public formatAddress(address: IHasAddress): string {
    return this._addressService.getSingleLineAddress(address);
  }

  public getDateRaised(order: OrderDetails): string {
    let dateRaised: string;

    if (this.isDraft(order)) {
      dateRaised = 'Draft';
    } else {
      const locale: string = this._localeService.getLocaleId();
      dateRaised = (order.dateRaised ? formatDate(order.dateRaised, 'shortDate', locale)
                                     : '(Not set)');  /* Shouldn't be possible, but just in case... */
    }

    return dateRaised;
  }

  public isDraft(order: OrderDetails): boolean {
    return (order.orderState === OrderState.NotSpecified || order.orderState === OrderState.InProgress);
  }

  public isOverdue(order: OrderDetails): boolean {
    return (!order.archived
          && order.orderType === OrderType.Invoice
          && order.orderState === OrderState.Raised
          && order.dateDue ? (order.dateDue.valueOf() < Date.now()) : false);
  }

  public goToOrder(idToken: base32guid = DUMMY_ROUTE_SEGMENT_FOR_NEW_ORDER): void {
    this._router.navigate([this.itemRoute, idToken]);
  }
}
