import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Network } from '@capacitor/network';
import { ReplaySubject } from 'rxjs';

import { Logger, LogService } from '../core/logging';

@Injectable()
export class NetworkService implements OnDestroy {
  public readonly onlineStatus$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public networkOnline: boolean = false;
  private readonly log: Logger;

  constructor(private _ngZone: NgZone,
              logService: LogService) {
    this.log = logService.getLogger(this.constructor.name);

    /* As per https://capacitorjs.com/docs/guides/angular#ngzone ... */
    Network.addListener('networkStatusChange', (status) => {
      this._ngZone.run(() => {
        this.setOnlineStatus(status.connected);
      });
    });

    Network.getStatus()
           .then((status) => {
              this.setOnlineStatus(status.connected);
            });
  }

  private setOnlineStatus(online: boolean): void {
    this.log.debug(`networkStatusChange: ${online ? 'ONLINE' : 'OFFLINE'}`);
    this.onlineStatus$.next(online);
    this.networkOnline = online;
}

  public ngOnDestroy(): void {
    Network.removeAllListeners();
  }
}
