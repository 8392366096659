import { Directive, Input } from '@angular/core';
import { Chart, ChartDataset, ChartOptions, ChartType, registerables } from 'chart.js';

import { Colour } from '../../utility';

const BORDER_SHADE_PERCENT: number = -0.3;

Chart.register(...registerables);

/* Components deriving from this class will need to declare themselves as a component based on the following:
@Component({
  selector: 'wky-blah-chart',
  templateUrl: './chart.component.html',
  styleUrls: [ './chart.component.scss' ],
  encapsulation: ViewEncapsulation.ShadowDom
})
*/
@Directive({})
// eslint-disable-next-line @angular-eslint/directive-class-suffix -- required for abstract base classes that would otherwise have to be decorated with @Component to avoid NG2007 errors
export abstract class ChartComponent  {
  @Input() public datasets: ChartDataset[] = [];
  @Input() public labels: string[] = [];
  @Input() public legend: boolean = true;
  @Input() public options: ChartOptions = ChartComponent.getDefaultOptions();
  @Input() public showEmptyState: boolean = false;
  public abstract readonly chartType: ChartType;

  public static getDefaultOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false
    };
  }

  /**
   * Note that this sets the dataset's `hidden` property to `false`, so should only be called for
   * datasets that are visible.
   */
  public setDatasetProperties(datasetIndex: number, data: number[], fillColour: string): void {
    const dataset: ChartDataset = this.datasets[datasetIndex];
    dataset.data = data;
    dataset.hidden = false;
    dataset.backgroundColor = fillColour;
    dataset.borderColor = Colour.shade(fillColour, BORDER_SHADE_PERCENT);
    dataset.hoverBackgroundColor = dataset.backgroundColor;
    dataset.hoverBorderColor = dataset.borderColor;
  }
}
