<header [ngClass]="this.headerClass">
	<h5><fa-icon *ngIf="this.icon" [icon]="this.icon" [fixedWidth]="true"></fa-icon>{{ this.heading }}</h5>
	<button *ngIf="this.showCloseButton" (click)="onCloseClick()" class="ion-activatable">
		<ion-icon name="close-outline"></ion-icon>
		<ion-ripple-effect></ion-ripple-effect>
	</button>
</header>
<div [innerHTML]="this.htmlContent" class="content"></div>
<ion-item *ngIf="this.showCheckbox" lines="none" [color]="this.color">
	<ion-checkbox [color]="this.color === '' ? '' : 'light'" (ionChange)="onCheckboxChange($event)" slot="start"></ion-checkbox>
	<ion-label class="ion-text-wrap">Don't show this again</ion-label>
</ion-item>
