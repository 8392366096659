import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PopoverController } from '@ionic/angular';

import { ContentSanitizerService } from 'src/app/services';
import { getCustomEventChecked } from 'src/app/utility';

// TODO: TASK - redundant blank header space left if no heading/icon/close button

@Component({
  selector: 'wky-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {
  @Input() public showCloseButton: boolean = false;
  @Input() public showCheckbox: boolean = false;
  @Input() public icon: IconProp | undefined;
  @Input() public heading: string = '';
  @Input() public color: string = '';
  public htmlContent: SafeHtml = '';
  private _markdownContent: string = '';
  private _dontShowAgain: boolean = false;

  constructor(private _popoverController: PopoverController,
              private _contentSanitizerService: ContentSanitizerService) {}

  public get headerClass(): string {
    return !!this.icon || this.heading.length > 0 ? 'hasHeading' : '';
  }

  @Input()
  public get markdown(): string {
    return this._markdownContent;
  }
  public set markdown(markdown: string) {
    this._markdownContent = markdown;
    this.htmlContent = this._contentSanitizerService.toSafeHtml(markdown);
  }

  public onCheckboxChange($event: Event): void {
    this._dontShowAgain = getCustomEventChecked($event);
  }

  public async onCloseClick(): Promise<boolean> {
    return this._popoverController.dismiss(this._dontShowAgain);
  }
}
