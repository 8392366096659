import { BASE16_RADIX } from '../constants';
import { base32guid } from '../core/model';
import { Base32 } from './base32';

export class Uuid {
  private _value: string = '00000000-0000-0000-0000-000000000000';

  constructor(value?: string) {
    const regex: RegExp = /[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}/;

    this._value = (value && regex.test(value)) ? value
                                               : this.getUuid();
  }

  public static fromBase32(input: string): Uuid {
    /* Conversion of GUIDs to a byte array are specifically defined in RFC 4122 */
    const outputBytes: Uint8Array = Base32.decodeToByteArray(input);
/* eslint-disable @typescript-eslint/no-magic-numbers */
    const guidBytes: number[] = [
      /* Reverse the first 4 bytes */
      outputBytes[ 3],
      outputBytes[ 2],
      outputBytes[ 1],
      outputBytes[ 0],
      /* Reverse the next 2 bytes */
      outputBytes[ 5],
      outputBytes[ 4],
      /* Reverse the next 2 bytes */
      outputBytes[ 7],
      outputBytes[ 6],
      /* Keep the remaining bytes in their original order */
      outputBytes[ 8],
      outputBytes[ 9],
      outputBytes[10],
      outputBytes[11],
      outputBytes[12],
      outputBytes[13],
      outputBytes[14],
      outputBytes[15]
    ];
    const value: string = guidBytes.map(byte => byte.toString(BASE16_RADIX)
                                                    .padStart(2, '0'))
                                   .join('');
    const guidValue: string = value.slice( 0,  8) + '-' +
                              value.slice( 8, 12) + '-' +
                              value.slice(12, 16) + '-' +
                              value.slice(16, 20) + '-' +
                              value.slice(20, 32);
/* eslint-enable @typescript-eslint/no-magic-numbers */

    return new Uuid(guidValue);
  }

  public toBase32(): base32guid {
    const value: string = this._value.replace(/-/g, '');

    /* Conversion of GUIDs to a byte array are specifically defined in RFC 4122 */
    const byteStrings: string[] = value.match(/.{2}/g) ?? [];
    const bytes: Uint8Array = new Uint8Array([
/* eslint-disable @typescript-eslint/no-magic-numbers */
      /* Reverse the first 4 bytes */
      Number.parseInt(byteStrings[ 3], BASE16_RADIX),
      Number.parseInt(byteStrings[ 2], BASE16_RADIX),
      Number.parseInt(byteStrings[ 1], BASE16_RADIX),
      Number.parseInt(byteStrings[ 0], BASE16_RADIX),
      /* Reverse the next 2 bytes */
      Number.parseInt(byteStrings[ 5], BASE16_RADIX),
      Number.parseInt(byteStrings[ 4], BASE16_RADIX),
      /* Reverse the next 2 bytes */
      Number.parseInt(byteStrings[ 7], BASE16_RADIX),
      Number.parseInt(byteStrings[ 6], BASE16_RADIX),
      /* Keep the remaining bytes in their original order */
      Number.parseInt(byteStrings[ 8], BASE16_RADIX),
      Number.parseInt(byteStrings[ 9], BASE16_RADIX),
      Number.parseInt(byteStrings[10], BASE16_RADIX),
      Number.parseInt(byteStrings[11], BASE16_RADIX),
      Number.parseInt(byteStrings[12], BASE16_RADIX),
      Number.parseInt(byteStrings[13], BASE16_RADIX),
      Number.parseInt(byteStrings[14], BASE16_RADIX),
      Number.parseInt(byteStrings[15], BASE16_RADIX)
/* eslint-enable @typescript-eslint/no-magic-numbers */
    ]);

    return Base32.encodeByteArray(bytes);
  }

  public toString(): string {
    return this._value;
  }

  /* A very small(!) implementation of UUID v4, taken from: https://gist.github.com/jed/982883
    and tidied up to pass tslint.  This should suffice for us, but if not, we should check out
    https://github.com/kelektiv/node-uuid instead.
  */
  private getUuid = (a?: any): string => {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  /* eslint-disable no-bitwise */
    return a ? (a ^ Math.random() * 16 >> a / 4).toString(16)
             : ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this.getUuid);
  /* eslint-enable no-bitwise */
  /* eslint-enable @typescript-eslint/no-magic-numbers */
  };
}
