import { Injectable } from '@angular/core';

import { LogService } from '../core/logging';
import { Account, IdPrefix } from '../core/model';
import { PouchDbEntityDataService } from './pouchdb-entity-data.service';
import { PouchDbService } from './pouchdb.service';

@Injectable()
export class AccountService extends PouchDbEntityDataService<Account> {
  constructor(pouchDbService: PouchDbService,
              logService: LogService) {
    super(IdPrefix.Account, pouchDbService, logService);
  }

  protected getInstanceFromDoc(doc: any): Account {
    const isAccount: boolean = (doc instanceof Account);
    const account: Account = isAccount ? doc as Account
                                       : new Account(doc);

    return account;
  }
}
