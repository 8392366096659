import { base32guid, CouchDbEntity } from '../core/model';
import { Uuid } from '../utility';

export class EntityDataHelper<T extends CouchDbEntity> {
  constructor(public readonly idPrefix: string) {}

  public EnsureUniqueIdAssigned(entity: T): T {
    if (entity._id === '') {
      entity = this.assignUniqueId(entity);
    }

    return entity;
  }

  public assignUniqueId(entity: T): T {
    const idToken: base32guid = entity.idToken === '' ? new Uuid().toBase32() : entity.idToken;
    return { ...this.toObject(entity),
             idToken,
             _id: this.idPrefix + idToken
           } as T;
  }

  public clone(entity: T, rev?: string): T {
    const entityClone: T = Object.assign({},
                                         entity,
                                         {_rev: rev ?? entity._rev}) as T;
    return entityClone;
  }

// TODO: TASK - work out the best alternative to disabling this
  // eslint-disable-next-line @typescript-eslint/ban-types -- corresponds to return type of Object.assign()
  public toObject(entity: T): {} & T {
// TODO: TASK - consider whether date types are being handled correctly (PouchDB(?) raises this as a potential issue)
    return Object.assign({}, entity);
  }
}
