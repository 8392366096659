import { Component, OnInit } from '@angular/core';

import { OrderType } from '../../core/model';
import { OrderListPage } from './order-list.page';

@Component({
  selector: 'wky-quote-list-page',
  templateUrl: './order-list.page.html',
  styleUrls: ['./order-list.page.scss']
})
export class QuoteListPage extends OrderListPage implements OnInit {
  public ngOnInit(): void {
    this.pageTitle = 'Quotes';
    this.itemRoute = '/quotes';
  }

  protected get orderType(): OrderType {
    return OrderType.Quote;
  }
}
