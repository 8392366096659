export const enum CssBreakpoint {
  /* These should match the standard ionic breakpoints (see src\theme\_wky-variables.scss) */
  /* eslint-disable @typescript-eslint/naming-convention -- two-letter abbreviations */
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200
  /* eslint-enable @typescript-eslint/naming-convention */
}

export const enum CssClass {
  AlertIsWide = 'is-wide',
  PapersizeA4 = 'paper-a4',
  PapersizeLetter = 'paper-letter',
  PasswordStrength = 'password-strength',
  PasswordStrengthScorePrefix = 'score',
  PopoverIsWide = 'is-wide'
}

export const enum ErrorCode {
  /** Thrown if the password reset code has expired */
  ExpiredAuthCode,
  /** Thrown if the password reset code is invalid. This can happen if the code is malformed or has already been used */
  InvalidAuthCode,
  /** Thrown if the user corresponding to the given password reset code has been disabled */
  UserDisabled,
  /** Thrown if there is no user corresponding to the password reset code. This may have happened if the user was deleted between when the code was issued and when this method was called */
  UserNotFound,
  /** Thrown if the new password is not strong enough */
  WeakPassword,
  InvalidEmail,
  Unknown
}

export const enum NotFoundType {
  Invoice = 'invoice',
  Quote = 'quote'
}

export /* not const */ enum Notification {
  /* These flags are stored so their values must be hardcoded to ensure that any
    deletions won't affect other values.
  */
  None = 0,
  OrderEmailPlaceholderHelp = 1,
  TemplateColoursTip = 2,
  TemplateUsingDummyData = 3,
  TemplateUsingCurrentData = 4,
  InvoiceNotesHelpInfo = 5,
  QuoteNotesHelpInfo = 6,
  SmallInfoHelpInfo = 7
}

export const enum QuerystringKey {
  Tab = 'tab',
  Token = 'token',
  RedirectUrl = 'redirecturl'
}
