<ion-split-pane [when]="this.showMenu$ | async" contentId="main-content">
	<ion-menu contentId="main-content" swipe-gesture="false">
		<ion-header>
			<ion-toolbar>
				<ion-title>Workery</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<ion-list lines="none">
				<ion-item routerLink="/dashboard">
					<fa-icon icon="home" slot="start" [fixedWidth]="true"></fa-icon>
					<ion-label>Home</ion-label>
				</ion-item>
				<ion-item routerLink="/invoices">
					<fa-icon icon="file-invoice" slot="start" [fixedWidth]="true"></fa-icon>
					<ion-label>Invoices</ion-label>
				</ion-item>
				<ion-item routerLink="/quotes">
					<fa-icon icon="comment-dollar" slot="start" [fixedWidth]="true"></fa-icon>
					<ion-label>Quotes</ion-label>
				</ion-item>
				<ion-item routerLink="/settings">
					<fa-icon icon="cog" slot="start" [fixedWidth]="true"></fa-icon>
					<ion-label>Settings</ion-label>
				</ion-item>
			</ion-list>
			<ion-list lines="none">
				<ion-item routerLink="/logout">
					<fa-icon icon="sign-out" slot="start" [fixedWidth]="true"></fa-icon>
					<ion-label>Log Out</ion-label>
				</ion-item>
			</ion-list>
		</ion-content>
	</ion-menu>
	<ion-router-outlet id="main-content"></ion-router-outlet>
</ion-split-pane>
