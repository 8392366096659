import { ErrorCode } from '../enums';

export class AppError extends Error {
  constructor(public readonly errorCode: ErrorCode,
              message: string,
              public readonly innerError?: Error) {
    super(message);

    this.name = 'AppError';
  }
}
