import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';

import { Logger, LogService } from './logging';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  private readonly _log: Logger;

  constructor(logService: LogService) {
     this._log = logService.getLogger('ResponseInterceptor');
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
               .pipe(map((event: HttpEvent<any>) => {
                      /* The API wraps response data in an object, as per OWASP recommendations,
                        so it needs to be unwrapped here.
                      */
                      return (event instanceof HttpResponse && event.body && typeof(event.body.data) !== 'undefined'
                                ? event.clone({
                                      body: event.body.data
                                  })
                                : event);
                    }),
                    catchError((error: any) => {
                      /* Just log the basic error here (callees can determine what to do about it later) */
                      this._log.warn(error);
                      throw error;
                    }));
  }
}
