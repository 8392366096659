import { Injectable } from '@angular/core';
import { add, sub } from 'date-fns';

import { LogService } from '../core/logging';
import { DateRange, DateRangePeriod } from '../utility';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';

const MONTHS_IN_QUARTER: number = 3;
const MONTHS_IN_YEAR: number = 12;

@Injectable({
  providedIn: 'root'
})
export class DateRangeService extends BaseService {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(settingsService: SettingsService,
              logService: LogService) {
    super(settingsService, logService);
  }

  /**
   * Returns a `DateRange` covering the period, from the start of the first day of the period to the
   * start of the day *after* the last day of the period.  This makes iterations simpler by allowing
   * values to be tested as `toDate < range.to` (rather than requiring `range.to` to include the
   * latest possible time on the last day for testing with `<=`).
   */
  public getDateRange(period: DateRangePeriod): DateRange {
    return this.getDateRangeEx(period, new Date(), this.currentSettings.financialYearStart);
  }

  /**
   * Returns a `DateRange` covering the period, from the start of the first day of the period to the
   * start of the day *after* the last day of the period.  This makes iterations simpler by allowing
   * values to be tested as `toDate < range.to` (rather than requiring `range.to` to include the
   * latest possible time on the last day for testing with `<=`).
   *
   * This method is provided primarily for testing to allow 'today' and the financial year start to be
   * specified as required.
   */
  public getDateRangeEx(period: DateRangePeriod, today: Date, financialYearStart: Date): DateRange {
    let from: Date = new Date(0);
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    let to: Date = new Date(10000, 0, 1);   /* A relatively arbitrary max date */
    const startOfThisMonth: Date = new Date(today.getFullYear(), today.getMonth(), 1);
    const startingMonth: number = financialYearStart.getMonth();
    const monthsIntoYear: number = ((today.getMonth() + MONTHS_IN_YEAR) - startingMonth) % MONTHS_IN_YEAR;
    const monthsIntoQuarter: number = monthsIntoYear % MONTHS_IN_QUARTER;

    switch (period) {
      case DateRangePeriod.ThisMonth:
        from = startOfThisMonth;
        to = add(startOfThisMonth, { months: 1 });
        break;

      case DateRangePeriod.LastMonth:
        from = sub(startOfThisMonth, { months: 1 });
        to = startOfThisMonth;
        break;

      case DateRangePeriod.Last3MonthsPlus:
        from = sub(startOfThisMonth, { months: 3 });
        to = add(startOfThisMonth, { months: 1 });
        break;

      case DateRangePeriod.ThisFinQuarter:
        from = sub(startOfThisMonth, { months: monthsIntoQuarter });
        to = add(from, { months: MONTHS_IN_QUARTER });
        break;

      case DateRangePeriod.LastFinQuarter:
        from = sub(startOfThisMonth, { months: monthsIntoQuarter + MONTHS_IN_QUARTER });
        to = add(from, { months: MONTHS_IN_QUARTER });
        break;

      case DateRangePeriod.ThisFinYear:
// TODO: TASK - this assumes start of year is the 1st of the month
        from = sub(startOfThisMonth, { months: monthsIntoYear });
        to = add(from, { months: MONTHS_IN_YEAR });
        break;

      case DateRangePeriod.LastFinYear:
// TODO: TASK - this assumes start of year is the 1st of the month
        from = sub(startOfThisMonth, { months: monthsIntoYear + MONTHS_IN_YEAR });
        to = add(from, { months: MONTHS_IN_YEAR });
        break;

      case DateRangePeriod.Custom:
        /* Nothing to calculate */
        break;

      default:
        this.log.error(`Unrecognized DateRangePeriod enum value - ${period}`);
        break;
    }

    return new DateRange(period, from, to);
  }
}
