import { Injectable } from '@angular/core';
import { concatMap, Observable, of } from 'rxjs';

import { QueryParams } from '../core';
import { LogService } from '../core/logging';
import { OrderNumber, OrderType } from '../core/model';
import { OrderNumberFactory } from './order-number-factory';
import { PouchDbDataService } from './pouchdb-data.service';
import { PouchDbService } from './pouchdb.service';

@Injectable()
export class OrderNumberService extends PouchDbDataService<OrderNumber> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(pouchDbService: PouchDbService,
              logService: LogService) {
    /* Technically not needed, but if omitted, there is no constructor for the base class to
      get the class name from (which, bizarrely, then leads to 'circular dependency' errors
      in the service's DI).
    */
    super(pouchDbService, logService);
  }

  public getNext(orderType: OrderType): Observable<OrderNumber> {
    const queryParams: QueryParams = new QueryParams('orderDetails/orderNumberFormats',
                                                     [orderType, Number.MAX_SAFE_INTEGER],
                                                     [orderType, 0]);
    queryParams.ascending = false;
    queryParams.pageSize = 1;
    queryParams.includeDocs = false;

    return this.getWithQuery(queryParams)
               .pipe(concatMap((orderNumbers: OrderNumber[]) => {
                  let defaultValue: string;

                  switch (orderType) {
                    case OrderType.Quote:
                      defaultValue = 'Q-00001';
                      break;

                    case OrderType.Invoice:
                    default:
                      defaultValue = '00001';
                      break;
                  }

                  const value: string = orderNumbers.length > 0 ? orderNumbers[0].value : defaultValue;
                  return of(new OrderNumber('', value));
                }));
  }

  protected getInstanceFromRow(row: any): OrderNumber {
    const value: string = OrderNumberFactory.generate(row.value[0], row.value[1], row.key[1]);
    return new OrderNumber(row.id, value);
  }
}
