import { Injectable } from '@angular/core';

import { Name } from '../core/model';
import { stringFormat } from '../utility';

// TODO: TASK - probably need a better name for this service
// TODO: TASK - this needs to be initialized with the locale (derive from BaseService to get locale)
// TODO: FEATURE -provide support for non-hyphenated compound last names, eg. Kitty St John O'Connor

@Injectable()
export class NameService {
  private _titles: string[] = [];
  private _titleVariants: string[] = [];
  private _suffixVariants: string[] = [];

  public getTitles(): string[] {
    if (this._titles.length === 0) {
// TODO: TASK - load these from somewhere
      this._titles = [
        'Mr ',
        'Mrs ',
        'Ms ',
        'Miss ',
        'Mr & Mrs ',
        'Dr ',
        'Prof ',
        'Sir ',
        'Rev '
      ];
    }

    return this._titles.slice(0);  /* Return a clone so internal array cannot be changed by caller */
  }

  /**
   * This method is declared public to provide access purely for testing purposes and
   * should not ordinarily be used.
   */
  public getTitleVariants(): string[] {
    if (this._titleVariants.length === 0) {
// TODO: TASK - load these from somewhere
      /* IMPORTANT  Functions in this service assume that these values are
          a) all uppercase
          b) contain a single trailing space
          c) in the reverse-aphabetical order (to prevent any value from preemptively matching a later one)
      */
      this._titleVariants = [
        'SIR ',
        'REV. ',
        'REV ',
        'PROF. ',
        'PROF ',
        'MS. ',
        'MS ',
        'MRS. ',
        'MRS ',
        'MR. & MRS. ',
        'MR. ',
        'MR & MRS ',
        'MR ',
        'MISS ',
        'DR. ',
        'DR '
      ];
    }

    return this._titleVariants.slice(0);  /* Return a clone so internal array cannot be changed by caller */
  }

  /**
   * This method is declared public to provide access purely for testing purposes and
   * should not ordinarily be used.
   */
  public getSuffixVariants(): string[] {
    if (this._suffixVariants.length === 0) {
// TODO: TASK - load these from somewhere
      /* IMPORTANT  Functions in this service assume that these values are
          a) all uppercase
          b) contain a single preceding space
          c) in the reverse-aphabetical order (to prevent any value from preemptively matching a later one)
      */
      this._suffixVariants = [
        ' SR.',
        ' SR',
        ' SNR.',
        ' SNR',
        ' SENIOR',
        ' JUNIOR',
        ' JR.',
        ' JR',
        ' JNR.',
        ' JNR',
        ' III',
        ' II',
        ' ESQ.',
        ' ESQ',
        ' 111',
        ' 11'
      ];
    }

    return this._suffixVariants.slice(0);  /* Return a clone so internal array cannot be changed by caller */
  }

  public splitName(fullName: string): Name {
    const name: Name  = new Name();
    const values: [string, string] = this.splitTitleFromName(fullName);
    name.title = values[0];
    fullName = values[1];

    let testName: string = fullName.toUpperCase();

    for (const suffix of this.getSuffixVariants()) {
      if (testName.endsWith(suffix)) {
        testName = fullName.slice(0, fullName.length - suffix.length);
        break;
      }
    }

    if (testName.length === fullName.length) {
      testName = fullName;  /* So it's not left as all uppercase */
    }

    const nameParts: string[] = testName.split(' ');
    const firstPart: string = nameParts[0];

    switch (nameParts.length) {
      case 1:
        /* It's just the lastname */
        name.lastName = fullName;
        break;

      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      case 2:
      /* It's rare for a lastname to include a space, so more likely that it's both
         a first and last name.
      */
// TODO: FEATURE - this reasoning might be locale-specific.  Also, if we've found the title, it
// could be argued that it's unlikely to include the first name, in which case, maybe
// it is a space-including last name??
       name.firstName = firstPart;
       name.lastName = fullName.slice(firstPart.length + 1);   /* Allow for space delimiter */
       break;

      default:
        /* Almost definitely a first and last name */
        name.firstName = firstPart;
        name.lastName = fullName.slice(firstPart.length + 1);   /* Allow for space delimiter */
        break;
    }

    return name;
  }

  public splitTitleAndFirstName(titleFirstName: string): Name {
    const values: [string, string] = this.splitTitleFromName(titleFirstName);
    return new Name(values[0], values[1], '');
  }

  private splitTitleFromName(name: string): [string, string] {
    name = name.trim().replace(/\s{2,}/g, ' ');
    const values: [string, string] = ['', ''];
    const testName: string = name.toUpperCase() + ' ';  /* Append space in case name is just the title */

    for (const title of this.getTitleVariants()) {
      if (testName.startsWith(title)) {
        values[0] = name.slice(0, title.length - 1);  /* Don't include the trailing space */
        values[1] = name.slice(title.length);
        break;
      }
    }

    if (values[0].length === 0) {
      values[1] = name;
    }

    return values;
  }

  public getFullName(firstName: string, lastName: string): string {
// TODO: TASK - this is likely to be locale-specific for the order
    return stringFormat('{0} {1}', firstName, lastName).trim();
  }
}
